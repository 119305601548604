import { Icon } from "@iconify/react";
import { useFormikContext, Formik, Form, Field, FieldArray } from "formik";
import { vehicleSchema } from "../formSchema";
import { toCommas } from "../../../utils/priceConverter";
import { ScrollTo } from "../../../utils/formUtils";
import { useEffect, useRef } from "react";

export default function VehicleForm({ next, data }) {
  const formRef = useRef();
  const FormSubmit = (values, { setSubmitting }) => {
    delete values.tempfeature;
    let modified = values;
    if (modified.callForPrice) {
      modified.price = 0;
    } else {
      if (modified.price == "") {
        modified.price = 0;
        modified.callForPrice = true;
      }
    }
    next(modified);
    setSubmitting(false);
  };
  useEffect(() => {
    if (data) {
      formRef.current?.setValues(
        {
          location: data.location,
          type: data.type,
          brand: data.brand,
          model: data.model,
          color: data.color,
          series: data.series,
          year: data.year,
          features: data.features,
          tempfeature: "",
          engineSize: data.engineSize,
          trans: data.trans,
          price: data.price,
          seatingCapacity: data.seatingCapacity,
          mileage: data.mileage,
          callForPrice: false,
          negotiable: false,
          quickSale: false,
          workToOwn: false,
          tradeAcc: false
        },
        false
      );
    }
  }, []);
  return (
    <div className='px-4 h-full bg-light rounded-xl'>
      <h1 className='text-3xl font-bold font-head text-darkText tracking-wider mb-8 pt-2'>
        Vehicle Information
      </h1>
      <Formik
        innerRef={formRef}
        initialValues={{
          location: "",
          type: "",
          brand: "",
          model: "",
          color: "",
          series: "",
          year: "",
          features: [],
          tempfeature: "",
          engineSize: "",
          trans: "",
          price: "",
          seatingCapacity: "",
          mileage: "",
          condition: "",
          addInfo: "",
          callForPrice: false,
          negotiable: false,
          quickSale: false,
          workToOwn: false,
          tradeAcc: false
        }}
        validationSchema={vehicleSchema}
        onSubmit={FormSubmit}
        validateOnMount
      >
        {({
          isSubmitting,
          errors,
          touched,
          values,
          setFieldTouched,
          setFieldValue,
        }) => (
          <InnerForm
            isSubmitting={isSubmitting}
            errors={errors}
            touched={touched}
            values={values}
            setTouched={setFieldTouched}
            setFieldValue={setFieldValue}
          />
        )}
      </Formik>
    </div>
  );
}

function InnerForm({
  isSubmitting,
  errors,
  touched,
  values,
  setTouched,
  setFieldValue,
}) {
  const typeRef = useRef();
  const locationRef = useRef();
  const brandRef = useRef();
  const modelRef = useRef();
  const seriesRef = useRef();
  const transRef = useRef();
  const conditionRef = useRef();

  const formikContext = useFormikContext();
  useEffect(() => {
    if (isSubmitting) {
      if (formikContext.errors.location != undefined) {
        ScrollTo(locationRef);
        return;
      } else if (formikContext.errors.type != undefined) {
        ScrollTo(typeRef);
        return;
      } else if (formikContext.errors.brand != undefined) {
        ScrollTo(brandRef);
        return;
      } else if (formikContext.errors.model != undefined) {
        ScrollTo(modelRef);
        return;
      } else if (formikContext.errors.condition != undefined) {
        ScrollTo(conditionRef);
        return;
      } else if (formikContext.errors.series != undefined) {
        ScrollTo(seriesRef);
        return;
      } else if (formikContext.errors.trans != undefined) {
        ScrollTo(transRef);
        return;
      }
    }
  }, [isSubmitting]);

  const Year = () => {
    const Now = new Date();
    const cYear = Now.getFullYear();
    let temp = [];
    for (let i = cYear + 1; 1990 <= i; i--) {
      temp.push(i);
    }
    return temp;
  };
  return (
    <Form className='grid grid-cols-1 items-center text-secondary sm:grid-cols-2 gap-1 sm:gap-2 justify-items-center sm:items-start lg:grid-cols-3'>
      <div
        className={
          errors.location && touched.location
            ? "w-11/12 border-danger border-2 bg-white text-danger py-2 pl-8 pr-2 relative"
            : !errors.location && touched.location
              ? "w-11/12 border-success border-2 bg-white text-success py-2 pl-8 pr-2 relative"
              : "w-11/12 border-secondary border-2 bg-white  py-2 pl-8 pr-2 text-secondary relative"
        }
      >
        <div
          className={
            errors.location && touched.location
              ? "w-12 h-12 border-danger border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : !errors.location && touched.location
                ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
                : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {errors.location && touched.location ? (
            <Icon
              className='w-full h-full font-bold text-danger relative right-1'
              icon='ic:round-close'
            />
          ) : !errors.location && touched.location ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between'>
          <h2 className='text-xl font-bold font-head tracking-wide'>
            Location
          </h2>
          <p className='font-bold'>
            {!errors.location && touched.location ? "Done" : "Required*"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2'>
          {errors.location && touched.location
            ? `${errors.location}`
            : "Select Location"}
        </sub>
        <Field
          className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
          name='location'
          component='select'
          innerRef={locationRef}
        >
          <option value=''>--Select A Location--</option>
          <optgroup label='Trinidad'>
            <option value='Arima'>Arima</option>
            <option value='Chaguanas'>Chaguanas</option>
            <option value='Couva'>Couva</option>
            <option value='Debe'>Debe</option>
            <option value='Diego Martin'>Diego Martin</option>
            <option value='Laventille'>Laventille</option>
            <option value='Mayaro'>Mayaro</option>
            <option value='Penal'>Penal</option>
            <option value='Piarco'>Piarco</option>
            <option value='Point Fortin'>Point Fortin</option>
            <option value='Port of Spain'>Port of Spain</option>
            <option value='Princes Town'>Princes Town</option>
            <option value='Rio Claro'>Rio Claro</option>
            <option value='San Fernando'>San Fernando</option>
            <option value='San Juan'>San Juan</option>
            <option value='Sangre Grande'>Sangre Grande</option>
            <option value='Siparia'>Siparia</option>
            <option value='Talparo'>Talparo</option>
            <option value='Tabaquite'>Tabaquite</option>
            <option value='Tunapuna'>Tunapuna</option>
          </optgroup>
          <optgroup label='Tobago'>
            <option value='Tobago'>Tobago</option>
          </optgroup>
        </Field>
      </div>
      <div
        className={
          errors.type && touched.type
            ? "w-11/12 border-danger border-2 bg-white text-danger py-2 pl-8 pr-2 relative"
            : !errors.type && touched.type
              ? "w-11/12 border-success border-2 bg-white text-success py-2 pl-8 pr-2 relative"
              : "w-11/12 border-secondary border-2 bg-white  py-2 pl-8 pr-2 text-secondary relative"
        }
      >
        <div
          className={
            errors.type && touched.type
              ? "w-12 h-12 border-danger border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : !errors.type && touched.type
                ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
                : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {errors.type && touched.type ? (
            <Icon
              className='w-full h-full font-bold text-danger relative right-1'
              icon='ic:round-close'
            />
          ) : !errors.type && touched.type ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between'>
          <h2 className='text-xl font-bold font-head tracking-wide'>Type</h2>
          <p className='font-bold'>
            {!errors.type && touched.type ? "Done" : "Required*"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2'>
          {errors.type && touched.type
            ? `${errors.type}`
            : "Select Vehicle Type"}
        </sub>
        <Field
          className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
          name='type'
          component='select'
          innerRef={typeRef}
        >
          <option value=''>--Select A Type--</option>
          <option value='suv'>Suv</option>
          <option value='wagon'>Wagon</option>
          <option value='hatchback'>HatchBack</option>
          <option value='sedan'>Sedan</option>
          <option value='coupe'>Coupe</option>
          <option value='minivan'>Minivan(7 seater)</option>
          <option value='minibus'>Minibus(12 seater)</option>
          <option value='pickup'>Pickup</option>
          <option value='truck'>Truck</option>
          <option value='boat'>Boat</option>
          <option value='other'>Other</option>
        </Field>
      </div>
      <div
        className={
          errors.brand && touched.brand
            ? "w-11/12 border-danger border-2 bg-white text-danger py-2 pl-8 pr-2 relative"
            : !errors.brand && touched.brand
              ? "w-11/12 border-success border-2 bg-white text-success py-2 pl-8 pr-2 relative"
              : "w-11/12 border-secondary border-2 bg-white py-2 pl-8 pr-2 text-secondary relative"
        }
      >
        <div
          className={
            errors.brand && touched.brand
              ? "w-12 h-12 border-danger border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : !errors.brand && touched.brand
                ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
                : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {errors.brand && touched.brand ? (
            <Icon
              className='w-full h-full font-bold text-danger relative right-1'
              icon='ic:round-close'
            />
          ) : !errors.brand && touched.brand ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between'>
          <h2 className='text-xl font-bold font-head tracking-wide'>Brand</h2>
          <p className='font-bold'>
            {!errors.brand && touched.brand ? "Done" : "Required*"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2'>
          {errors.brand && touched.brand
            ? "Please Select A Vehicle Brand"
            : "Select Vehicle Brand"}
        </sub>
        <Field
          className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
          name='brand'
          type='text'
          component='input'
          placeholder='Enter Brand'
        ></Field>
      </div>
      <div
        className={
          errors.model && touched.model
            ? "w-11/12 border-danger border-2 bg-white text-danger py-2 pl-8 pr-2 relative"
            : !errors.model && touched.model
              ? "w-11/12 border-success border-2 bg-white text-success py-2 pl-8 pr-2 relative"
              : "w-11/12 border-secondary border-2 bg-white py-2 pl-8 pr-2 text-secondary relative"
        }
      >
        <div
          className={
            errors.model && touched.model
              ? "w-12 h-12 border-danger border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : !errors.model && touched.model
                ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
                : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {errors.model && touched.model ? (
            <Icon
              className='w-full h-full font-bold text-danger relative right-1'
              icon='ic:round-close'
            />
          ) : !errors.model && touched.model ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between'>
          <h2 className='text-xl font-bold font-head tracking-wide'>Model</h2>
          <p className='font-bold'>
            {!errors.model && touched.model ? "Done" : "Required*"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2'>
          {errors.model && touched.model ? errors.model : "Enter Vehicle Model"}
        </sub>
        <Field
          className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
          name='model'
          type='text'
          component='input'
          placeholder='Enter Model'
          innerRef={modelRef}
        ></Field>
      </div>
      <div
        className={
          values.color != "" && touched.color
            ? "w-11/12 border-success border-2 bg-white text-success py-2 pl-8 pr-2 relative"
            : "w-11/12 border-secondary border-2 bg-white py-2 pl-8 pr-2 text-secondary relative"
        }
      >
        <div
          className={
            values.color != "" && touched.color
              ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {values.color != "" && touched.color ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between'>
          <h2 className='text-xl font-bold font-head tracking-wide'>Color</h2>
          <p className='font-bold'>
            {values.color != "" && touched.color ? "Done" : "Optional*"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2'>
          Select Color
        </sub>
        <Field
          className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
          name='color'
          component='select'
        >
          <option value=''>--Select Color--</option>
          <option value="white">White</option>
          <option value="black">Black</option>
          <option value="silver">Silver</option>
          <option value="gray">Gray</option>
          <option value="red">Red</option>
          <option value="blue">Blue</option>
          <option value="green">Green</option>
          <option value="brown">Brown</option>
          <option value="beige">Beige</option>
          <option value="yellow">Yellow</option>
          <option value="orange">Orange</option>
          <option value="gold">Gold</option>
        </Field>
      </div>
      <div
        className={
          values.year != "" && touched.year
            ? "w-11/12 border-success border-2 bg-white text-success py-2 pl-8 pr-2 relative"
            : "w-11/12 border-secondary border-2 bg-white py-2 pl-8 pr-2 text-secondary relative"
        }
      >
        <div
          className={
            values.year != "" && touched.year
              ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {values.year != "" && touched.year ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between'>
          <h2 className='text-xl font-bold font-head tracking-wide'>
            Model Year
          </h2>
          <p className='font-bold'>
            {values.year != "" && touched.year ? "Done" : "Optional*"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2'>
          Select Model Year
        </sub>
        <Field
          className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
          name='year'
          component='select'
          placholder='Select Year'
        >
          <option value=''>--Select A Year--</option>
          {Year().map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </Field>
      </div>
      <div
        className={
          errors.condition && touched.condition
            ? "w-11/12 border-danger border-2 bg-white text-danger py-2 pl-8 pr-2 relative"
            : !errors.condition && touched.condition
              ? "w-11/12 border-success border-2 bg-white text-success py-2 pl-8 pr-2 relative"
              : "w-11/12 border-secondary border-2 bg-white py-2 pl-8 pr-2 text-secondary relative"
        }
      >
        <div
          className={
            errors.condition && touched.condition
              ? "w-12 h-12 border-danger border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : !errors.condition && touched.condition
                ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
                : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {errors.condition && touched.condition ? (
            <Icon
              className='w-full h-full font-bold text-danger relative right-1'
              icon='ic:round-close'
            />
          ) : !errors.condition && touched.condition ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between'>
          <h2 className='text-xl font-bold font-head tracking-wide'>
            Condition
          </h2>
          <p className='font-bold'>
            {!errors.condition && touched.condition ? "Done" : "Required*"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2'>
          {errors.condition && touched.condition
            ? `${errors.condition}`
            : "Select Vehicle Condition"}
        </sub>
        <Field
          className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
          name='condition'
          component='select'
          innerRef={conditionRef}
        >
          <option value=''>--Vehicle Condition--</option>
          <option value='New'>New</option>
          <option value='Used'>Used</option>
          <option value='Damaged'>Damaged</option>
        </Field>
      </div>
      <div
        className={
          errors.series && touched.series
            ? "w-11/12 border-danger border-2 bg-white text-danger py-2 pl-8 pr-2 relative"
            : !errors.series && touched.series
              ? "w-11/12 border-success border-2 bg-white text-success py-2 pl-8 pr-2 relative"
              : "w-11/12 border-secondary border-2 bg-white py-2 pl-8 pr-2 text-secondary relative"
        }
      >
        <div
          className={
            errors.series && touched.series
              ? "w-12 h-12 border-danger border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : !errors.series && touched.series
                ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
                : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {errors.series && touched.series ? (
            <Icon
              className='w-full h-full font-bold text-danger relative right-1'
              icon='ic:round-close'
            />
          ) : !errors.series && touched.series ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between'>
          <h2 className='text-xl font-bold font-head tracking-wide'>Series</h2>
          <p className='font-bold'>
            {!errors.series && touched.series ? "Done" : "Required*"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2'>
          {errors.series && touched.series
            ? errors.series
            : "Enter Vehicle Series"}
        </sub>
        <Field
          className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
          name='series'
          type='text'
          component='input'
          placeholder='Enter Series'
          maxLength='4'
          innerRef={seriesRef}
        />
      </div>
      <div
        className={
          errors.mileage && touched.mileage
            ? "w-11/12 border-danger text-danger border-2 bg-white py-2 pr-2 relative"
            : !errors.mileage && values.mileage !== "" && touched.mileage
              ? "w-11/12 border-success border-2 bg-white text-success bg-white py-2 pr-2 relative"
              : "w-11/12 border-secondary border-2 bg-white text-secondary bg-white py-2 pr-2 relative"
        }
      >
        <div
          className={
            errors.mileage && touched.mileage
              ? "w-12 h-12 border-danger border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : !errors.mileage && values.mileage !== "" && touched.mileage
                ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
                : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {errors.mileage && touched.mileage ? (
            <Icon
              className='w-full h-full font-bold text-danger relative right-1'
              icon='ic:round-close'
            />
          ) : !errors.mileage && values.mileage !== "" && touched.mileage ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between pl-8'>
          <h2 className='text-xl font-bold font-head tracking-wide'>Mileage</h2>
          <p className='font-bold'>
            {!errors.mileage && values.mileage !== "" && touched.mileage
              ? "Done"
              : errors.mileage && touched.mileage
                ? "Invalid"
                : "Optional"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2 pl-8'>
          {errors.mileage && touched.mileage
            ? errors.mileage
            : "Enter Vehicle Mileage"}
        </sub>
        <div className='pl-8'>
          <Field
            className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
            name='mileage'
            component='input'
            type='number'
            maxLength='10'
            placeholder='Enter Mileage'
          />
          <sub className='text-lg font-semibold ml-2'>Km</sub>
        </div>
        {!errors.mileage && values.mileage !== "" ? (
          <div className='w-full text-center'>
            <h3 className='text-2xl text-secondary font-bold'>
              {`${toCommas(values.mileage)} Km`}
            </h3>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          errors.seatingCapacity && touched.seatingCapacity
            ? "w-11/12 border-danger text-danger border-2 bg-white py-2 pr-2 relative"
            : !errors.seatingCapacity &&
              values.seatingCapacity !== "" &&
              touched.seatingCapacity
              ? "w-11/12 border-success border-2 bg-white text-success bg-white py-2 pr-2 relative"
              : "w-11/12 border-secondary border-2 bg-white text-secondary bg-white py-2 pr-2 relative"
        }
      >
        <div
          className={
            errors.seatingCapacity && touched.seatingCapacity
              ? "w-12 h-12 border-danger border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : !errors.seatingCapacity &&
                values.seatingCapacity !== "" &&
                touched.seatingCapacity
                ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
                : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {errors.seatingCapacity && touched.seatingCapacity ? (
            <Icon
              className='w-full h-full font-bold text-danger relative right-1'
              icon='ic:round-close'
            />
          ) : !errors.seatingCapacity &&
            values.seatingCapacity !== "" &&
            touched.seatingCapacity ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between'>
          <h2 className='text-xl font-bold tracking-wide pl-8'>
            Seating Capacity
          </h2>
          <p className='font-bold'>
            {!errors.seatingCapacity &&
              values.seatingCapacity !== "" &&
              touched.seatingCapacity
              ? "Done"
              : errors.seatingCapacity && touched.seatingCapacity
                ? "Invalid"
                : "Optional"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2 pl-8'>
          {errors.seatingCapacity && touched.seatingCapacity
            ? errors.seatingCapacity
            : "Enter Seating Capacity"}
        </sub>
        <div className='pl-8'>
          <Field
            className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
            name='seatingCapacity'
            component='input'
            type='number'
            maxLength='10'
            placeholder='Enter Seat Count'
          />
        </div>
      </div>
      <div
        className={
          errors.trans && touched.trans
            ? "w-11/12 border-danger border-2 bg-white text-danger py-2 pl-8 pr-2 relative"
            : !errors.trans && touched.trans
              ? "w-11/12 border-success border-2 bg-white b text-success py-2 pl-8 pr-2 relative"
              : "w-11/12 border-secondary border-2 bg-white py-2 pl-8 pr-2 text-secondary relative"
        }
      >
        <div
          className={
            errors.trans && touched.trans
              ? "w-12 h-12 border-danger border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : !errors.trans && touched.trans
                ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
                : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {errors.trans && touched.trans ? (
            <Icon
              className='w-full h-full font-bold text-danger relative right-1'
              icon='ic:round-close'
            />
          ) : !errors.trans && touched.trans ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between'>
          <h2 className='text-xl font-bold font-head tracking-wide'>
            Transmission
          </h2>
          <p className='font-bold'>
            {!errors.trans && touched.trans ? "Done" : "Required*"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2'>
          {errors.trans && touched.trans
            ? errors.trans
            : "Select Vehicle Transmission"}
        </sub>
        <Field
          className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
          name='trans'
          component='select'
          innerRef={transRef}
        >
          <option value=''>--Trans Type--</option>
          <option value='Automatic'>Automatic</option>
          <option value='Manual'>Manual</option>
          <option value='Tiptronic'>Tiptronic</option>
        </Field>
      </div>
      <div
        className={
          values.fuel != "" && touched.fuel
            ? "w-11/12 border-success border-2 bg-white text-success py-2 pl-8 pr-2 relative"
            : "w-11/12 border-secondary border-2 bg-white py-2 pl-8 pr-2 text-secondary relative"
        }
      >
        <div
          className={
            values.fuel != "" && touched.fuel
              ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {values.fuel != "" && touched.fuel ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between'>
          <h2 className='text-xl font-bold font-head tracking-wide'>Fuel</h2>
          <p className='font-bold'>
            {values.fuel != "" && touched.fuel ? "Done" : "Optional"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2'>
          Select Fuel Type
        </sub>
        <Field
          className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
          name='fuel'
          component='select'
        >
          <option value=''>--Select A Fuel--</option>
          <option value='petrol'>Petrol</option>
          <option value='diesel'>Diesel</option>
          <option value='hybrid'>Hybrid</option>
          <option value='electric'>Electric</option>
          <option value='cng'>CNG</option>
        </Field>
      </div>
      <div
        className={
          errors.engineSize && touched.engineSize
            ? "w-11/12 border-danger text-danger border-2 bg-white py-2 pr-2 relative"
            : !errors.engineSize &&
              values.engineSize !== "" &&
              touched.engineSize
              ? "w-11/12 border-success border-2 bg-white text-success bg-white py-2 pr-2 relative"
              : "w-11/12 border-secondary border-2 bg-white text-secondary bg-white py-2 pr-2 relative"
        }
      >
        <div
          className={
            errors.engineSize && touched.engineSize
              ? "w-12 h-12 border-danger border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : !errors.engineSize &&
                values.engineSize !== "" &&
                touched.engineSize
                ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
                : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {errors.engineSize && touched.engineSize ? (
            <Icon
              className='w-full h-full font-bold text-danger relative right-1'
              icon='ic:round-close'
            />
          ) : !errors.engineSize && values.engineSize !== "" ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between pl-8'>
          <h2 className='text-xl font-bold font-head tracking-wide'>
            {values.fuel == "electric" ? "Battery" : "Engine"}
          </h2>
          <p className='font-bold'>
            {!errors.engineSize &&
              values.engineSize !== "" &&
              touched.engineSize
              ? "Done"
              : errors.engineSize && touched.engineSize
                ? "Invalid"
                : "Optional"}
          </p>
        </div>
        <sub className='text-base font-semibold block mb-2 pl-8'>
          {errors.engineSize && touched.engineSize
            ? errors.engineSize
            : values.fuel == "electric"
              ? "Enter Battery Capacity"
              : "Enter Engine Size"}
        </sub>
        <div className='pl-8'>
          <Field
            className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
            name='engineSize'
            component='input'
            type='number'
            maxLength='10'
            placeholder='Enter Size'
          />
          <sub className='text-base font-semibold ml-2'>
            {values.fuel == "electric" ? "kW" : "CC"}
          </sub>
        </div>
        {!errors.engineSize && values.engineSize !== "" ? (
          <div className='w-full text-center'>
            <h3 className='text-xl text-secondary font-bold'>
              {values.fuel == "electric"
                ? `${toCommas(values.engineSize)} kW`
                : `${toCommas(values.engineSize)} CC`}
            </h3>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          errors.tempfeature
            ? "w-11/12 border-danger text-danger border-2 bg-white pl-8 py-2 pr-2 relative"
            : !errors.tempfeature && values.features.length != 0
              ? "w-11/12 border-success border-2 bg-white text-success bg-white pl-8 py-2 pr-2 relative"
              : "w-11/12 border-secondary border-2 bg-white text-secondary bg-white pl-8 py-2 pr-2 relative"
        }
      >
        <div
          className={
            errors.tempfeature
              ? "w-12 h-12 border-danger border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : !errors.tempfeature && values.features.length != 0
                ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
                : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {errors.tempfeature ? (
            <Icon
              className='w-full h-full font-bold text-danger relative right-1'
              icon='ic:round-close'
            />
          ) : !errors.tempfeature && values.features.length != 0 ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between'>
          <h2 className='text-2xl font-bold tracking-wide'>Features</h2>
          <p className='font-bold'>
            {values.features.length != 0 ? "Valid" : "Optional"}
          </p>
        </div>
        {errors.tempfeature ? (
          <sub className='text-base text-danger font-semibold block mb-2'>
            {errors.tempfeature}
          </sub>
        ) : (
          <sub className='text-base font-semibold block mb-2'>Add Feature</sub>
        )}
        <div className='flex items-center flex-wrap gap-2 w-full mb-2'>
          <FieldArray
            name='features'
            render={(arrayHelpers) => (
              <>
                <div className='flex items-center gap-2 w-full'>
                  <Field
                    className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
                    component='input'
                    name='tempfeature'
                    type='text'
                    maxLength='50'
                    minLength='2'
                    placeholder='Add Feature'
                  />
                  <div className='flex justify-center gap-2'>
                    <Icon
                      className='w-8 h-8 font-bold text-secondary text-center'
                      onClick={() => {
                        if (!errors.tempfeature) {
                          if (values.tempfeature == "") {
                            return;
                          }
                          arrayHelpers.unshift(values.tempfeature);
                          setFieldValue("tempfeature", "", false);
                          setTouched("tempfeature", false);
                        }
                      }}
                      icon='fluent-mdl2:circle-addition'
                    />
                  </div>
                </div>
                {values.features.length !== 0 ? (
                  <div className='w-full flex flex-wrap justify-start max-h-[12rem] overflow-scroll'>
                    <h3 className='text-xl font-semibold text-secondary py-2'>
                      Feature{values.features.length > 1 ? "s" : ""}
                    </h3>
                    <span className='w-full text-secondary pb-2 font-semibold'>
                      Click/Tap Feature to Remove
                    </span>
                    <ul className='flex flex-col gap-3 w-full'>
                      {values.features?.map((data, index) => (
                        <li
                          className='bg-secondary truncate font-semibold tracking-widest text-white py-1 px-3'
                          key={`${data}-${index}`}
                          onClick={() => {
                            arrayHelpers.remove(index);
                            setTouched("tempfeature", false);
                          }}
                        >
                          <span className='text-start'>
                            {values.features.length - index}.
                          </span>
                          <span className='ml-4'>{data}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          />
        </div>
      </div>
      <div
        className={
          values.addInfo !== "" && touched.addInfo
            ? "w-11/12 border-success border-2 bg-white text-success py-2 pr-2 relative"
            : "w-11/12 border-secondary border-2 bg-white py-2 pr-2 text-secondary relative"
        }
      >
        <div
          className={
            values.addInfo !== "" && touched.addInfo
              ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {values.addInfo !== "" && touched.addInfo ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between pl-8'>
          <h2 className='text-xl font-bold font-head tracking-wide'>
            Additional Info
          </h2>
          <p className='font-bold'>
            {errors.addInfo && touched.addInfo ? "Done" : "Optional"}
          </p>
        </div>
        {errors.addInfo && touched.addInfo ? (
          <sub className='text-base text-danger font-semibold block mb-2 pl-8'>
            {` ${errors.addInfo}`}
          </sub>
        ) : (
          <sub className='text-base font-semibold block mb-2 pl-8'>
            Enter Additional Info.
          </sub>
        )}
        <div className='h-full ml-6 mr-2'>
          <Field
            className='w-full p-2 bg-zinc-300 text-zinc-500 rounded-lg font-semibold tracking-widest'
            name='addInfo'
            rows='10'
            component='textarea'
            type='text'
            maxLength='500'
            placeholder='Addtional Info'
          />
        </div>
      </div>
      <div
        className={
          errors.price && touched.price
            ? "w-11/12 border-danger text-danger border-2 bg-white py-2 pr-2 relative"
            : (!errors.price && values.price !== "" && touched.price) ||
              values.callForPrice
              ? "w-11/12 border-success border-2 bg-white text-success bg-white py-2 pr-2 relative"
              : "w-11/12 border-secondary border-2 bg-white text-secondary bg-white py-2 pr-2 relative"
        }
      >
        <div
          className={
            errors.price && touched.price
              ? "w-12 h-12 border-danger border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
              : (!errors.price && values.price !== "" && touched.price) ||
                values.callForPrice
                ? "w-12 h-12 border-success border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
                : "w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9"
          }
        >
          {errors.price && touched.price ? (
            <Icon
              className='w-full h-full font-bold text-danger relative right-1'
              icon='ic:round-close'
            />
          ) : (!errors.price && values.price !== "" && touched.price) ||
            values.callForPrice ? (
            <Icon
              className='w-12 h-12 font-bold text-success relative bottom-1 right-3'
              icon='material-symbols:check-small-rounded'
            />
          ) : (
            <Icon
              className='w-full h-full font-bold text-secondary'
              icon='clarity:form-line'
            />
          )}
        </div>
        <div className='w-100 flex justify-between ml-8'>
          <h2 className='text-xl font-bold font-head tracking-wide'>Price</h2>
          <p className='font-bold'>
            {(!errors.price && values.price !== "" && touched.price) ||
              values.callForPrice
              ? "Done"
              : errors.price && touched.price
                ? "Invalid"
                : "Optional"}
          </p>
        </div>
        <p className='text-base font-semibold block mb-2 ml-8'>
          Please Enter Price
        </p>
        <div className='pl-8 flex flex-col gap-2'>
          <label className='relative inline-flex items-center cursor-pointer'>
            <Field
              type='checkbox'
              name='callForPrice'
              component='input'
              className='sr-only peer'
            />
            <div className="w-11 h-6 bg-zinc-400 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            <span className='ml-3 text-md font-semibold text-secondary'>
              Call For Price
            </span>
          </label>
          <label className='relative inline-flex items-center cursor-pointer'>
            <Field
              type='checkbox'
              name='negotiable'
              component='input'
              className='sr-only peer'
            />
            <div className="w-11 h-6 bg-zinc-400 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            <span className='ml-3 text-md font-semibold text-secondary'>
              Price Negotiable
            </span>
          </label>
          <label className='relative inline-flex items-center cursor-pointer'>
            <Field
              type='checkbox'
              name='quickSale'
              component='input'
              className='sr-only peer'
            />
            <div className="w-11 h-6 bg-zinc-400 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            <span className='ml-3 text-md font-semibold text-secondary'>
              Quick Sale
            </span>
          </label>
          <label className='relative inline-flex items-center cursor-pointer'>
            <Field
              type='checkbox'
              name='workToOwn'
              component='input'
              className='sr-only peer'
            />
            <div className="w-11 h-6 bg-zinc-400 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            <span className='ml-3 text-md font-semibold text-secondary'>
              Work To Own
            </span>
          </label>
          <label className='relative inline-flex items-center cursor-pointer'>
            <Field
              type='checkbox'
              name='tradeAcc'
              component='input'
              className='sr-only peer'
            />
            <div className="w-11 h-6 bg-zinc-400 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
            <span className='ml-3 text-md font-semibold text-secondary'>
              Trade
            </span>
          </label>
        </div>
        {values.callForPrice ? (
          ""
        ) : (
          <div className='flex ml-8 mt-4'>
            <Field
              className='w-full py-2 bg-zinc-300 text-black rounded-2xl text-center font-semibold tracking-widest'
              name='price'
              component='input'
              type='number'
              maxLength='10'
              placeholder='Enter Price'
            />
            <sub className='text-base font-semibold ml-2'>TTD</sub>
          </div>
        )}
        {values.callForPrice ? (
          ""
        ) : !errors.price && values.price !== "" ? (
          <div className='w-full text-center'>
            <h3 className='text-xl text-secondary font-bold'>
              {`$ ${toCommas(values.price)} TTD`}
            </h3>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className='w-full flex justify-center sm:col-span-2 lg:col-span-3'>
        <button
          className='bg-success w-32 text-center text-light font-bold rounded-full h-8 px-3 py-0 tracking-wider'
          type='submit'
          disabled={isSubmitting}
        >
          Next Step
          <Icon
            className='inline w-4 h-4 text-light mb-1'
            icon='material-symbols:arrow-forward-ios-rounded'
          />
        </button>
      </div>
    </Form>
  );
}
