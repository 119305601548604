function About() {
  return (
    <div className='w-full relative'>
      <div className='w-full flex flex-col justify-start'>
        <img
          src='https://firebasestorage.googleapis.com/v0/b/showroom478.appspot.com/o/Assets%2FAbout%20Hero%2FAsset%202%402x.webp?alt=media&token=e4905eea-d91d-49a7-8aa1-68850f160a9d'
          className='w-full h-[25rem] sm:h-[30rem] md:hidden'
          alt='ShowRoomTT Logo'
        />
        <img
          className='hidden md:block w-full h-[30rem] lg:h-[43rem] lg:w-11/12 lg:mx-auto'
          src='https://firebasestorage.googleapis.com/v0/b/showroom478.appspot.com/o/Assets%2FAbout%20Hero%2FAboutPageLGBG.webp?alt=media&token=fbb64944-5632-4c51-a5d6-ba63fade6832'
        />
      </div>
      <div className='w-full pb-2 flex sm:w-[35rem] mx-auto flex-col relative -top-16 md:-top-8'>
        <div className='w-28 mx-auto'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 592'>
            <g id='Layer_2' data-name='Layer 2'>
              <g id='Layer_1-2' data-name='Layer 1'>
                <g>
                  <path
                    d='M0,583c5.72-9.24,14.67-12.65,24.86-14.26,4-.64,8-2.31,11.83-3.75,4.89-1.82,7.71-5.66,9.31-10.44,1.12-3.32,3.14-4.84,6.74-5.61,14.25-3,28.48-6.26,42.53-10.12,3.69-1,6.72-4.52,10-6.94s6.24-6.19,9.91-7.15c6.61-1.74,10.23-6.64,14.4-11,1.12-1.16,1.2-4,.8-5.84-1.36-6.25.47-9.08,6.78-8.78a51.36,51.36,0,0,1,10.17,2c4.67,1.19,9.26,2.64,13.91,3.89,18.91,5.11,42.65-10.94,47.13-29.29.61-2.46-.55-5.41-1.1-8.08-1-4.85-1.5-9.95-3.43-14.41s-5.38-8.34-8.41-12.84c3-2.9,2.83-6.08-.07-9.52-.94-1.11-.87-3.11-1.14-4.72-.17-1,.27-2.59-.19-2.87-5.43-3.28-1.74-5.71.62-8.32a3.53,3.53,0,0,0,.63-1.34c2.33-8.27,5.16-16.45,6.79-24.87,1.1-5.72.29-11.65,3.82-17,1.09-1.64-.18-5.15-.94-7.62-2-6.67-4.2-13.31-6.68-19.82a39.05,39.05,0,0,0-5.44-10.45c-2.83-3.53-5.81-6.36.08-10.17a49.12,49.12,0,0,0-4.55-1.92c-6.15-1.85-11.18-5.24-16.08-9.49-3.36-2.91-8.57-3.77-13-5.32-3.11-1.08-6.48-1.49-9.53-2.72-6.3-2.54-8.41-1.68-12.28,4.16-.94,1.41-4.07,1.37-6.19,2l-.73-1.52c.81-1.07,1.63-2.13,2.53-3.31-.32-.18-.72-.61-1.1-.59a92.44,92.44,0,0,0-9.86.73c-3,.46-5-.17-5.58-3.26s-1.48-6.53-1.1-9.66c.2-1.7,2.62-4.17,4.33-4.45,6.64-1,11.7-4.88,16.28-9,4.07-3.71,8.11-4.69,13.17-3.49a36.05,36.05,0,0,1,4.94,1h-6.27L412,505.11c.52,11.61,1,24,1.69,36.3.08,1.58,1.4,3.07,1.57,4.67.12,1.19-.7,2.47-1.1,3.72-1-.71-2.53-1.19-3-2.16-2.1-4.58-5.56-6-9.37-2.92-4.88,3.91-9.35,8.35-14.12,12.42a18.73,18.73,0,0,1-5,2.94c-11,4.58-22,9.07-33.1,13.56a21.7,21.7,0,0,1-5.6,1.91c-8.52.91-17.07,1.73-25.62,2.25a22.88,22.88,0,0,1-8.32-1.19c-15-5-29.65-4.69-44.81.6-6.69,2.34-15,2.33-22.06.83-11.43-2.42-22.53-.63-33.57.55-11.43,1.22-22.59.57-33.92-.2a76.37,76.37,0,0,0-18.84,1.18c-6.63,1.21-13.06,3.58-19.52,5.59a17.69,17.69,0,0,1-18.78-5.42c-4.62-5.72-11.21-6.49-17.81-7a68,68,0,0,0-12.43-.21,64.5,64.5,0,0,0-13.5,2.78c-4.55,1.49-8.67,1.65-13-.5-6.78-3.38-13.19-1.82-19.45,1.72C35,580.73,27.38,584.78,19.89,589A33.71,33.71,0,0,0,16,592H0Z'
                    fill='#c8102e'
                  />
                  <path
                    d='M640,35c-4.45-.45-7.58-2.18-7.58-8.8L640,32Z'
                    fill='#c8102e'
                  />
                  <path
                    d='M624,0l4,3.54c-2.09.7-3.73,1.55-5.42,1.68-.7.06-2.31-1.77-2.14-2.14A12.82,12.82,0,0,1,623,0Z'
                    fill='#c8102e'
                  />
                  <path d='M417.94,289.18c-2.51,4-4.4,9.07-10.93,5.87-.66-.32-2.34.89-3.24,1.71-6.7,6.11-8.23,13.8-6.38,22.33,2.25,10.4,4.73,20.75,7,31.15,1.54,7.1,2.94,14.23,4.29,21.37.52,2.7.16,4.74-3.4,5.3-7.83,1.23-10.48,5.44-9.92,13.17,1.12,15.55,5.08,30.5,9.95,45.13,2.54,7.65,7.15,14.6,10.71,21.92,2.39,4.93,6.24,2,9.8,1.77,1,3.13,1.55,5.36-2.82,6.16-1.69.32-3,2.58-4.45,3.91L188.13,269.84c3.45.92,6.71,2,10,2.51.65.11,2.45-1.9,2.3-2.65-.62-3.07-.81-6.29,2.86-6.56,2-.15,4.22,2.38,6.93,4.08.07-.07.83-1,1.7-1.84,2.31-2.22,4-2.86,5.19,1.2.5,1.66,2.79,2.78,3.22,3.18,3.62-3.46,6-6.55,9.19-8.36,1.13-.64,4.05,1.84,6.48,3.06.58-4,4.27-5.86,8.61-7.75,2.29-1,4.07-4.37,5-7,1-3,2.41-3.26,4.78-2.3,12.88,5.25,26.61,5.77,40.17,6.68a213.53,213.53,0,0,0,27.93-.45c3.45-.22,6.75-2.33,10.2-3.29,8.29-2.3,14.92-9.48,24.39-8.28,2.63.34,5.27.65,7.9,1l-3,1.44c17.36,15,34.34,29.73,51.45,44.31C414.36,289.56,416.41,289.07,417.94,289.18Z' />
                  <path
                    d='M614.57,48.16c-.76.91-1.22,1.24-1.35,1.66-2,6.83-2,6.83-9,5.08-4.89-1.21-7.71.17-9.67,5.34-1.39,3.67-2.66,6.81-7.34,7.46-2.07.28-4.22,2-5.75,3.66a22.52,22.52,0,0,1-20.23,7.37c-7.55-1-13.5,1.69-19.21,6.18-3.38,2.66-7.19,5.24-11.59,1.25a3,3,0,0,0-2.76.2c-4.54,3.35-10.19,5.23-12.07,11.79-1.74,6.12-7.6,7.08-13.38,4.38-3.85-1.79-8.56-1.83-12.91-2.35-1.72-.2-3.53.44-6.11.82,0-3.17-.13-5.78,0-8.36.2-3,1.72-5.3,5-5.07,7,.5,10.22-5.07,14.17-8.9,7.7-7.45,14.37-16,22.15-23.33,4.53-4.28,10.39-7.14,15.63-10.67,1.1-.74,2.5-1.4,3.09-2.47C548.36,33,557.83,31.68,566.62,29c4.46-1.38,8.54-3.95,13-5.33,6.92-2.16,14-3.81,21-5.53a2.94,2.94,0,0,1,2.59,1c1.43,3,3.35,2.36,5.74,1.35,2.57-1.08,4.46-2.18,3.19-5.49-.19-.52.81-2.15,1.35-2.19,3.82-.33,8.6-1.8,11.18-.07,2,1.37,1.74,6.77,1.76,10.38,0,1.79-2,3.5-2.41,5.39s-.52,4.05.31,5.49a9.78,9.78,0,0,1,0,10.77c-.75,1.27-1.07,2.78-1.74,4.1s-1.6,2.65-2.41,4c-1.21-.91-2.44-1.79-3.63-2.73C616,49.57,615.43,49,614.57,48.16Z'
                    fill='#c8102e'
                  />
                  <path
                    d='M417.94,289.18c-1.53-.11-3.58.38-4.53-.43-17.11-14.58-34.09-29.31-51.45-44.31l3-1.44,26.94-6.47c3.19-1.95,6.21-2.66,9.47.2C412,246,422.73,255.1,433.24,264.45c1.14,1,1.16,3.28,1.7,5Z'
                    fill='#fff'
                  />
                  <path
                    d='M434.94,269.41c-.54-1.68-.56-4-1.7-5C422.73,255.1,412,246,401.41,236.73c-3.26-2.86-6.28-2.15-9.47-.2,6-3.89,12.57-4.44,19.17-2.49,8.61,2.54,17.09,2.19,25.84.87,4.54-.69,9.36.23,14,.77.5.06,1.4,2.7,1,3.63-1.42,3.11-2.84,6.49-5.2,8.83a33.82,33.82,0,0,0-9.37,17.11C437.05,266.74,435.78,268,434.94,269.41Z'
                    fill='#c8102e'
                  />
                  <path
                    d='M83.27,307.56l-12,2.68c1.28-4.64,1.88-8.64,3.6-12.07.63-1.27,4.23-2.12,5.91-1.54,3.11,1.06,6.17,3.05,6.22,7.73l-8.82-1.55Z'
                    fill='#c8102e'
                  />
                  <path
                    d='M104.75,305.71l-5.22-1.81c.76-1.35,1.42-2.75,2.29-4s1.66-2.68,2.88-3.34a55.15,55.15,0,0,1,7.08-2.8c-.48,2.81-.39,5.92-1.63,8.35C109.28,303.79,106.59,304.56,104.75,305.71Z'
                    fill='#c8102e'
                  />
                  <path
                    d='M92.86,302.28c-1.46-3.15-3-6.39-4.47-9.63C94.42,293.85,96.34,298.25,92.86,302.28Z'
                    fill='#c8102e'
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <section className='mb-6 py-4 px-2 text-darkText'>
          <h2 className='text-3xl font-bold tracking-wide font-head text-center mb-4'>
            <span className='relative'>
              <span className='relative z-10'>About</span>
              <div className='absolute w-full h-3 bg-[#CC59D2] bottom-1 left-0'></div>
            </span>{" "}
            Us
          </h2>
          <p className='indent-6 mb-4'>
            Based in Trinidad and Tobago, ShowRoomTT is committed to providing a
            transparent, trustworthy, and customer-centric platform for
            individuals interested in buying or selling cars. Whether you're in
            the market for a sleek sedan, a robust SUV, or a powerful pickup
            truck, we have a diverse inventory to suit your preferences and
            lifestyle.
          </p>
          <p className='indent-6'>
            Discover the joy of buying and selling cars with confidence. Join
            the growing community of satisfied customers who have found their
            dream cars or successfully sold their vehicles through ShowRoomTT.
            Your journey to automotive satisfaction starts here.
          </p>
          <p className='mt-4'>
            For More Infomation Please Feel Free To Email:
            <a
              className='ml-2 underline decoration-blue text-blue'
              href='mailto:support@showroomtt.com'
            >
              support@showroomTT.com
            </a>
          </p>
        </section>
      </div>
    </div>
  );
}
export default About;
