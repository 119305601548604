import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetSingleDocument } from "../../api/firestore";
function Modal() {
    const [open, setOpen] = useState(false);
    const [PromoDoc, SetDoc] = useState({});
    useEffect(() => {
        async function GrabPromo() {
            const Promotion = localStorage.getItem("Promo");
            if (Promotion == null) {
                await GetPromo(null);
            } else {
                let ParsePromotion = JSON.parse(Promotion);
                const ElapsedTimeSecs = Math.floor((Date.now() - ParsePromotion) / 1000);
                if (ElapsedTimeSecs >= 43200) {
                    await GetPromo(true);
                    return;
                }
                return;
            }
        }
        GrabPromo();
    }, []);
    useEffect(() => {
        if (open) {
            document.getElementsByTagName("HTML")[0].style.overflow = "hidden";
            window.scroll(0, 0);
        } else {
            document.getElementsByTagName("HTML")[0].style.overflow = "auto";
        }
    }, [open]);
    const GetPromo = async (localCheck) => {
        const Document = await GetSingleDocument("Promotions", "Promo");
        if (Document == null) {
            if (localCheck) {
                localStorage.removeItem("Promo");
                return;
            }
            return;
        } else {
            if (Document.endDate <= (Math.floor(Date.now() / 1000))) {
                localStorage.removeItem("Promo");
                return;
            } else {
                SetDoc(Document);
                localStorage.setItem("Promo", JSON.stringify(Date.now()));
                toggleModal(true);
            }
        }
    }
    const toggleModal = (bool) => {
        setOpen(bool);
    }
    if (open) {
        return (
            <div className="w-full h-full absolute z-50">
                <div className="w-full h-[120vh] absolute -z-10 bg-dark opacity-70" onClick={() => { toggleModal(false) }}></div>
                <dialog className="h-[80vh] sm:h-[90vh] w-11/12 sm:w-3/4 md:w-1/2 lg:w-2/6 rounded-xl mx-auto my-16 sm:my-12" open={open}>
                    <div className="absolute right-0">
                        <button className="text-white" onClick={() => { toggleModal(false) }}><Icon
                            className="w-full h-8 font-bold text-light"
                            icon='ri:close-line'
                        />
                        </button>
                    </div>
                    <Link to='/Pricing' aria-label='Go to Pricing Page' onClick={() => { toggleModal(false) }}>
                        <img className="w-full h-full rounded-xl" src={PromoDoc.promoUrl} />
                    </Link>
                </dialog>
            </div>
        );
    }
    return;
}

export default Modal;
