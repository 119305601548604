import { Icon } from "@iconify/react";
import { useState } from "react";
import Card from "../Card";
import noResultIcon from "../../assets/icons/mag.svg";
import CardPlaceHolder from "../CardPlaceHolder";
import InfiniteScroll from "react-infinite-scroll-component";

function search({
  data,
  status,
  next,
  hasMore,
  sortSearch,
  search,
  totalDocuments,
}) {
  const [searchField, setSearchField] = useState("");
  return (
    <div className='pt-8 lg:pt-16 mb-12'>
      <div className='flex justify-center'>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            search(`q=${searchField.toLowerCase()}`);
            setSearchField("");
          }}
          className='flex w-5/6 sm:w-4/6 md:w-1/2 '
        >
          <input
            className='pl-6 shadow-xl rounded-tl-full rounded-bl-full w-full h-8 xl:h-10 md:text-lg placeholder:text-zinc-600 sm:placeholder:text-lg placeholder:text-start lg:placeholder:font-semibold align-middle'
            type='text'
            placeholder='Search'
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
          />
          <button type='submit'>
            <Icon
              className='shadow-xl text-3xl font-bold h-8 xl:h-10 w-12 sm:w-16 md:w-20 rounded-tr-full rounded-br-full text-zinc-600 bg-white'
              icon='radix-icons:magnifying-glass'
            />
          </button>
        </form>
      </div>
      <div className='relative'>
        <div className='mt-8 mb-2'>
          <div className='flex justify-between border-b-4 border-zinc-400'>
            <h2 className='text-zinc-800 font-bold text-xl pb-2 mb-2'>
              Search Results
            </h2>
            <select
              name='price'
              className='font-semibold h-8 w-32 bg-zinc-300 rounded-full text-center font-semibold tracking-widest'
              onChange={(e) => {
                switch (e.currentTarget.value) {
                  case "asc":
                    sortSearch("asc");
                    break;
                  case "dsc":
                    sortSearch("dsc");
                    break;
                  default:
                    sortSearch(false);
                }
              }}
            >
              <option value=''>Relevance</option>
              <option value='asc'>Price:Asc</option>
              <option value='dsc'>Price:Desc</option>
            </select>
          </div>
          <h4 className='text-zinc-500 mt-2 font-bold text-sm'>
            {totalDocuments} results found
          </h4>
        </div>
        {status == "pending" ? (
          <div className='grid grid-flow-row grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-1 sm:gap-2 place-items-start'>
            <CardPlaceHolder count={8} />
          </div>
        ) : totalDocuments == 0 ? (
          <div className='w-full flex justify-center mt-12 flex-wrap'>
            <img
              className='w-2/5 md:w-2/12 h-full'
              src={noResultIcon}
              alt='No Results found'
            />
            <div className='w-full text-center mt-4'>
              <h2 className='text-2xl text-darkText font-semibold tracking-wide'>
                No Results Found
              </h2>
            </div>
          </div>
        ) : (
          <InfiniteScroll
            className='grid grid-flow-row grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-1 sm:gap-2 place-items-start'
            dataLength={() => {
              let count = 0;
              data.map((page) => {
                count += page.documents.length;
              });
              return count;
            }}
            next={next}
            hasMore={hasMore}
            loader={<CardPlaceHolder count={4} />}
            endMessage={
              <div className='absolute -bottom-8 w-full'>
                <p className='text-center text-base'>
                  <b>Yay! You have seen it all</b>
                </p>
              </div>
            }
          >
            {data?.map((page) => {
              return page.documents.map((item) => {
                return <Card key={item?.objectID} data={item} />;
              });
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
}
export default search;
