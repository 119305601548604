import { Icon } from "@iconify/react";
import { Formik, Form, Field, FieldArray } from "formik";
import { contactSchema, tempSchema } from "../formSchema";
import { useEffect, useRef, useState } from "react";
import { UpdateDocumentField } from "../../../api/firestore";
import useNoti from "../../../context/notificationhook";
import phoneUtil from "google-libphonenumber";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
const phoneParser = phoneUtil.PhoneNumberUtil.getInstance();
const PNF = phoneUtil.PhoneNumberFormat;
export default function ContactForm({ next, previous, user, fetchUser }) {
  const formRef = useRef();
  const { Show } = useNoti();
  const [toggleUserProfile, setToggleUserProfile] = useState(false);
  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    if (user.contactNumbers?.length != 0) {
      setToggleUserProfile(true);
    }
  }, []);
  const FormSubmit = async (values) => {
    try {
      if (dirty) {
        await UpdateDocumentField(user.uid, "Users", { ...values })
          .then((result) => {
            if (result.success) {
              Show("Contact Info. Updated", true);
              fetchUser();
              setToggleUserProfile(true);
            }
          })
          .catch((error) => {
            throw error;
          });
      } else {
        setToggleUserProfile(true);
      }
    } catch (error) {
      throw error;
    }
  };
  return (
    <div className='flex flex-col items-center px-4 lg:px-10 h-full bg-light rounded-xl'>
      <h1 className='text-3xl font-bold font-head text-darkText tracking-wider mb-8 pt-2'>
        Contact Information
      </h1>
      {toggleUserProfile ? (
        <div className='flex justify-center w-full'>
          <div className='w-full lg:w-1/2 relative border-success border-2 bg-white rounded-3xl p-2 mx-2 text-success'>
            <div className='w-12 h-12 border-success border-2 bg-white rounded-full absolute z-10 -left-5 -top-2'>
              <Icon
                className='w-12 h-12 font-bold text-success relative'
                icon='material-symbols:check-small-rounded'
              />
            </div>
            <h2 className='text-2xl font-bold font-head tracking-wide text-center'>
              Profile
            </h2>
            <div className='flex flex-wrap justify-between'>
              <div className='w-2/5'>
                <h3 className='font-bold text-zinc-500 text-base'>
                  Contact Name
                </h3>
                <p className='font-bold text-darkText truncate text-base'>
                  {user.contactName}
                </p>
              </div>
              <div className='w-2/5'>
                <h3 className='font-bold text-zinc-500 text-base'>
                  {user.contactNumbers.length > 1
                    ? "Contact Numbers"
                    : "Contact Number"}
                </h3>
                {user.contactNumbers.map((item) => {
                  return (
                    <p className='font-bold text-darkText text-base' key={item}>
                      {item}
                    </p>
                  );
                })}
              </div>
              <div className='w-2/5'>
                <h3 className='font-bold text-zinc-500 text-base'>
                  Contact Email
                </h3>
                <p className='font-bold text-black text-base truncate'>
                  {user.email}
                </p>
              </div>
              <div className='w-2/5'>
                <h3 className='font-bold text-zinc-500 text-base'>
                  Preffered Contact
                </h3>
                <p className='font-bold text-darkText text-base'>
                  {user.preferredContact}
                </p>
              </div>
            </div>
            <div className='w-full flex justify-end mt-4'>
              <button
                onClick={() => {
                  setToggleUserProfile((prev) => !prev);
                }}
                className='font-semibold bg-white text-secondary underline decoration-secondary decoration-2 decoration-solid'
              >
                Edit Contact Information
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className='flex justify-center w-full'>
          <Formik
            innerRef={formRef}
            initialValues={{
              tempNumber: "",
              contactName: user.contactName,
              contactNumbers: [...user.contactNumbers],
              preferredContact: user.preferredContact,
            }}
            validationSchema={contactSchema}
            onSubmit={FormSubmit}
          >
            {({ errors, touched, values, setFieldError, setFieldValue }) => (
              <InnerForm
                errors={errors}
                touched={touched}
                values={values}
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
              />
            )}
          </Formik>
        </div>
      )}
      <div className='flex mt-8 px-2 md:px-40 xl:px-60 2xl:px-96 justify-between gap-8 xl:gap-32'>
        <button
          className='bg-success text-center text-lightText font-bold rounded-full h-8 px-3 py-0 tracking-wider'
          type='button'
          onClick={() => {
            if (user.contactNumbers.length > 0 && !toggleUserProfile) {
              setToggleUserProfile(true);
            } else {
              previous((prev) => prev - 1);
            }
          }}
        >
          <Icon
            className='inline w-4 h-4 text-lightText mb-1'
            icon='material-symbols:arrow-back-ios-rounded'
          />
          {user.contactNumbers.length > 0 && !toggleUserProfile
            ? "Back To Profile"
            : "Previous Step"}
        </button>
        <button
          className='bg-success text-center text-lightText font-bold rounded-full h-8 px-3 py-0 tracking-wider'
          type='submit'
          onClick={
            toggleUserProfile
              ? () => {
                next(false);
              }
              : () => {
                setDirty(formRef.current?.dirty);
                formRef.current?.submitForm();
              }
          }
        >
          {toggleUserProfile ? "Next Step" : "Update Info"}
          <Icon
            className='inline w-4 h-4 text-tlightcolor mb-1'
            icon='material-symbols:arrow-forward-ios-rounded'
          />
        </button>
      </div>
    </div>
  );
}

function InnerForm({ errors, touched, values, setFieldError, setFieldValue }) {
  const [phone, setPhone] = useState({ number: "" });
  return (
    <Form className='w-full lg:w-1/2 flex flex-col items-center text-secondary'>
      <div className='w-4/5 border-secondary border-2 py-2 text-secondary relative'>
        <div className='w-12 h-12 border-secondary border-2 bg-white pl-2 absolute z-10 -left-6 top-9'>
          <Icon
            className='w-full h-full font-bold text-secondary'
            icon='clarity:form-line'
          />
        </div>
        <h2 className='text-xl font-bold tracking-wide pl-8'>
          Contact Information
        </h2>
        <sub className='text-base font-semibold block mb-2 pl-8'>
          Contact Number
        </sub>
        {errors.contactNumbers && touched.contactNumbers ? (
          <p className='text-danger font-semibold pl-8'>
            {errors.contactNumbers}
          </p>
        ) : (
          ""
        )}
        {errors.tempNumber ? (
          <p className='text-danger font-semibold pl-8'>Invalid Number</p>
        ) : (
          ""
        )}
        <div className='flex items-center flex-col gap-4'>
          <FieldArray
            name='contactNumbers'
            render={(arrayHelpers) => (
              <>
                <div className='flex justify-center items-center w-full mt-5'>
                  <PhoneInput
                    style={{ '--react-international-phone-font-size': '16px', border: '1px solid gray', borderRadius: "8px 0px 0px 8px" }}
                    defaultCountry="tt"
                    value={phone.number}
                    onChange={(phone, meta) => setPhone({ number: phone, meta })}
                  />
                  <div className='flex flex-col justify-center h-10 w-12 bg-zinc-300 rounded-r-lg'>
                    <Icon
                      className='h-4/6 w-5/6 mx-auto font-bold inline text-secondary'
                      onClick={() => {
                        try {
                          if (values.contactNumbers?.length == 3) {
                            return;
                          }
                          tempSchema.validateSync(
                            phone
                          );
                          let number = phoneParser.parseAndKeepRawInput(phone.number, phone.meta.iso2)
                          arrayHelpers.push(`+${number.getCountryCode()} ${phoneParser.format(number, PNF.NATIONAL)}`);
                          setFieldValue("tempNumber", "", false);
                        } catch (e) {
                          setFieldError("tempNumber", e.message);
                        }
                      }}
                      icon='fluent-mdl2:circle-addition'
                    />
                  </div>
                </div>
                {errors.tempNumber ? (
                  <h3 className='text-danger font-semibold tracking-wider'>
                    {errors.tempNumber}
                  </h3>
                ) : (
                  ""
                )}
                {values.contactNumbers?.length !== 0 ? (
                  <div className='w-full flex flex-col mt-4'>
                    <ul className='flex flex-wrap justify-center gap-1'>
                      {values.contactNumbers?.map((data, index) => (
                        <li
                          className='w-full sm:w-4/6 mx-4 text-lg sm:text-xl cursor-pointer rounded-md flex font-bold bg-secondary justify-center tracking-widest text-lightText py-1 px-3'
                          key={data}
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        >
                          {data}
                        </li>
                      ))}
                    </ul>
                    {values.contactNumbers?.length === 3 ? (
                      <span className='w-full text-danger text-center mt-2 font-semibold'>
                        Maximum of 3 Numbers
                      </span>
                    ) : (
                      ""
                    )}
                    <span className='w-full text-zinc-600 text-center mt-4 font-semibold'>
                      Click/Tap Contact Number to Remove
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          />
          <sub className='text-base font-semibold self-start block mb-2 pl-8'>
            Contact Name
          </sub>
          {errors.contactName && touched.contactName ? (
            <p className='text-danger font-semibold'>{errors.contactName}</p>
          ) : (
            ""
          )}
          <Field
            name='contactName'
            className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
            type='text'
            component='input'
            placeholder='Contact Name'
          />
          <label className='font-bold self-start pl-8'>
            Preferred form of contact
          </label>
          <Field
            className='w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-2xl text-center font-semibold tracking-widest'
            name='preferredContact'
            component='select'
          >
            <option value=''>--Preferred--</option>
            <option value='Call'>Call</option>
            <option value='Whatsapp'>WhatsApp</option>
            <option value='Email'>Email</option>
          </Field>
        </div>
      </div>
    </Form>
  );
}
