import React from "react";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { UserAuthContextProvider } from "./api/fireauth";
import { NotificationProvider } from "./context/notification";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import Home from "./pages/Home";
import LoginSignUp from "./pages/LoginSignUp";
import AuthPage from "./pages/Auth";
import Pricing from "./pages/Pricing";
import FormPage from "./pages/FormDataPage";
import ProductPage from "./pages/ProductPage";
import DashboardPage from "./pages/DashboardPage";
import ErrorPage from "./pages/ErrorPage";
import "./index.css";
import SearchPage from "./pages/SearchPage";
import ProtectedRoute from "./components/ProtectedRoute/protectedRoute";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PaymentPage from "./pages/PaymentPage";
import AboutPage from "./pages/AboutPage";
import SharePage from "./pages/SharePage";
Sentry.init({
  dsn: "https://0c305a199e694c99885ed69492f63ed9@o959327.ingest.sentry.io/5907714",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration(
      React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    ),
  ],
  tracesSampleRate: 1.0,
});
const queryClient = new QueryClient();
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        name: "home",
        path: "/",
        element: <Home />,
      },
      {
        path: "login",
        element: <LoginSignUp />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "auth",
        element: <AuthPage />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "form",
        element: (
          <ProtectedRoute>
            <FormPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "dashboard",
        element: (
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "product/:objectId",
        element: <ProductPage />,
      },
      {
        path: "s/:objectId",
        element: <SharePage />,
      },
      {
        path: "checkout",
        element: (
          <ProtectedRoute>
            <PaymentPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "search",
        element: <SearchPage />,
      },
    ],
  },
]);
createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HelmetProvider>
      <UserAuthContextProvider>
        <QueryClientProvider client={queryClient}>
          <NotificationProvider>
            <RouterProvider router={router} />
          </NotificationProvider>
        </QueryClientProvider>
      </UserAuthContextProvider>
    </HelmetProvider>
  </React.StrictMode>
);
