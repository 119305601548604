import { Icon } from "@iconify/react";
import Card from "../Card";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import noResult from "../../assets/icons/mag.svg";
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from "react-router-dom";
import { toCommas } from "../../utils/priceConverter";
import { UpperFirst } from "../../utils/stringmodified";
import { useEffect, useMemo, useState } from "react";
import useNoti from "../../context/notificationhook";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  EmailIcon,
  WhatsappIcon,
} from "react-share";
import {
  incrementPageView,
  getViewCount,
  GetRandomDocuments,
  GetSingleDocument,
} from "../../api/firestore";
import HelmetTags from "../Helmet/helmet";
import ProductPlaceHolder from "../ProductPlaceHolder";
import { format } from "libphonenumber-js";

function product() {
  const { state } = useLocation();
  const [docData, setDocData] = useState(null);
  const navigate = useNavigate();
  const { objectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setMobile] = useState(true);
  const [viewCount, setViewCount] = useState(0);
  const [premiumSlide, setPremiumSlide] = useState([]);
  const [similar, setSimilar] = useState([]);
  const { Show } = useNoti();
  const naviType = useNavigationType();
  const GetPremiumSlides = async () => {
    let documents = await GetRandomDocuments(
      "UserUploads",
      4,
      docData.objectID,
      true
    );
    setPremiumSlide([...documents]);
  };
  const GetSimilarSlides = async () => {
    let documents = await GetRandomDocuments(
      "UserUploads",
      8,
      docData.objectID,
      false,
      docData.type
    );
    setSimilar([...documents]);
  };
  useEffect(() => {
    if (!state) {
      async function GetDocument() {
        await GetSingleDocument("UserUploads", objectId)
          .then((value) => {
            let number = format(value.contactNumbers[0], "E.164");
            number.slice(1);
            value = { ...value, baseNumber: number };
            setDocData(value);
            setIsLoading(false);
          })
          .catch(() => {
            Show("Error Occured", false);
          });
      }
      GetDocument();
    } else {
      setDocData(state);
      setIsLoading(false);
    }
  }, [, objectId]);
  useMemo(() => {
    if (docData) {
      GetPremiumSlides();
      GetSimilarSlides();
      getViewCount(docData.objectID)
        .then((value) => {
          setViewCount(value + 1);
        })
        .catch(() => {
          Show("Error Occured", false);
        });
    }
  }, [, docData]);
  useEffect(() => {
    if (docData) {
      if (!window.navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i)) {
        setMobile(false);
      } else {
        setMobile(true);
      }
      if (naviType == "PUSH") {
        const viewUpdate = setTimeout(() => {
          incrementPageView(docData.objectID, docData.num_shards).catch(() => {
            Show("Error Occured", false);
          });
        }, 3000);
        return () => {
          clearTimeout(viewUpdate);
        };
      }
    }
  }, [docData]);
  if (!isLoading) {
    if (docData) {
      return (
        <div className='w-full pb-2 pt-20 bg-secondary-tint lg:px-28 xl:px-72'>
          <HelmetTags
            pageTitle={`${docData.brand} ${docData.model}- ShowRoomTT`}
            description={`${docData.brand} ${docData.model} Ad Posted at ShowRoomTT`}
            link={window.location.href}
            isProduct={true}
            thumbnail={docData.thumbnail}
          />
          <div className='flex justify-between px-4'>
            <div className='flex gap-1 h-14'>
              {docData.premium ? (
                <Icon
                  className='h-12 w-12 font-bold text-premium self-center'
                  icon='mdi:crown'
                />
              ) : (
                <div className='w-2 h-full bg-primary mr-2' />
              )}
              <div className='font-bold font-head tracking-widest'>
                <h1 className='text-2xl'>{docData.brand}</h1>
                <h2>{docData.model}</h2>
              </div>
            </div>
            <div className='font-semibold tracking-wide'>
              <h1 className='text-lg'>
                Views:
                <span className='text-tertiary'>{viewCount}</span>
              </h1>
            </div>
          </div>
          <div className='flex justify-center mt-6'>
            <Splide
              aria-label='Vehicle Photos'
              options={{
                rewind: false,
                width: "100%",
                mediaQuery: "min",
                height: 300,
                gap: "1rem",
                perPage: 1,
                arrows: false,
                pagination: false,
                padding: { left: 15, right: 45 },
                breakpoints: {
                  640: {
                    perPage: 2,
                    height: 320,
                  },
                  1024: {
                    perPage: 3,
                  },
                },
              }}
            >
              {docData?.images.map((src, index) => {
                return (
                  <SplideSlide key={`img${index}`}>
                    <img
                      className={
                        docData.premium
                          ? "h-full w-full rounded-xl border-2 border-premium"
                          : "h-full w-full rounded-xl"
                      }
                      src={src}
                      alt={`${docData.brand} ${docData.model}-${index}`}
                    />
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
          <div className='w-full mt-4 flex flex-row flex-wrap justify-center'>
            <div className='flex flex-col w-full text-center'>
              {docData.callForPrice || docData.soldStatus ? (
                ""
              ) : (
                <h2 className='text-xl font-bold font-head tracking-wider'>
                  Price
                </h2>
              )}
              {docData.callForPrice || docData.soldStatus ? (
                ""
              ) : docData.price < docData.prevPrice ? (
                <h3 className='text-2xl text-danger line-through opacity-60 font-bold font-head tracking-wider mb-2'>
                  {`$ ${toCommas(docData.prevPrice)}`}
                </h3>
              ) : (
                ""
              )}
              {docData.soldStatus ? (
                <h3 className='w-2/5 md:w-1/5 text-2xl my-2 self-center font-bold bg-accent font-head rotate-6 text-lightText border-solid border-2 border-danger px-2 tracking-widest rounded-lg'>
                  Sold
                </h3>
              ) : (
                <h3 className='text-2xl text-darkText font-bold font-head tracking-wider mb-2'>
                  {docData.callForPrice
                    ? docData.preferredContact == "Whatsapp" ? isMobile ? (
                      <div className="flex justify-center flex-col items-center">
                        <h2>{`${UpperFirst(docData.preferredContact)} for Price`}</h2>
                        <a href={`whatsapp://send?phone=${docData.baseNumber}`}> <Icon
                          className='h-12 w-12 font-bold text-premium self-center'
                          icon='logos:whatsapp-icon'
                        /></a></div>
                    ) : (
                      <div className="flex justify-center flex-col items-center">
                        <h2>{`${UpperFirst(docData.preferredContact)} for Price`}</h2>
                        <a href={`https://api.whatsapp.com/send?phone=${docData.baseNumber}`}><Icon
                          className='h-12 w-12 font-bold text-premium self-center'
                          icon='logos:whatsapp-icon'
                        /></a>
                      </div>)
                      : `${UpperFirst(docData.preferredContact)} for Price`
                    : `$ ${toCommas(docData.price)}`}
                </h3>
              )}
              <div className='self-center w-2/5 md:w-1/5 h-3 bg-accent rounded-xl mt-2' />
            </div>
            <div className='mx-2 mt-8 w-full sm:mx-14 flex justify-center flex-wrap pb-8 border-b-2 border-zinc-400'>
              {docData.negotiable ? (
                <div className='text-center flex flex-col w-2/6 lg:w-1/6'>
                  <Icon
                    className={`h-14 sm:h-16 w-14 sm:w-16 font-bold ${docData.premium ? "text-premium" : "text-secondary"
                      } self-center`}
                    icon='fa6-solid:handshake'
                  />
                  <h3 className='text-lg font-bold font-head'>Negotiable</h3>
                </div>
              ) : (
                ""
              )}
              {docData.workToOwn ? (
                <div className='text-center flex flex-col w-2/6 lg:w-1/6'>
                  <Icon
                    className={`h-14 sm:h-16 w-14 sm:w-16 font-bold ${docData.premium ? "text-premium" : "text-secondary"
                      } self-center`}
                    icon='ic:outline-work'
                  />
                  <h3 className='text-lg font-bold font-head'>Work To Own</h3>
                </div>
              ) : (
                ""
              )}
              {docData.quickSale ? (
                <div className='text-center flex flex-col w-2/6 lg:w-1/6'>
                  <Icon
                    className={`h-14 sm:h-16 w-14 sm:w-16 font-bold ${docData.premium ? "text-premium" : "text-secondary"
                      } self-center`}
                    icon='fluent:flash-20-filled'
                  />
                  <h3 className='text-lg font-bold font-head'>Quick Sale</h3>
                </div>
              ) : (
                ""
              )}
              {docData.tradeAcc ? (
                <div className='text-center flex flex-col w-2/6 lg:w-1/6'>
                  <Icon
                    className={`h-14 sm:h-16 w-14 sm:w-16 font-bold ${docData.premium ? "text-premium" : "text-secondary"
                      } self-center`}
                    icon='uil:exchange'
                  />
                  <h3 className='text-lg font-bold font-head'>Trade Accepted</h3>
                </div>
              ) : (
                ""
              )}
              {docData.year ? (
                <div className='text-center flex flex-col w-2/6 lg:w-1/6'>
                  <Icon
                    className={`h-14 sm:h-16 w-14 sm:w-16 font-bold ${docData.premium ? "text-premium" : "text-secondary"
                      } self-center`}
                    icon='material-symbols:calendar-month-rounded'
                  />
                  <h3 className='text-lg font-bold font-head'>
                    {docData.year}
                  </h3>
                </div>
              ) : (
                ""
              )}
              <div className='text-center flex flex-col w-2/6 lg:w-1/6'>
                <Icon
                  className={`h-14 sm:h-16 w-14 sm:w-16 font-bold ${docData.premium ? "text-premium" : "text-secondary"
                    } self-center`}
                  icon='game-icons:gear-stick'
                />
                <h3 className='text-lg font-bold font-head'>
                  {UpperFirst(docData.trans)}
                </h3>
              </div>
              {docData.mileage ? (
                <div className='text-center flex flex-col w-2/6 lg:w-1/6'>
                  <Icon
                    className={`h-14 sm:h-16 w-14 sm:w-16 font-bold ${docData.premium ? "text-premium" : "text-secondary"
                      } self-center`}
                    icon='tabler:road'
                  />
                  <h3 className='text-lg font-bold font-head'>
                    {docData.mileage} km
                  </h3>
                </div>
              ) : (
                ""
              )}

              {docData.fuel ? (
                <div className='text-center flex flex-col w-2/6 lg:w-1/6'>
                  {docData.fuel == "petrol" || docData.fuel == "hybrid" ? (
                    <Icon
                      className={`h-14 sm:h-16 w-14 sm:w-16 font-bold ${docData.premium ? "text-premium" : "text-secondary"
                        } self-center`}
                      icon='maki:fuel'
                    />
                  ) : (
                    <Icon
                      className={`h-14 w-14 font-bold ${docData.premium ? "text-premium" : "text-secondary"
                        } self-center`}
                      icon='mdi:electric-charger'
                    />
                  )}

                  <h3 className='text-lg font-bold font-head'>
                    {UpperFirst(docData.fuel)}
                  </h3>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className='lg:flex mx-2'>
              <div className='sm:px-14 mt-8 flex flex-wrap gap-2 justify-between w-full pb-8 border-b-2 border-zinc-400'>
                <h2 className='w-full text-center text-2xl tracking-wider font-bold font-head'>
                  Contact Information
                </h2>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    {docData.contactNumbers.length == 1
                      ? "Contact Number:"
                      : "Contact Numbers:"}
                  </p>
                  {docData.contactNumbers.map((item) => (
                    <p className='text-lg font-bold tracking-wider' key={item}>
                      {item}
                    </p>
                  ))}
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Contact Name:
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.contactName}
                  </p>
                </div>
                <div className='w-1/2'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Email:
                  </p>
                  <p className='text-lg font-bold tracking-wider break-words'>
                    {docData.contactEmail}
                  </p>
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Preferred Contact
                  </p>
                  {docData.preferredContact == "Whatsapp" ? isMobile ? (
                    <div className="flex justify-center">
                      <a href={`whatsapp://send?phone=${docData.baseNumber}`}> <Icon
                        className='h-10 w-10 font-bold text-premium self-center'
                        icon='logos:whatsapp-icon'
                      /></a></div>
                  ) : (
                    <div className="flex justify-center">
                      <a href={`https://api.whatsapp.com/send?phone=${docData.baseNumber}`}><Icon
                        className='h-10 w-10 font-bold text-premium self-center'
                        icon='logos:whatsapp-icon'
                      /></a>
                    </div>) : (<p className='text-lg font-bold tracking-wider'>
                      {UpperFirst(docData.preferredContact)}
                    </p>)}

                </div>
              </div>
              <div className='px-4 sm:px-14 mt-8 flex flex-wrap gap-2 justify-between w-full pb-8 border-b-2 border-zinc-400'>
                <h2 className='w-full text-center text-2xl tracking-wider font-bold font-head'>
                  Vehicle Information
                </h2>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Location
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.location}
                  </p>
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Brand:
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.brand}
                  </p>
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Model:
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.model}
                  </p>
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Color:
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.color ? UpperFirst(docData.color) : "--"}
                  </p>
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Series:
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.series}
                  </p>
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Condition:
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.condition}
                  </p>
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Transmission:
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.trans ? UpperFirst(docData.trans) : "--"}
                  </p>
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Fuel Type:
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.fuel ? UpperFirst(docData.fuel) : "--"}
                  </p>
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Mileage:
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.mileage ? `${docData.mileage} km` : "--"}
                  </p>
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Engine Size:
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.engineSize} CC
                  </p>
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Year:
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.year ? docData.year : "--"}
                  </p>
                </div>
                <div className='w-2/5'>
                  <p className='font-semibold tracking-wide text-zinc-600'>
                    Seating Capacity:
                  </p>
                  <p className='text-lg font-bold tracking-wider'>
                    {docData.seatingCapacity ? docData.seatingCapacity : "--"}
                  </p>
                </div>
              </div>
              {docData.features.length != 0 ? (
                <div className='w-full border-b-2 border-zinc-400 py-4'>
                  <h2 className='w-full text-center text-2xl tracking-wider font-bold font-head'>
                    Features
                  </h2>
                  <ul className='flex flex-wrap'>
                    {docData.features.map((feat) => {
                      return (
                        <li key={feat} className='relative flex w-1/2'>
                          <Icon
                            className='absolute z-10 inline h-10 w-10 font-bold text-secondary self-start'
                            icon='material-symbols:check-small-rounded'
                          />
                          <p className='indent-10 leading-5 font-semibold tracking-wide align-baseline mt-2'>
                            {feat}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
              {docData.addInfo ? (
                <div className='w-full border-b-2 border-zinc-400 py-4'>
                  <h2 className='w-full text-center text-2xl tracking-wider font-bold font-head'>
                    Additional Info.
                  </h2>
                  <p className='font-medium mt-4 indent-4'>{docData.addInfo}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className='my-8 sm:mx-10'>
            <div className='flex text-start'>
              <Icon
                className='h-8 sm:h-8 w-14 font-bold text-secondary align-center'
                icon='material-symbols:share'
              />
              <h2 className='w-full text-2xl tracking-wider font-bold font-head'>
                Share
              </h2>
            </div>
            <div className='flex justify-between m-4 sm:w-2/4 lg:w-1/4 2xl:w-1/5'>
              <EmailShareButton
                url={`https://${window.location.hostname}/s/${docData.objectID}`}
              >
                <EmailIcon size={45} round={true} />
              </EmailShareButton>
              <FacebookShareButton
                url={`https://${window.location.hostname}/s/${docData.objectID}`}
              >
                <FacebookIcon size={45} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={`https://${window.location.hostname}/s/${docData.objectID}`}
              >
                <div className='bg-black rounded-full'>
                  <Icon
                    className='h-11 w-11 p-2 font-bold text-white'
                    icon='ri:twitter-x-fill'
                  />
                </div>
              </TwitterShareButton>
              <WhatsappShareButton
                url={`https://${window.location.hostname}/s/${docData.objectID}`}
              >
                <WhatsappIcon size={45} round={true} />
              </WhatsappShareButton>
            </div>
          </div>
          {premiumSlide.length == 0 ? (
            ""
          ) : (
            <div className='my-4 sm:mx-10'>
              <div className='relative text-start'>
                <h2 className='text-tcolor font-semibold text-xl tracking-wide mb-6 ml-4'>
                  Other Premium Ads
                </h2>
              </div>
              <div className='grid grid-flow-row grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-1 sm:gap-2 place-items-start justify-items-center'>
                {premiumSlide?.map((item) => {
                  return <Card key={item.objectID} data={item} />;
                })}
              </div>
            </div>
          )}
          {similar.length == 0 ? (
            ""
          ) : (
            <div className='my-4 sm:mx-10'>
              <div className='relative text-start'>
                <h2 className='text-tcolor font-semibold text-xl tracking-wide mb-6 ml-4'>
                  Similar Ads
                </h2>
              </div>
              <div className='grid grid-flow-row grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-1 sm:gap-2 place-items-start justify-items-center'>
                {similar?.map((item) => {
                  return <Card key={item.objectID} data={item} />;
                })}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className='w-full flex justify-center pt-32 px-8 flex-wrap'>
          <img
            className='w-2/5 md:w-2/12 h-full'
            src={noResult}
            alt='Ad Not Found'
          />
          <div className='w-full text-center mt-4'>
            <h2 className='text-2xl text-darkText font-semibold font-head tracking-wide'>
              {"Ad Not Found (The ad either does not exist or is already sold)"}
            </h2>
          </div>
          <button
            onClick={() => {
              navigate("/");
            }}
            className='mt-4 px-3 h-10 bg-primary text-lightText font-bold tracking-wide rounded-lg'
          >
            Go to HomePage
          </button>
        </div>
      );
    }
  } else {
    return (
      <div className='w-full pb-2 pt-20 bg-secondary-tint lg:px-28 xl:px-72'>
        <ProductPlaceHolder />
      </div>
    );
  }
}

export default product;
