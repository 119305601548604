import { Formik, Form, Field, FieldArray } from "formik";
import { Icon } from "@iconify/react";
import { AccountInfo, tempSchema } from "./accountSettingsSchema";
import { UpdateDocumentField, GetSingleDocument } from "../../api/firestore";
import phoneUtil from "google-libphonenumber";
import { useEffect, useState } from "react";
import { DeleteUserAccount, signOutUser } from "../../api/fireauth";
import { useNavigate } from "react-router-dom";
const phoneParser = phoneUtil.PhoneNumberUtil.getInstance();
const PNF = phoneUtil.PhoneNumberFormat;
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
function AccountSettings({ user }) {
  const [profileInfo, setProfileInfo] = useState();
  const [toggleDelete, setToggleDelete] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [phone, setPhone] = useState({ number: "" });
  const [editToggle, setEditToggle] = useState(false);
  const navigate = useNavigate();
  const SaveData = async (values) => {
    setEditToggle(false);
    setIsLoad(true);
    await UpdateDocumentField(user.uid, "Users", {
      contactName: values.contactName,
      contactNumbers: values.contactNumbers,
    }).then((res) => {
      if (res.success) {
        GetUserProfile();
      }
    });
  };
  const GetUserProfile = () => {
    GetSingleDocument("Users", user.uid).then((data) => {
      setProfileInfo({
        contactName: data.contactName,
        contactNumbers: data.contactNumbers,
      });
      setIsLoad(false);
    });
  };

  const DeleteAccount = (values) => {
    if (values.confirm === "CONFIRM") {
      DeleteUserAccount(user).then((res) => {
        signOutUser(() => {
          navigate("/", {
            state: res,
          });
        });
      });
    }
  };
  useEffect(() => {
    GetUserProfile();
  }, []);

  return (
    <div className='mx-2 mt-8'>
      <div className='my-2 border-b-2 border-zinc-400'>
        <h3 className='font-head text-2xl font-bold tracking-wider pb-4'>
          Account Settings
        </h3>
      </div>
      <div className='flex flex-col justify-center items-center'>
        <section className='my-6 w-full md:w-1/2'>
          <div className='flex justify-between'>
            <h3 className='text-zinc-600 font-head text-xl font-bold tracking-wider pb-2'>
              General Info
            </h3>
            <div className='flex flex-col'>
              <Icon
                onClick={() => {
                  setEditToggle((prev) => !prev);
                }}
                className={editToggle ? "self-center w-8 h-8 font-bold text-tertiary" : "self-center w-8 h-8 font-bold text-zinc-600"}

                icon={editToggle ? "fluent:edit-off-20-regular" : 'lucide:edit'}
              />
              {editToggle ? <p
                className="text-center text-tertiary font-semibold text-sm"

              >
                Cancel
              </p> :
                <p
                  className="text-center text-zinc-600 font-semibold text-sm"

                >
                  Edit
                </p>
              }
            </div>
          </div>
          <div>
            {isLoad ? (
              ""
            ) : (
              <Formik
                initialValues={{
                  userName: profileInfo.contactName,
                  contactName: profileInfo.contactName,
                  contactNumbers: profileInfo.contactNumbers,
                  tempNumber: "",
                }}
                onSubmit={SaveData}
                validationSchema={AccountInfo}
              >
                {({
                  values,
                  setFieldValue,
                  errors,
                  setFieldError,
                  dirty,
                  handleReset,
                }) => (
                  <Form>
                    <div className='my-3'>
                      <h3 className='font-bold text-lg text-zinc-500 mb-2'>
                        Username
                      </h3>
                      <Field
                        className='w-4/5 h-10 py-2 bg-zinc-300 pl-4 text-zinc-700 border-2 border-tertiary rounded-lg font-semibold tracking-widest disabled:border-zinc-400'
                        name='userName'
                        disabled={!editToggle}
                        type='text'
                        component='input'
                        placeholder='Username'
                      ></Field>
                      {errors.userName ? (
                        <h3 className='text-danger font-semibold tracking-wider my-2'>
                          {errors.userName}
                        </h3>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className='my-3'>
                      <h3 className='font-bold text-lg text-zinc-500 mb-2'>
                        Contact Name
                      </h3>
                      <Field
                        className='w-4/5 h-10 py-2 bg-zinc-300 pl-4 text-zinc-700 border-2 border-tertiary rounded-lg font-semibold tracking-widest disabled:border-zinc-400'
                        name='contactName'
                        disabled={!editToggle}
                        type='text'
                        component='input'
                        placeholder='Contact Name'
                      ></Field>
                      {errors.contactName ? (
                        <h3 className='text-danger font-semibold tracking-wider my-2'>
                          {errors.contactName}
                        </h3>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className='my-3'>
                      <h3 className='font-bold text-lg text-zinc-500 mb-2'>
                        Account Email
                      </h3>
                      <div className='w-4/5 h-10 py-2 bg-zinc-300 pl-4 text-zinc-700 border-2 border-zinc-400 rounded-lg font-semibold tracking-widest'>
                        {user.email}
                      </div>
                    </div>
                    <FieldArray
                      name='contactNumbers'
                      render={(arrayHelpers) => (
                        <>
                          {values.contactNumbers.length == 0 && !editToggle ? "" : <h3 className='font-bold text-lg text-zinc-500 mb-2'>
                            {values.contactNumbers.length > 1
                              ? "Contact Numbers"
                              : "Contact Number"}
                          </h3>}
                          {editToggle ? (
                            <div className='flex items-center w-full'>
                              <PhoneInput
                                style={{ '--react-international-phone-font-size': '16px', border: '2px solid gray', borderRadius: "8px 0px 0px 8px" }}
                                defaultCountry="tt"
                                value={phone.number}
                                onChange={(phone, meta) => setPhone({ number: phone, meta })}
                              />
                              <div className='flex flex-col justify-center h-10 w-12 bg-zinc-300 rounded-r-lg'>
                                <Icon
                                  className='h-4/6 w-5/6 mx-auto font-bold inline text-secondary'
                                  onClick={() => {
                                    try {
                                      if (editToggle) {
                                        if (values.contactNumbers?.length == 3) {
                                          return;
                                        }
                                        tempSchema.validateSync(
                                          phone
                                        );
                                        let number = phoneParser.parseAndKeepRawInput(phone.number, phone.meta.iso2);
                                        arrayHelpers.push(`+${number.getCountryCode()} ${phoneParser.format(number, PNF.NATIONAL)}`);
                                        setFieldValue("tempNumber", "", false);
                                      }
                                    } catch (e) {
                                      setFieldError("tempNumber", e.message);
                                    }
                                  }}
                                  icon='fluent-mdl2:circle-addition'
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {errors.tempNumber ? (
                            <h3 className='text-danger font-semibold tracking-wider my-2'>
                              {errors.tempNumber}
                            </h3>
                          ) : (
                            ""
                          )}
                          {values.contactNumbers?.length !== 0 ? (
                            <div className='w-full flex flex-col mt-4'>
                              <ul className='flex flex-wrap justify-start gap-1'>
                                {values.contactNumbers?.map((data, index) => (
                                  <li
                                    className={
                                      editToggle
                                        ? "w-4/6 text-xl rounded-md flex font-bold bg-tertiary justify-center tracking-widest text-lightText py-1 px-3"
                                        : "w-4/6 text-xl rounded-md flex font-bold bg-secondary justify-center tracking-widest text-lightText py-1 px-3"
                                    }
                                    key={data}
                                    onClick={() => {
                                      if (editToggle) {
                                        arrayHelpers.remove(index);
                                      }
                                    }}
                                  >
                                    {data}
                                  </li>
                                ))}
                              </ul>
                              {values.contactNumbers?.length === 3 && editToggle ? (
                                <span className='w-full text-danger text-center mt-2 font-semibold'>
                                  Maximum of 3 Numbers
                                </span>
                              ) : (
                                ""
                              )}
                              {editToggle ? (
                                <span className='w-full text-zinc-600 text-center mt-4 font-semibold'>
                                  Click/Tap Contact Number to Remove
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    />
                    {dirty && editToggle ? (
                      <div className='flex w-full justify-center my-4 gap-8'>
                        <button
                          className='bg-primary text-center text-lightText rounded-md font-bold h-10 px-8 py-0 tracking-wider'
                          type='submit'
                        >
                          Save
                        </button>
                        <button
                          className='bg-zinc-300 text-center text-darkText rounded-md font-bold h-10 px-8 py-0 tracking-wider'
                          type='button'
                          onClick={() => {
                            handleReset();
                            setEditToggle(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </section>
        <section className='my-4 w-full md:w-1/2'>
          <h3 className='text-zinc-600 font-head text-xl font-bold tracking-wider pb-2 border-b-2 border-zinc-400'>
            Delete Account
          </h3>
          <div className='flex flex-col'>
            <p className='text-lg font-semibold text-darkText mb-8'>
              Once you delete your account, there is no going back. Please be
              certain.
            </p>
            <button
              className='bg-zinc-300 border-2 border-black items-center w-4/6 flex justify-center self-center text-danger rounded-md font-bold h-10 px-8 tracking-wide'
              type='button'
              onClick={() => {
                setToggleDelete((prev) => !prev);
              }}
            >
              Delete Account
              {toggleDelete ? (
                <Icon
                  className='text-3xl lg:text-5xl xl:text-6xl font-bold'
                  icon='ic:round-arrow-drop-up'
                />
              ) : (
                <Icon
                  className='text-3xl lg:text-5xl xl:text-6xl font-bold'
                  icon='ic:round-arrow-drop-down'
                />
              )}
            </button>
          </div>
          {toggleDelete ? (
            <div className='my-4 flex justify-center'>
              <Formik
                initialValues={{
                  confirm: "",
                }}
                onSubmit={DeleteAccount}
              >
                {({ values }) => (
                  <Form>
                    <div className='flex flex-col'>
                      <h3 className='font-bold text-lg text-zinc-500 mb-2'>
                        Enter "CONFIRM" to continue.
                      </h3>
                      <Field
                        name='confirm'
                        className='w-full h-10 text-center py-2 bg-zinc-300 text-zinc-700 border-2 border-zinc-400 placeholder:font-bold rounded-lg font-semibold tracking-widest'
                        type='text'
                        component='input'
                        placeholder='CONFIRM'
                      ></Field>
                      <button
                        className={
                          values.confirm !== "CONFIRM"
                            ? "bg-danger opacity-40  text-center text-lightText rounded-md font-bold h-10 px-8 py-0 mt-4 tracking-widest"
                            : "bg-danger text-center text-lightText rounded-md font-bold h-10 px-8 py-0 mt-4 tracking-widest"
                        }
                        type='submit'
                        disabled={values.confirm !== "CONFIRM"}
                      >
                        Delete
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            ""
          )}
        </section>
      </div>
    </div>
  );
}

export default AccountSettings;
