import { searchClient } from "@algolia/client-search";

const client = searchClient(
  "FYECMA2WIP",
  "1ed3c79e63867d3a86b4d3b95c8f6007"
);
const baseIndex = "showroom_userupload_index";
const ascIndex = "asc_price_sort_index";
const dscIndex = "dsc_price_sort_index";
export default { client, baseIndex, ascIndex, dscIndex };
