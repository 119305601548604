import app from "./firebase";
import * as Sentry from "@sentry/react";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  onAuthStateChanged,
  signOut,
  deleteUser,
  signInWithCredential,
  GoogleAuthProvider
} from "firebase/auth";
import { createContext, useContext, useEffect, useState, useMemo } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "./functions";

const auth = getAuth(app);
const userAuthContext = createContext();
const sendEmailLink = async (email, isSignUp = false, contactName = null) => {
  try {
    const SendLink = httpsCallable(functions, "SendEmailLink");
    await SendLink({ email }).then(() => {
      localStorage.setItem("emailForSignIn", email);
      if (isSignUp) {
        if (contactName) {
          localStorage.setItem("contactName", contactName);
          localStorage.setItem("SignUp", isSignUp);
        }
      }
    });
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
const authEmailLink = async () => {
  try {
    const isAuth = isSignInWithEmailLink(auth, location.href);
    let NewUser = null;
    if (isAuth) {
      if (localStorage.getItem("emailForSignIn")) {
        await signInWithEmailLink(
          auth,
          localStorage.getItem("emailForSignIn"),
          location.href
        )
          .then(async (cred) => {
            if (localStorage.getItem("contactName")) {
              if (localStorage.getItem("SignUp")) {
                NewUser = {
                  name: localStorage.getItem("contactName"),
                  uid: cred.user.uid,
                  email: cred.user.email,
                }
                localStorage.removeItem("contactName");
                localStorage.removeItem("SignUp");
              }
            }
            localStorage.removeItem("emailForSignIn");
          })
          .catch((e) => {
            throw e;
          });
      }
      else {
        throw new Error('Local Storage Email Not Found.');
      }
    }
    return { isAuth, NewUser };
  } catch (e) {
    Sentry.captureException(e);
  }
};

const DeleteUserAccount = async (user) => {
  try {
    await deleteUser(user);
    return { msg: "User Deleted", success: true };
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
const UserAuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user == null) {
        setCurrentUser(false);
        return;
      }
      setCurrentUser(user);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  const user = useMemo(() => currentUser, [currentUser]);
  return (
    <userAuthContext.Provider value={{ user }}>
      {children}
    </userAuthContext.Provider>
  );
};
const signOutUser = async (func) => {
  try {
    await signOut(auth)
      .then(() => {
        func();
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
const isAdmin = () => {
  return auth.currentUser.getIdTokenResult().then((idTokenResult) => {
    if (idTokenResult.claims.admin || idTokenResult.claims.owner) {
      return true;
    } else {
      return false;
    }
  });
};
const getCurrentUser = () => {
  return auth.currentUser;
};

const useUserAuth = () => {
  return useContext(userAuthContext);
};
const signInWithProvider = async (credentials) => {
  try {
    let cred = GoogleAuthProvider.credential(credentials.credential);
    const userCred = await signInWithCredential(auth, cred);
    return userCred.user;
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
export {
  sendEmailLink,
  authEmailLink,
  UserAuthContextProvider,
  getCurrentUser,
  useUserAuth,
  signOutUser,
  DeleteUserAccount,
  signInWithProvider,
  isAdmin,
};
