import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { UpdateDocumentField } from "../../api/firestore";
import { Formik, Form, Field } from "formik";
import PriceSchema from "./editPriceShcema";
import useNoti from "../../context/notificationhook";
function EditCard({ data }) {
  try {
    const [isNew, setIsNew] = useState(null);
    const [isSold, setIsSold] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [price, setPrice] = useState({
      price: data.price,
      callForPrice: data.callForPrice,
      prevPrice: data.prevPrice,
    });
    const navigate = useNavigate();
    const { Show } = useNoti();
    const toCommas = (value) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const NewValidate = () => {
      const endTime = Math.floor(Date.now() / 1000);
      const secs = endTime - data.uploadDate.seconds;
      let min = secs / 60;
      let hours = min / 60;
      let days = Math.floor(hours / 24);
      if (days > 7) {
        setIsNew(false);
      } else {
        setIsNew(true);
      }
    };

    const ChangePrice = async (values, { setSubmitting }) => {
      let updatedData = {
        ...values,
        prevPrice: price.price,
      };
      if (values.callForPrice) {
        updatedData = {
          ...values,
          price: 0,
          prevPrice: price.price,
        };
      }
      await UpdateDocumentField(data.objectID, "UserUploads", updatedData)
        .then((res) => {
          Show(res.msg, res.success);
        })
        .finally(() => {
          setPrice({
            price: updatedData.price,
            prevPrice: updatedData.prevPrice,
            callForPrice: updatedData.callForPrice,
          });
          setSubmitting(false);
          setOpenEdit(false);
        });
      return;
    };

    const setSoldState = async (evt) => {
      evt.stopPropagation();
      setIsSold((prev) => !prev);
      let done = await UpdateDocumentField(
        data.objectID,
        "UserUploads",
        {
          soldStatus: !data.soldStatus,
        },
        true,
        "soldDate"
      );
      if (done.success) {
        return;
      }
      setIsSold((prev) => !prev);
    };
    useEffect(() => {
      if (data.soldStatus) {
        setIsSold(true);
      }
      NewValidate();
    }, []);
    const GoToProduct = () => {
      navigate(`/product/${data.objectID}`, {
        state: { ...data, soldStatus: isSold, ...price },
      });
    };
    return (
      <div className='w-full bg-cardgrey rounded-xl min-h-[10rem]'>
        <div className='flex' onClick={GoToProduct}>
          <div className='relative w-2/5'>
            {isSold ? (
              <div className='absolute z-[1] px-4 py-1 bg-danger rounded-tl-lg rounded-br-lg text-center text-lightText drop-shadow-md'>
                <p className='font-semibold text-sm tracking-widest'>SOLD</p>
              </div>
            ) : (
              ""
            )}
            <img
              className={
                isSold
                  ? "h-4/5 w-full rounded-tl-lg grayscale"
                  : "h-4/5 w-full rounded-tl-lg"
              }
              src={data.thumbnail}
              alt='Image of A car'
            />
            <div className='h-1/5 bg-primary text-center text-white font-bold'>
              <p className='inline tracking-widest align-middle'>
                {price.price == 0
                  ? "Call For Price"
                  : `$ ${toCommas(price.price)}`}
              </p>
            </div>
          </div>
          <div className='relative text-tcolor w-3/5'>
            <div className='relative z-10 w-full h-full'>
              {!(isNew || data.premium) ? (
                ""
              ) : (
                <div className='grid grid-cols-2 text-xs font-bold h-6'>
                  {isNew ? (
                    <div className='px-4 py-1 bg-danger text-white rounded-tr-full col-start-1 drop-shadow-md text-center'>
                      <p>New Ad</p>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.premium ? (
                    <div className='flex justify-around pl-2 py-1 bg-premium rounded-bl-full text-center col-start-2 drop-shadow-md'>
                      <Icon
                        className='h-4 w-4 font-bold text-dark self-center'
                        icon='mdi:crown'
                      />
                      <p>Premium</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div
                className={
                  !(isNew || data.premium)
                    ? "px-2 h-full flex flex-col justify-center"
                    : "px-2 h-full"
                }
              >
                <div className='flex gap-2'>
                  <span>Brand:</span>
                  <p className='font-semibold truncate'>{data.brand}</p>
                </div>
                <div className='flex gap-2'>
                  <span>Model:</span>
                  <p className='font-semibold truncate'>{data.model}</p>
                </div>
                <div className='flex gap-2'>
                  <span>Series:</span>
                  <p className='font-semibold truncate'>{data.series}</p>
                </div>
                <div className='flex gap-2'>
                  <span>Year:</span>
                  <p className='font-semibold truncate'>{data.year}</p>
                </div>
                <div className='flex gap-2'>
                  <span>Transmission:</span>
                  <p className='font-semibold truncate'>{data.trans}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {openEdit ? (
          <Formik
            initialValues={{
              price: price.price,
              callForPrice: price.callForPrice,
              negotiable: data.negotiable,
              quickSale: data.quickSale,
              workToOwn: data.workToOwn,
              tradeAcc: data.tradeAcc
            }}
            validationSchema={PriceSchema}
            onSubmit={ChangePrice}
          >
            {({ values, errors, touched, isSubmitting, dirty }) => (
              <Form className='py-4'>
                <p className='text-base font-head font-bold block text-center'>
                  Edit Price
                </p>
                <div className='pl-2 grid grid-cols-2 gap-2'>
                  <label className='relative inline-flex items-center cursor-pointer'>
                    <Field
                      type='checkbox'
                      name='callForPrice'
                      component='input'
                      className='sr-only peer'
                    />
                    <div className="w-11 h-6 bg-zinc-400 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    <span className='ml-3 text-md font-semibold text-secondary'>
                      Call For Price
                    </span>
                  </label>
                  <label className='relative inline-flex items-center cursor-pointer'>
                    <Field
                      type='checkbox'
                      name='negotiable'
                      component='input'
                      className='sr-only peer'
                    />
                    <div className="w-11 h-6 bg-zinc-400 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    <span className='ml-3 text-md font-semibold text-secondary'>
                      Negotiable
                    </span>
                  </label>
                  <label className='relative inline-flex items-center cursor-pointer'>
                    <Field
                      type='checkbox'
                      name='quickSale'
                      component='input'
                      className='sr-only peer'
                    />
                    <div className="w-11 h-6 bg-zinc-400 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    <span className='ml-3 text-md font-semibold text-secondary'>
                      Quick Sale
                    </span>
                  </label>
                  <label className='relative inline-flex items-center cursor-pointer'>
                    <Field
                      type='checkbox'
                      name='workToOwn'
                      component='input'
                      className='sr-only peer'
                    />
                    <div className="w-11 h-6 bg-zinc-400 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    <span className='ml-3 text-md font-semibold text-secondary'>
                      Work To Own
                    </span>
                  </label>
                  <label className='relative inline-flex items-center cursor-pointer'>
                    <Field
                      type='checkbox'
                      name='tradeAcc'
                      component='input'
                      className='sr-only peer'
                    />
                    <div className="w-11 h-6 bg-zinc-400 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    <span className='ml-3 text-md font-semibold text-secondary'>
                      Trade
                    </span>
                  </label>
                </div>
                {values.callForPrice ? (
                  ""
                ) : (
                  <div className='flex justify-center mt-4'>
                    <Field
                      className='w-4/6 py-1 bg-light text-black rounded-2xl text-center font-semibold tracking-widest'
                      name='price'
                      component='input'
                      type='number'
                      maxLength='10'
                      placeholder='Enter Price'
                    />
                  </div>
                )}
                {errors.price && touched.price ? (
                  <sub className='text-base text-center text-danger font-semibold block my-2'>
                    {` ${errors.price}`}
                  </sub>
                ) : (
                  ""
                )}
                {values.callForPrice ? (
                  ""
                ) : !errors.price && values.price !== "" ? (
                  <div className='w-full text-center mt-2'>
                    <h3 className='text-xl font-head text-secondary font-bold'>
                      {`$ ${toCommas(values.price)} TTD`}
                    </h3>
                  </div>
                ) : (
                  ""
                )}
                <div className='w-full mt-2 flex justify-center gap-8'>
                  {dirty ? (
                    <button
                      className='bg-success w-24 text-center text-light font-bold rounded-lg px-4 py-1 tracking-wider'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    className='bg-zinc-500 w-24 text-center text-light font-bold rounded-lg px-4 py-1 tracking-wider'
                    type='button'
                    disabled={isSubmitting}
                    onClick={() => {
                      setOpenEdit(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div className='mt-2  pt-2 grid grid-cols-2 gap-1 border-t-2 border-zinc-400'>
            {data.premium || isSold ? (
              ""
            ) : (
              <button
                type='button'
                className='bg-premium font-semibold tracking-wider text-base px-2 py-1 rounded-md'
                onClick={(evt) => {
                  navigate("/checkout", {
                    state: {
                      operation: 3,
                      objectID: data.objectID,
                      price: 10,
                    },
                  });
                }}
              >
                Premium Upgrade
              </button>
            )}
            {isSold ? (
              ""
            ) : (
              <button
                type='button'
                className='bg-secondary/70 font-semibold tracking-wider px-2 py-1 rounded-md text-darkText'
                onClick={(evt) => {
                  navigate("/checkout", {
                    state: {
                      operation: 4,
                      objectID: data.objectID,
                      price: 5,
                    },
                  });
                }}
              >
                Refresh
              </button>
            )}
            <button
              type='button'
              onClick={(evt) => {
                setSoldState(evt);
              }}
              className={
                isSold
                  ? "bg-success font-semibold tracking-wider px-2 py-1 rounded-md text-white"
                  : "bg-secondary/70 font-semibold tracking-wider px-2 py-1 rounded-md text-darkText"
              }
            >
              {isSold ? "Sold" : "Mark as Sold"}
            </button>
            {isSold ? (
              ""
            ) : (
              <button
                type='button'
                onClick={(evt) => {
                  setOpenEdit(true);
                }}
                className='bg-secondary/70 font-semibold tracking-wider px-2 py-1 rounded-md text-darkText'
              >
                Edit Price
              </button>
            )}
          </div>
        )}
      </div>
    );
  } catch (e) {
    console.log(e);
  }
}

export default EditCard;
