import { Icon } from "@iconify/react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { CallableCapture, CallableOrder } from "../../../api/paypal";
import { useEffect, useState } from "react";
import useNoti from "../../../context/notificationhook";
import { toPrice } from "../../../utils/priceConverter";
import { Formik, Field, Form } from "formik";
import {
  GetSingleDocument,
  CalculateVoucher,
  IncrementVoucher,
} from "../../../api/firestore";

function PaymentForm({ uid, price, item, operation, submit, previous }) {
  const [thumbnail, setThumbnail] = useState("");
  const [onProcessing, setOnProcessing] = useState(false);
  const [onComplete, setOnComplete] = useState(false);
  const [isdisabled, setDisabled] = useState(false);
  const [disableContinue, setDisableContinue] = useState(false);
  const [voucherSpinner, setVoucherSpinner] = useState(false);
  const [voucherList, setVoucherList] = useState([]);
  const [voucherError, setVoucherError] = useState("");
  const [priceState, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const { Show } = useNoti();

  const initialOptions = {
    clientId: "test",
    currency: "USD",
    intent: "capture",
    components: "buttons",
  };
  let order;
  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setThumbnail(reader.result);
    });
    reader.readAsDataURL(item.media[0]);
  }, []);
  useEffect(() => {
    setPrice(price - discount);
  }, [discount]);

  const GetVoucherData = async (values) => {
    return GetSingleDocument("Vouchers", null, {
      key: "code",
      operator: "==",
      value: values.voucher.toUpperCase().trim(),
    }).then((data) => {
      if (data == null) {
        setVoucherError("Invalid Voucher");
      } else {
        let validOperation = false;
        data.type.map((item) => {
          if (item == operation) {
            validOperation = true;
          }
        });
        if (validOperation) {
          GetSingleDocument(`Vouchers/${data.refID}/Claims`, uid).then(
            (claimDoc) => {
              if (claimDoc == null) {
                setDiscount((prev) => {
                  let discountCalc = price * (data.discount / 100);
                  return prev + discountCalc;
                });
                setVoucherList((prev) => [...prev, data]);
              } else {
                if (!data.infinite) {
                  if (claimDoc.claimed >= data.claimCount) {
                    setVoucherError("Voucher Already Claimed");
                    return;
                  }
                  setDiscount((prev) => {
                    let discountCalc = price * (data.discount / 100);
                    return prev + discountCalc;
                  });
                  setVoucherList((prev) => [...prev, data]);
                  return;
                }
                setDiscount((prev) => {
                  let discountCalc = price * (data.discount / 100);
                  return prev + discountCalc;
                });
                setVoucherList((prev) => [...prev, data]);
              }
            }
          );
        } else {
          setVoucherError("Voucher Does Not Apply");
        }
      }
    });
  };
  const voucherSubmit = async (values, actions) => {
    setVoucherSpinner(true);
    setVoucherError("");

    if (voucherList.length == 0) {
      await GetVoucherData(values);
    }
    for (let item in voucherList) {
      if (item.code == values.voucher.toUpperCase().trim()) {
        setVoucherError("Voucher Already Exist");
      } else {
        await GetVoucherData(values);
      }
    }
    actions.resetForm();
    setVoucherSpinner(false);
  };
  return (
    <div className='relative flex flex-col items-center px-4 lg:px-10 h-full bg-light'>
      <div
        className={
          onProcessing
            ? "fixed pt-16 z-50 top-0 bottom-0 h-screen w-full backdrop-blur-sm bg-light/20 opacity-100 transition duration-300"
            : "absolute opacity-0 transition duration-300 pt-16 -z-50 h-screen w-full backdrop-blur-sm bg-light/20"
        }
      >
        <div className='w-full h-full flex flex-col justify-center items-center'>
          {onComplete ? (
            <div className='relative bottom-12 flex flex-col items-center'>
              <Icon className='w-16 h-auto text-darkText' icon='subway:tick' />
              <h2 className='text-4xl font-extrabold font-head text-darkText tracking-wide'>
                Transaction Successful
              </h2>
            </div>
          ) : (
            <div className='relative bottom-12 flex flex-col items-center'>
              <div className='w-12 h-12 rounded-full animate-spin border-8 border-solid border-darkText border-t-transparent'></div>
              <h2 className='text-4xl font-extrabold font-head text-darkText tracking-wide'>
                Processing Transaction
              </h2>
            </div>
          )}
        </div>
      </div>
      <div className='pt-2 w-full sm:w-1/2'>
        <div>
          <h4 className='font-bold font-head text-3xl text-darkText'>{`${operation == 1
            ? "Premium Ad"
            : operation == 2
              ? "Basic Ad"
              : "Invalid Operation"
            }`}</h4>
          <div className='my-6 bg-zinc-300 drop-shadow-2xl rounded-xl p-4'>
            <div className='mb-6 border-b-2 border-zinc-400 pb-2'>
              <div className='border-b-2 border-zinc-400 pb-2 mb-2'>
                <div className='flex justify-between'>
                  <h2 className='text-lg font-semibold'>Subtotal:</h2>
                  <span className='font-bold font-head text-zinc-700 text-xl'>{`$ ${toPrice(
                    price
                  )} USD`}</span>
                </div>
                <div className='flex justify-between'>
                  <h2 className='text-lg font-semibold'>Discount:</h2>
                  <span className='font-bold font-head text-zinc-700 text-xl'>
                    {`- ${discount == 0 ? 0 : toPrice(discount)} USD`}
                  </span>
                </div>
              </div>
              <h2 className='text-lg font-semibold'>Apply Voucher Code:</h2>
              <Formik
                initialValues={{
                  voucher: "",
                }}
                onSubmit={voucherSubmit}
              >
                <>
                  <Form className='flex gap-2 justify-start'>
                    <Field
                      name='voucher'
                      className='w-4/5 py-2 pl-2 bg-zinc-600 text-light rounded-lg text-start tracking-widest placeholder:text-light'
                      type='text'
                      component='input'
                      placeholder='Voucher'
                      maxLength='20'
                    />
                    <button
                      className='bg-zinc-600 rounded-lg py-2 px-2 text-light'
                      type='submit'
                      disabled={voucherSpinner}
                    >
                      {voucherSpinner ? (
                        <div className='w-6 h-6 rounded-full animate-spin border-4 border-solid border-light border-t-transparent'></div>
                      ) : (
                        "Apply"
                      )}
                    </button>
                  </Form>
                  {voucherError ? (
                    <div className='my-2'>
                      <p className='text-danger font-semibold'>
                        {voucherError}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              </Formik>
              {voucherList.length == 0 ? (
                ""
              ) : (
                <div>
                  <h2 className='text-lg font-semibold mt-4'>
                    Active Vouchers:
                  </h2>
                  <ul className='flex flex-col items-start mt-1 h-full'>
                    {voucherList?.map((item, index) => (
                      <li
                        className='tracking-wider font-head bg-primary text-light rounded-lg py-1 px-2'
                        key={item.code}
                        onClick={() => {
                          setPrice((prev) => {
                            let discountCalc = price * (item.discount / 100);
                            return prev + discountCalc;
                          });
                          setDiscount((prev) => {
                            let discountCalc = price * (item.discount / 100);
                            return prev - discountCalc;
                          });
                          setVoucherList((prev) => {
                            return prev.splice(index, 1);
                          });
                        }}
                      >
                        {item.code}
                      </li>
                    ))}
                  </ul>
                  <p className='text-center mt-2 font-semibold'>
                    Click/Tap to remove voucher
                  </p>
                </div>
              )}
            </div>
            <div className='flex justify-between'>
              <h2 className='text-2xl font-bold'>Total:</h2>
              <span className='font-bold font-head text-accent text-3xl'>{`$ ${price == 0 ? 0 : toPrice(priceState)
                } USD`}</span>
            </div>
          </div>
          <div className='flex flex-col mb-8 h-36'>
            <div className='flex gap-2 bg-zinc-300 drop-shadow-xl rounded-xl h-full'>
              <img
                className='rounded-l-xl w-40'
                src={thumbnail}
                alt='ad Thumbnail'
              />
              <div className='py-2'>
                <h3 className='text-2xl font-semibold font-head text-zinc-700 tracking-wider'>
                  {item.brand}
                </h3>
                <h4 className='text-lg font-semibold font-head text-zinc-700 tracking-wide'>
                  {item.model}
                </h4>
                <h4 className='text-lg font-semibold font-head text-zinc-700 tracking-wide'>
                  {item.series}
                </h4>
                <h4 className='text-lg font-semibold font-head text-zinc-700 tracking-wide'>
                  {item.trans}
                </h4>
              </div>
            </div>
          </div>
          {priceState == 0 ? (
            <div className='w-full flex justify-center'>
              <button
                type='button'
                className='py-2 w-60 bg-success rounded-lg text-light text-2xl font-head tracking-wider'
                disabled={disableContinue}
                onClick={async () => {
                  setDisableContinue(true);
                  await CalculateVoucher(voucherList, uid, operation, submit);
                }}
              >
                Continue
              </button>
            </div>
          ) : (
            <PayPalScriptProvider options={initialOptions}>
              <PayPalButtons
                className='relative z-0'
                style={{ layout: "horizontal", shape: "pill" }}
                fundingSource='paypal'
                disabled={isdisabled}
                forceReRender={[discount]}
                createOrder={async () => {
                  const voucherRef = [];
                  if (voucherList.length > 0) {
                    voucherList.map((item) => {
                      voucherRef.push(item.refID);
                    });
                  }
                  return await CallableOrder(null, operation, voucherRef).then(
                    ({ data }) => {
                      order = {
                        operation: data.Operation,
                        discount: data.discountPrice,
                        originalPrice: data.OriginalPrice,
                      };
                      return data.id;
                    }
                  );
                }}
                onApprove={({ orderID }) => {
                  setDisabled(true);
                  setOnProcessing(true);
                  CallableCapture({ id: orderID, ...order })
                    .then(() => {
                      setOnComplete(true);
                      setTimeout(async () => {
                        await IncrementVoucher(uid, voucherList);
                        submit(orderID, price);
                      }, 3000);
                    })
                    .catch((e) => {
                      throw e;
                    });
                }}
                onError={(e) => {
                  setDisabled(false);
                  setOnProcessing(false);
                  setOnComplete(false);
                  Show("An Error Occured", false);
                }}
              />
              <PayPalButtons
                style={{ layout: "horizontal", shape: "pill" }}
                className='relative z-0'
                fundingSource='card'
                disabled={isdisabled}
                forceReRender={[discount]}
                createOrder={async () => {
                  const voucherRef = [];
                  if (voucherList.length > 0) {
                    voucherList.map((item) => {
                      voucherRef.push(item.refID);
                    });
                  }
                  return await CallableOrder(null, operation, voucherRef).then(
                    ({ data }) => {
                      order = {
                        operation: data.Operation,
                        discount: data.discountPrice,
                        originalPrice: data.OriginalPrice,
                      };
                      return data.id;
                    }
                  );
                }}
                onApprove={({ orderID }) => {
                  setDisabled(true);
                  setOnProcessing(true);
                  CallableCapture({ id: orderID, ...order })
                    .then(() => {
                      setOnComplete(true);
                      setTimeout(async () => {
                        await IncrementVoucher(uid, voucherList);
                        submit(orderID, price);
                      }, 3000);
                    })
                    .catch((e) => {
                      throw e;
                    });
                }}
                onError={() => {
                  setDisabled(false);
                  setOnProcessing(false);
                  setOnComplete(false);
                  Show("An Error Occured", false);
                }}
              />
            </PayPalScriptProvider>
          )}
          <table border='0' cellPadding='10' cellSpacing='0' align='center'>
            <tbody>
              <tr>
                <td align='center'></td>
              </tr>
              <tr>
                <td align='center'>
                  <a
                    title='Secured by PayPal'
                    onClick={() => {
                      window.open(
                        "https://www.paypal.com/c2/webapps/mpp/home?locale.x=en_C2",
                        "WIPaypal",
                        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700"
                      );
                      return false;
                    }}
                  >
                    <img
                      src='https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/logo-center-other-options-white-secured-pp.png'
                      border='0'
                      alt='Secured by PayPal'
                    />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='flex mt-8 px-2 justify-center'>
        <button
          className='bg-success text-center text-lightText font-bold rounded-full h-8 px-3 py-0 tracking-wider'
          type='button'
          disabled={isdisabled}
          onClick={() => {
            previous((prev) => prev - 1);
          }}
        >
          <Icon
            className='inline w-4 h-4 text-lightText mb-1'
            icon='material-symbols:arrow-back-ios-rounded'
          />
          Previous Step
        </button>
      </div>
    </div>
  );
}
export default PaymentForm;
