import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import Card from "../../Card/card";

export default function UploadForm({ data, media, isPremium, next, previous }) {
  const [values, setValues] = useState({ mainImage: 0 });
  const [files, setFileData] = useState([]);
  const [tempMainImg, setTempMainImg] = useState("");
  const [error, setError] = useState({ errorBool: false, errorMessage: "" });
  useEffect(() => {
    if (media.length > 0) {
      setFileData(media);
      handleMainFile(media[0], true);
    }
  }, []);
  const handleFile = (evt) => {
    try {
      setError({ errorBool: false, errorMessage: "" });
      if (evt.target.files.length == 0) {
        setFileData([]);
        throw new Error("Images Required");
      }
      for (let i = 0; i < evt.target.files.length; i++) {
        if (evt.target.files[i].size > 5000000) {
          throw new Error(`Image Execeeded exceeded the 5mb size limit`);
        }
      }
      const uploadFiles = [...evt.target.files];
      if (isPremium) {
        setFileData(uploadFiles.slice(0, 12));
        handleMainFile(evt.target.files[0], true);
        return;
      }
      setFileData(uploadFiles.slice(0, 8));
      handleMainFile(evt.target.files[0], true);
    } catch (error) {
      setError({ errorBool: true, errorMessage: error.message });
    }
  };
  const handleMainFile = (evt, file = false) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setTempMainImg(reader.result);
    });
    if (file) {
      reader.readAsDataURL(evt);
    } else {
      reader.readAsDataURL(files[evt.target.value]);
    }
  };

  const handlechange = (evt) => {
    setValues((prev) => ({ ...prev, [evt.target.name]: evt.target.value }));
  };
  const validateFiles = () => {
    if (files.length === 0) {
      setError({ errorBool: true, errorMessage: "Image Files Required!" });
      return false;
    }
    setError({ errorBool: false, errorMessage: "" });
    return true;
  };
  const uploadProcess = () => {
    if (validateFiles()) {
      if (values.mainImg == 0) {
        setValues((prev) => {
          return { ...prev, files: files };
        });
        next({ ...values, files: files });
      } else {
        let modified = files;
        let mainImg = modified.slice(+values.mainImage, +values.mainImage + 1);
        modified.splice(+values.mainImage, 1);
        modified.splice(0, 0, mainImg[0]);
        setValues((prev) => {
          return { ...prev, files: modified };
        });
        next({ ...values, files: files });
      }
    } else {
      return;
    }
  };
  return (
    <div className='flex flex-col items-center px-4 lg:px-10 h-full bg-light'>
      <h1 className='text-3xl font-bold font-head text-textDark tracking-wider mb-8 pt-2'>
        Upload Photos
      </h1>
      <div className='flex flex-col justify-items-center gap-3 flex-wrap items-center self-center text-secondary'>
        <div
          className={
            error.errorBool
              ? "w-4/5 grid grid-cols-1 md:grid-cols-2 border-danger border-2 text-danger py-4 pl-8 pr-2 relative"
              : "w-4/5 grid grid-cols-1 md:grid-cols-2 border-secondary border-2 py-4 pl-8 pr-2 text-secondary relative"
          }
        >
          <div>
            <div
              className={
                error.errorBool
                  ? "w-12 h-12 border-danger border-2 bg-light pl-2 absolute z-10 -left-6 top-9"
                  : "w-12 h-12 border-secondary border-2 bg-light pl-2 absolute z-10 -left-6 top-9"
              }
            >
              {error.errorBool ? (
                <Icon
                  className='w-full h-full font-bold text-danger relative right-1'
                  icon='ic:round-close'
                />
              ) : (
                <Icon
                  className='w-full h-full font-bold text-secondary'
                  icon='clarity:form-line'
                />
              )}
            </div>
            <h2 className='text-xl font-bold font-head tracking-wide'>
              Upload Photos
            </h2>
            <ul className='text-base list-disc ml-4 font-semibold mb-4'>
              <li>
                For the best results Photos should be taken in
                <span className='underline underline-offset-4 decoration-4 decoration-success ml-1'>
                  landscape mode.
                </span>
              </li>
              <li>{isPremium ? "12" : "8"} Images Max</li>
              <li>Images should not exceed 5MB.</li>
              <li>
                Accepted Format:
                <ul className='list-disc ml-4'>
                  <li>.jpg</li>
                  <li>.jpeg</li>
                  <li>.png</li>
                </ul>
              </li>
            </ul>
            {error.errorBool ? (
              <p className='text-danger center font-bold tracking-wider'>
                {error.errorMessage}
              </p>
            ) : (
              ""
            )}

            <input
              className='w-4/5'
              type='file'
              name='images'
              onChange={handleFile}
              accept='.jpg, .jpeg, .png'
              multiple
            ></input>
          </div>
          {files.length != 0 ? (
            <div>
              <label className='mt-2 mb-4 font-semibold text-center block flex flex-wrap justify-center'>
                <span className='text-lg'>Select Thumbnail Image.</span>
                <select
                  className='w-full sm:w-4/5 py-2 bg-zinc-300 text-zinc-500 rounded-xl text-center font-semibold tracking-widest'
                  name='mainImage'
                  onChange={(evt) => {
                    handleMainFile(evt);
                    handlechange(evt);
                  }}
                >
                  {files.map((item, index) => (
                    <option value={index} key={item.lastModified + index}>
                      {`Image ${index + 1}`}
                    </option>
                  ))}
                </select>
              </label>
              <div className='w-full flex justify-center'>
                <div className='sm:w-4/6'>
                  <Card
                    data={{
                      ...data,
                      images: files,
                      tempImage: tempMainImg,
                      new: true,
                      premium: isPremium,
                      soldStatus: false,
                      processing: false,
                    }}
                    options={{
                      temp: true,
                      disableWatch: true,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className='flex mt-8 px-2 md:px-40 xl:px-60 2xl:px-96 justify-between gap-8 xl:gap-32'>
        <button
          className='bg-success text-center text-white font-bold rounded-full h-8 px-3 py-0 tracking-wider'
          type='button'
          onClick={() => {
            previous((prev) => prev - 1);
          }}
        >
          <Icon
            className='inline w-4 h-4 text-tlightcolor mb-1'
            icon='material-symbols:arrow-back-ios-rounded'
          />
          Previous Step
        </button>
        <button
          className='bg-success text-center text-white font-bold rounded-full h-8 px-3 py-0 tracking-wider'
          type='submit'
          onClick={uploadProcess}
        >
          Next Step
          <Icon
            className='inline w-4 h-4 text-tlightcolor mb-1'
            icon='material-symbols:arrow-forward-ios-rounded'
          />
        </button>
      </div>
    </div>
  );
}
