import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
function HomeHero({ toggle, toggleValue }) {
  const navigate = useNavigate();
  const [searchField, setSearchField] = useState("");
  return (
    <div className='relative flex flex-col justify-center w-full bg-[url("https://firebasestorage.googleapis.com/v0/b/showroom478.appspot.com/o/Assets%2FHero%2Fbigred.webp?alt=media&token=daa4e475-2f12-4317-ac9b-eab303b97b9a")] md:bg-[url("https://firebasestorage.googleapis.com/v0/b/showroom478.appspot.com/o/Assets%2FHero%2Fmdred.webp?alt=media&token=f11a2a0a-1252-4dea-8823-9fbbaacc65d7")] bg-[length:60%_100%] sm:bg-[length:50%_100%] md:bg-[length:70%_100%] xl:bg-[length:62%_100%] overflow-hidden bg-right bg-no-repeat bg-right h-[360px] sm:h-[25rem] lg:h-[32rem] 2xl:h-[36rem]'>
      <div className='relative w-full grid grid-cols-2 md:grid-cols-5 pt-10 mb-12'>
        <div className='bg-[#1F0A1C] absolute w-3/4 h-[800px] -top-32 lg:-top-64 -left-16 sm:-left-28 md:-left-56 xl:-left-[32rem] rotate-[8deg] opacity-60 md:z-10' />
        <div className='bg-[#120610] absolute w-3/4 h-[800px] -top-32 lg:-top-64 -left-28 sm:-left-40 md:-left-72 xl:-left-[40rem] rotate-[8deg] md:z-10' />
        <div className='pt-8 self-center relative md:w-full pl-2 sm:pl-8 lg:pl-28 xl:pl-80 z-20 md:col-span-2'>
          <h1 className='w-full uppercase text-xl sm:text-2xl 2xl:text-3xl text-light font-bold font-head tracking-widest'>
            Buy and Sell Vehicles With Confidence!
          </h1>
          <Link to='pricing'>
            <button
              className='mt-8 text-base px-6 py-1 rounded-xl bg-gradient-to-r from-primary to-accent text-light align-middle text-center font-bold tracking-wider'
              type='button'
            >
              Get Started
            </button>
          </Link>
        </div>
      </div>
      <div className='flex flex-col w-full relative z-20 mb-8'>
        {toggleValue ? (
          <div className='h-8 w-3/5 opacity-0'></div>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (searchField == "") {
                navigate("search");
                return;
              }
              navigate(`search?q=${searchField.toLowerCase()}`);
            }}
            className='flex w-5/6 sm:w-3/6 md:w-5/12 lg:w-3/12 self-center'
          >
            <input
              className='placeholder:font-semibold pl-6 shadow-xl rounded-tl-full rounded-bl-full w-full h-8 md:text-lg placeholder:text-zinc-600 sm:placeholder:text-lg placeholder:text-start lg:placeholder:font-semibold align-middle'
              type='text'
              placeholder='Search'
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
            />
            <button type='submit' aria-label='search'>
              <Icon
                className='shadow-xl text-3xl font-bold h-8 w-12 sm:w-16 md:w-20 rounded-tr-full rounded-br-full text-zinc-600 bg-white'
                icon='radix-icons:magnifying-glass'
              />
            </button>
          </form>
        )}
        <button
          className='bg-primary sm:tracking-wide px-6 flex justify-center self-center text-lightText rounded-full h-6 flex items-center mt-4 py-4 font-semibold'
          type='button'
          onClick={toggle}
        >
          Advanced Search
          {toggleValue ? (
            <Icon
              className='text-3xl lg:text-5xl font-bold'
              icon='ic:round-arrow-drop-up'
            />
          ) : (
            <Icon
              className='text-3xl lg:text-5xl font-bold'
              icon='ic:round-arrow-drop-down'
            />
          )}
        </button>
      </div>
    </div>
  );
}

export default HomeHero;
