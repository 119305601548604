import { object, string, number, array } from "yup";
import phoneUtil from "google-libphonenumber";
const phoneParser = phoneUtil.PhoneNumberUtil.getInstance();

const vehicleSchema = object({
  location: string().required("Location Is Required"),
  type: string().required("Type Is Required"),
  brand: string()
    .lowercase()
    .trim()
    .min(1, "Too Short!")
    .max(20, "Too Long!")
    .required("Brand Is Required"),
  model: string()
    .lowercase()
    .trim()
    .min(1, "Too Short!")
    .max(20, "Too Long!")
    .required("Model Is Required"),
  year: number().min(4).notRequired(),
  color: string().notRequired(),
  condition: string().required("Condition is Required"),
  series: string().uppercase().trim().required("Series Is Required"),
  mileage: number().min(0, "Invalid Mileage").notRequired(),
  trans: string().required("Please Select a Transmission Type"),
  seatingCapacity: number().min(0, "Invalid Seats").notRequired(),
  fuel: string().notRequired(),
  engineSize: number().min(0, "Invalid Size").notRequired(),
  tempfeature: string()
    .test("feature", "Invalid (Min Length 2)", (value = "") => {
      const exp = new RegExp(/^(|\s+)$/);
      if (value.length <= 2 || exp.test(value)) {
        if (value == "") {
          return true;
        }
        return false;
      }
      return true;
    })
    .test("unique", "Feature Already Exist", (value, context) => {
      if (context.parent.features.length >= 1) {
        let isDuplicate = context.parent.features.filter(
          (item) => item == value
        );
        if (isDuplicate.length != 0) {
          return false;
        }
        return true;
      }
      return true;
    })
    .trim(),
  addInfo: string().test("addInfo", "Invalid (Min Length 2)", (value = "") => {
    const exp = new RegExp(/^(|\s+)$/);
    if (value.length <= 2 || exp.test(value)) {
      if (value == "") {
        return true;
      }
      return false;
    }
    return true;
  }),
  price: number().min(0, "Invalid Price").notRequired(),
});

const contactSchema = object({
  contactName: string().max(30).required("Contact Name Required"),
  contactNumbers: array()
    .min(1, "Contact Number is Required")
    .max(3, "Number Max 3")
    .required("Contact Number is Required"),
  prefferedContact: string(),
});
const tempSchema = object().test("tempNumber", "Invalid Phone Number", (value) => {
  return phoneParser.isValidNumber(phoneParser.parseAndKeepRawInput(`${value.number}`, value.meta.iso2));
}).notRequired();
export { vehicleSchema, contactSchema, tempSchema };
