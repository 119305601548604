import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isAdmin } from "../../api/fireauth";

function MobileSideBar({ user, toggle }) {
  const [admin, setAdmin] = useState(false);
  useEffect(() => {
    if (user) {
      const getAdmin = async () => {
        let adminBool = await isAdmin();
        setAdmin(adminBool);
      };
      getAdmin();
    }
  }, [user]);
  return (
    <div className='w-full bg-light h-screen absolute top-0 left-0 z-30 pt-24 overflow-hidden'>
      <div className='absolute -z-10 w-full h-full'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 427.49 638.36'>
          <g id='Layer_2' data-name='Layer 2'>
            <g id='Layer_1-2' data-name='Layer 1'>
              <g opacity='0.25'>
                <path
                  d='M370.13,280.45,279,122.65c.14.25,24.41-57.17,24.41-57.17l92.28,159.85C395.91,225.65,370.13,280.45,370.13,280.45Z'
                  fill='#56eef4'
                />
              </g>
              <g opacity='0.25'>
                <path
                  d='M401.9,215C371.53,162.37,310.64,56.9,310.8,57.17S335.21,0,335.21,0l92.28,159.84C427.68,160.17,401.9,215,401.9,215Z'
                  fill='#56eef4'
                />
              </g>
              <g opacity='0.25'>
                <path
                  d='M28.83,638.36,0,458.45c.05.29,43.19-44.7,43.19-44.7L72.4,596C72.46,596.36,28.83,638.36,28.83,638.36Z'
                  fill='#56eef4'
                />
              </g>
              <g opacity='0.25'>
                <path
                  d='M311.36,548.54l-91.1-157.8c.14.25,24.41-57.17,24.41-57.17L337,493.41C337.14,493.74,311.36,548.54,311.36,548.54Z'
                  fill='#56eef4'
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className='flex flex-col items-start gap-12 pl-16 opacity-0 animate-menuFadeIn'>
        <Link
          to='/'
          className='flex gap-4 font-bold tracking-wider text-2xl'
          onClick={toggle}
        >
          <div className='bg-primary w-2 h4' />
          <h2 className='font-head font-bold'>Home</h2>
        </Link>
        <Link
          to='/pricing'
          className='flex gap-4 font-bold tracking-wider text-2xl'
          onClick={toggle}
          state={{ msg: "Choose AD Tier", status: true }}
        >
          <div className='bg-primary w-2 h4' />
          <h2 className='font-head font-bold'>Post AD</h2>
        </Link>
        <Link
          to='/pricing#faq'
          className='flex gap-4 font-bold tracking-wider text-2xl'
          onClick={toggle}
        >
          <div className='bg-primary w-2 h4' />
          <h2 className='font-head font-bold'>FAQ</h2>
        </Link>
        {!user ? (
          <Link
            to='/login'
            className='flex gap-4 font-bold tracking-wider text-2xl'
            onClick={toggle}
          >
            <div className='bg-primary w-2 h4' />
            <h2 className='font-head font-bold'>Login/SignUp</h2>
          </Link>
        ) : (
          ""
        )}
        <Link
          to='about'
          className='flex gap-4 font-bold tracking-wider text-2xl'
          onClick={toggle}
        >
          <div className='bg-primary w-2 h4' />
          <h2 className='font-head font-bold'>About Us</h2>
        </Link>
        {admin ? (
          <Link
            to='https://firecms49--preview-v63laabf.web.app/'
            className='flex gap-4 font-bold tracking-wider text-2xl'
            onClick={toggle}
          >
            <div className='bg-danger w-2 h4' />
            <h2 className='font-head font-bold'>Admin Panel</h2>
          </Link>
        ) : (
          ""
        )}
      </div>
      <div className='fixed bottom-0 flex justify-center w-full'>
        <div className='w-full text-center'>
          <span>&copy; 2024 ShowRoomTT</span>
        </div>
      </div>
    </div>
  );
}
export default MobileSideBar;
