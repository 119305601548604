import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { GetSingleDocument } from "../../api/firestore";
import { signInWithProvider } from "../../api/fireauth";
import useNoti from "../../context/notificationhook";
import { useNavigate } from "react-router-dom";

const GoogleAuthButton = ({ signIn = false }) => {
  const { Show } = useNoti();
  const navigate = useNavigate();
  const Authenticate = async (credentialResponse) => {
    try {
      const user = await signInWithProvider(credentialResponse);
      GetSingleDocument("Users", null, {
        key: "email",
        operator: "==",
        value: user.email,
      }).then((data) => {
        if (data) {
          navigate("/", {
            state: { msg: `Welcome Back ${data.contactName}!`, success: true },
          });
        } else {
          navigate("/auth", {
            state: {
              newUser: true,
              name: user.displayName,
              uid: user.uid,
              email: user.email,
            },
          });
        }
      });
    } catch (e) {
      Show("An Error Occured", false);
      throw e;
    }
  };
  return (
    <GoogleOAuthProvider clientId='86961942918-vl6tts1pqdle4h8t0ottunp3vcanm0v3.apps.googleusercontent.com'>
      <GoogleLogin
        onSuccess={Authenticate}
        onError={(e) => {
          Show("An Error Occured", false);
          throw e;
        }}
        useOneTap={signIn}
        shape='pill'
        text={signIn ? "signin_with" : "signup_with"}
        context={signIn ? "signin" : "signup"}
        auto_select={signIn}
        ux_mode='popup'
      />
    </GoogleOAuthProvider>
  );
};
export default GoogleAuthButton;
