import Dashboard from "../components/Dashboard";

function DashboardPage() {
  return (
    <div className='mx-1 md:mx-12 lg:mx-28 xl:mx-36'>
      <Dashboard />
    </div>
  );
}

export default DashboardPage;
