import loader from "../../assets/loader/authloader.svg";

export default function AuthLoader({ operation }) {
  return (
    <div>
      <img
        className='mx-auto w-32 h-full animate-[spin_2s_linear_infinite]'
        src={loader}
        alt='auth loader'
      />
      <h2 className='text-2xl font-bold tracking-wider'>{operation}</h2>
    </div>
  );
}
