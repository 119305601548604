import {
  useNavigate,
  useRouteError,
  isRouteErrorResponse,
} from "react-router-dom";
import { useState } from "react";
import ErrorSvg from "../../assets/Error.svg";

function Error() {
  const navigate = useNavigate();
  const routeError = useRouteError();
  const [isRouteError] = useState(isRouteErrorResponse(routeError));
  console.log(routeError);
  return (
    <div
      className='w-full h-screen flex flex-col justify-center relative bg-[length:100%_60%] bg-no-repeat bg-[center_bottom_-4rem] bg-light'
      style={{ backgroundImage: `url(${ErrorSvg})` }}
    >
      <div className='text-center relative bottom-28 sm:mx-24'>
        <h1 className='text-8xl font-bold font-head mb-6'>
          {isRouteError ? routeError.status : "oops"}
        </h1>
        <h2 className='text-3xl font-bold font-head mb-8'>
          {isRouteError ? routeError.message : "Something went wrong"}
        </h2>
        <h3 className='text-lg font-bold font-body'>
          {isRouteError
            ? "You've reached a dead end. But don't let that stop you from finding the perfect car. Head to our homepage and start your search today."
            : "We're sorry that you've encountered an error while using our app. Please know that our team is working diligently to identify and fix the problem. "}
        </h3>
        <button
          onClick={() => {
            navigate("/");
          }}
          className='mt-4 px-3 h-10 bg-primary text-lightText font-bold tracking-wide rounded-lg'
        >
          Go to HomePage
        </button>
      </div>
    </div>
  );
}

export default Error;
