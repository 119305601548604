import "../CardPlaceHolder/placeStyle.css";

function ProductPlaceHolder() {
  return (
    <div className='w-full flex flex-col px-4'>
      <div className='ph-item'>
        <div className='ph-col-12'>
          <div className='ph-picture h-64'></div>
        </div>
      </div>
      <div className='ph-item w-1/2 self-center'>
        <div className='ph-col-12'>
          <div className='ph-picture h-12'></div>
        </div>
      </div>
      <div className='ph-item flex justify-center border-none'>
        <div className='ph-col-2'>
          <div className='ph-picture h-12'></div>
        </div>
        <div className='ph-col-2'></div>
        <div className='ph-col-2'>
          <div className='ph-picture h-12'></div>
        </div>
        <div className='ph-col-2'></div>
        <div className='ph-col-2'>
          <div className='ph-picture h-12'></div>
        </div>
      </div>
      <div className='ph-item flex justify-center border-none'>
        <div className='ph-col-2'>
          <div className='ph-picture h-12'></div>
        </div>
        <div className='ph-col-2'></div>
        <div className='ph-col-2'>
          <div className='ph-picture h-12'></div>
        </div>
        <div className='ph-col-2'></div>
        <div className='ph-col-2'>
          <div className='ph-picture h-12'></div>
        </div>
      </div>
      <div className='ph-item w-1/2 self-center'>
        <div className='ph-col-12'>
          <div className='ph-picture h-6'></div>
        </div>
      </div>
      <div className='ph-item border-none flex justify-center'>
        <div className='ph-col-4'>
          <div className='ph-picture h-4'></div>
        </div>
        <div className='ph-col-2'></div>
        <div className='ph-col-4'>
          <div className='ph-picture h-4'></div>
        </div>
      </div>
      <div className='ph-item border-none flex justify-center'>
        <div className='ph-col-4'>
          <div className='ph-picture h-4'></div>
        </div>
        <div className='ph-col-2'></div>
        <div className='ph-col-4'>
          <div className='ph-picture h-4'></div>
        </div>
      </div>
      <div className='ph-item border-none flex justify-center'>
        <div className='ph-col-4'>
          <div className='ph-picture h-4'></div>
        </div>
        <div className='ph-col-2'></div>
        <div className='ph-col-4'>
          <div className='ph-picture h-4'></div>
        </div>
      </div>
      <div className='ph-item border-none flex justify-center'>
        <div className='ph-col-4'>
          <div className='ph-picture h-4'></div>
        </div>
        <div className='ph-col-2'></div>
        <div className='ph-col-4'>
          <div className='ph-picture h-4'></div>
        </div>
      </div>
    </div>
  );
}
export default ProductPlaceHolder;
