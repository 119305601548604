import { useEffect, useState } from "react";
import noWatch from "../../assets/icons/nowatch.svg";
import CardPlaceHolder from "../CardPlaceHolder";
import InfiniteScroll from "react-infinite-scroll-component";
import Card from "../Card";
import { onDocumentChange } from "../../api/firestore";

function WatchList({ user, watchList, setWatchList }) {
  const [hasMore, setHasMore] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [load, setLoad] = useState(true);

  const getNextPage = (fragments = null) => {
    const nextPage = currentPage + 1;
    const begin = 8 * (nextPage - 1);
    if (fragments) {
      const end = begin + fragments;
      return watchList.slice(begin, end);
    } else {
      const end = 8 * nextPage;
      setCurrentPage((prev) => prev++);
      return watchList.slice(begin, end);
    }
  };
  const snapFunc = async (query) => {
    const Temp = [];
    query.forEach((doc) => {
      Temp.push(doc.data());
    });
    if (Temp.length != 0) {
      let pages = Math.floor(Temp.length / 8);
      // let fragment = Temp.length % 8 == 0 ? true : false;
      if (pages == 0) {
        setWatchList(Temp);
        setHasMore(false);
        setLoad(false);
      } else {
        let exactPage = pages + 1;
        if (exactPage == currentPage) {
          setWatchList(Temp);
          setHasMore(false);
          setLoad(false);
        } else {
          setWatchList(Temp.slice(0, 8));
          setHasMore(true);
          setLoad(false);
        }
      }
    } else {
      setWatchList([]);
      setHasMore(false);
      setLoad(false);
    }
  };
  useEffect(() => {
    const unsubscribe = onDocumentChange(user.uid, snapFunc);
    return () => {
      unsubscribe();
    };
  }, [user]);
  return (
    <div className='w-full pb-2 bg-secondary-tint mt-8 px-2'>
      <div className='my-2'>
        <div className='flex flex-row justify-start border-b-2 border-zinc-400'>
          <h2 className='font-head text-2xl font-semibold tracking-widerl pb-4'>
            WatchList
          </h2>
        </div>
        <h4 className='text-zinc-500 mt-2 font-bold text-sm'>
          {watchList?.length} results found
        </h4>
      </div>
      {load ? (
        <CardPlaceHolder count={4} />
      ) : watchList?.length == 0 ? (
        <div className='w-full flex justify-center mt-12 flex-wrap'>
          <img
            className='w-2/5 md:w-2/12 h-full'
            src={noWatch}
            alt='No Results found'
          />
          <div className='w-full text-center mt-4'>
            <h2 className='text-2xl text-darkText font-semibold tracking-wide'>
              No Ads Found
            </h2>
          </div>
        </div>
      ) : (
        <InfiniteScroll
          className='grid grid-flow-row grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-1 sm:gap-2 place-items-center'
          dataLength={watchList?.length}
          next={async () => {
            const pages = Math.floor(watchList.length / 8);
            const fragment = watchList.length % 8;
            if (pages == currentPage) {
              setWatchList((prev) => [...prev, ...getNextPage(fragment)]);
              setHasMore(false);
            } else {
              setWatchList((prev) => [...prev, ...getNextPage()]);
              setHasMore(true);
            }
          }}
          hasMore={hasMore}
          loader={<CardPlaceHolder count={4} />}
          endMessage={
            <div className='absolute -bottom-4 w-full'>
              <p className='text-center'>
                <b>Viewed All {watchList.length} Results</b>
              </p>
            </div>
          }
        >
          {watchList.map((item) => {
            return <Card key={item.objectID} data={item} />;
          })}
        </InfiniteScroll>
      )}
    </div>
  );
}

export default WatchList;
