import Product from "../components/Product";

function ProductPage() {
  return (
    <div>
      <Product />
    </div>
  );
}

export default ProductPage;
