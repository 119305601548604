import { Helmet } from "react-helmet-async";

function HelmetTags({
  pageTitle,
  description,
  link,
  isProduct = false,
  thumbnail = "",
}) {
  if (isProduct) {
    return (
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='description' content={description} />
        <link rel='canonical' href={link} />
        <meta property='og:site_name' content='ShowRoomTT' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={link} />
        <meta property='og:title' content={pageTitle} />
        <meta property='og:description' content={description} />
        <meta property='og:image:width' content='260' />
        <meta property='og:image:height' content='300' />
        <meta property='og:image:type' content='image/webp' />
        <meta property='og:image' content={thumbnail} />
        <meta property='og:image:alt' content={`Picture of ${description}`} />

        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:title' content={pageTitle} />
        <meta property='twitter:image' content={thumbnail} />
        <meta property='twitter:description' content={description} />
      </Helmet>
    );
  } else {
    return (
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='description' content={description} />
        <link rel='canonical' href={link} />
      </Helmet>
    );
  }
}
export default HelmetTags;
