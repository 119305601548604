import { useEffect, useState } from "react";
import { GetDocuments } from "../../api/firestore";
import { signOutUser } from "../../api/fireauth";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Icon } from "@iconify/react";
import EditCard from "../EditCard";
import WatchList from "../WatchList/watchList";
import AccountSettings from "../AccountProfile";
function Dashboard() {
  const [active, setActive] = useState(0);
  const [watchList, setWatchList] = useState([]);
  const [userPost, setUserPost] = useState(null);
  const { user } = useOutletContext();
  const navigate = useNavigate();
  const SignOut = () => {
    navigate("/", { state: { msg: "Sign Out Successful", success: true } });
  };
  useEffect(() => {
    if (user) {
      GetDocuments(12, { key: "userId", operator: "==", value: user.uid }).then(
        (data) => {
          setUserPost(data.documents);
          if (data.documents.length == 0 || null) {
            setActive(1);
          }
        }
      );
    }
  }, [user]);
  const toggleActive = (tabIndex) => {
    setActive(tabIndex);
  };
  return (
    <div className='pt-12 relative mx-1 md:mx-12 lg:mx-0 xl:mx-72'>
      <ul className='flex flex-row justify-around text-lg font-medium text-zinc-600 h-16 text-center mt-8'>
        {userPost?.length == 0 || userPost == null ? (
          ""
        ) : (
          <li
            onClick={() => {
              toggleActive(0);
            }}
            className={
              active == 0
                ? "text-center text-accent font-semibold h-full"
                : "h-full text-zinc-400"
            }
          >
            <Icon
              className='text-4xl font-bold inline'
              icon='mdi:post-outline'
            />
            <h2 className={active == 0 ? "border-b-4 border-accent" : ""}>
              Posts/Ads
            </h2>
          </li>
        )}
        <li
          onClick={() => {
            toggleActive(1);
          }}
          className={
            active == 1
              ? "text-center text-accent font-semibold h-full"
              : "h-full text-zinc-400"
          }
        >
          <Icon className='text-4xl font-bold inline' icon='mdi:eye-check' />
          <h2 className={active == 1 ? "border-b-4 border-accent" : ""}>
            WatchList
          </h2>
        </li>
        <li
          onClick={() => {
            toggleActive(2);
          }}
          className={
            active == 2
              ? "text-center text-accent font-semibold h-full"
              : "h-full text-zinc-400"
          }
        >
          <Icon className='text-4xl font-bold inline' icon='mdi:account-cog' />
          <h2 className={active == 2 ? "border-b-4 border-accent" : ""}>
            Settings
          </h2>
        </li>
        <li
          className='font-semibold text-darkText'
          onClick={() => {
            signOutUser(SignOut);
          }}
        >
          <Icon className='text-4xl font-bold inline' icon='mdi:logout' />
          <h2>Log Out</h2>
        </li>
      </ul>
      <div>
        {active == 0 ? (
          userPost?.length == 0 || userPost == null ? (
            ""
          ) : (
            <div className='mx-2 mt-8'>
              <div className='my-2'>
                <div className='flex flex-row justify-start border-b-2 border-zinc-400'>
                  <h3 className='font-head text-2xl font-semibold tracking-wider pb-4'>
                    Posted Ads
                  </h3>
                </div>
                <h4 className='text-zinc-500 mt-2 font-bold text-sm'>
                  {userPost?.length} results found
                </h4>
              </div>
              <div className='grid grid-flow-row grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 sm:gap-3 justify-items-center'>
                {userPost?.map((item) => (
                  <EditCard key={item.objectID} data={item} />
                ))}
              </div>
            </div>
          )
        ) : active == 1 ? (
          <WatchList
            user={user}
            watchList={watchList}
            setWatchList={setWatchList}
          />
        ) : (
          <AccountSettings user={user} />
        )}
      </div>
    </div>
  );
}
export default Dashboard;
