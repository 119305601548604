import About from "../components/About";
import HelmetTags from "../components/Helmet/helmet";

function AboutPage() {
  return (
    <div>
      <HelmetTags
        pageTitle='About Us - ShowRoomTT'
        description='The premier destination for hassle-free car buying and selling in Trinidad and Tobago!'
        link='www.showroomtt.com/about'
      />
      <About />
    </div>
  );
}

export default AboutPage;
