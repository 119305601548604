import { functions, httpsCallable } from "./functions";
import * as Sentry from "@sentry/react";

const CreateOrder = httpsCallable(functions, "CreateOrder");
const CaptureOrder = httpsCallable(functions, "CaptureOrder");
const SendReceipt = httpsCallable(functions, "SendReceipt");

function CallableOrder(postID, operation, voucherRefs) {
  try {
    return CreateOrder({ postID, operation, voucherRefs });
  } catch (e) {
    Sentry.captureException(e);
  }
}
async function CallableCapture(order) {
  try {
    const res = await CaptureOrder(order);
    await SendReceipt(res.data.Receipt);
    return;
  } catch (e) {
    Sentry.captureException(e);
  }
}

export { CallableOrder, CallableCapture };
