import { useState, createContext } from "react";

const NotificationContext = createContext();

function NotificationProvider({ children }) {
  const [active, setActive] = useState(false);
  const [text, setText] = useState("");
  const [notiType, setNotiType] = useState(false);
  const Show = (msg, type, delay = 3000) => {
    setText(msg);
    setNotiType(type);
    setActive(true);
    let delaySwitch = setTimeout(() => {
      setActive(false);
    }, delay);
    return () => {
      clearTimeout(delaySwitch);
    };
  };
  return (
    <NotificationContext.Provider value={{ Show, text, notiType, active }}>
      {children}
    </NotificationContext.Provider>
  );
}
export { NotificationContext, NotificationProvider };
