import { Link, useLocation, useNavigationType } from "react-router-dom";
import useNoti from "../../context/notificationhook";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { GetSingleDocument } from "../../api/firestore";

function PricingSelection() {
  const { Show } = useNoti();
  const [fAQ, setFAQ] = useState({
    first: false,
    second: false,
    third: false,
    forth: false,
    fifth: false,
  });
  const [currency, setCurrency] = useState(false);
  const { state } = useLocation();
  const naviType = useNavigationType();
  const [price, setPrice] = useState(null);
  const getPricing = async () => {
    await GetSingleDocument("AdPricing", "allPricing").then((data) => {
      setPrice(data);
    });
  };
  useEffect(() => {
    getPricing();
    if (state && naviType !== "POP") {
      setTimeout(() => {
        Show(state.msg, state.status, 5000);
      }, 2000);
    }
  }, []);
  return (
    <div className='relative w-full h-full bg-light pb-12'>
      <div className='w-full pt-8 h-[68vh]'>
        <div className='mt-16 lg:mt-20 relative pb-12 mx-1 md:mx-12 lg:mx-28 xl:mx-36'>
          <div className='flex flex-col items-center'>
            <h2 className='text-darkText text-3xl font-bold tracking-wide font-head'>
              <span className='relative'>
                <span className='relative z-10'>Ready</span>
                <div className='absolute w-full h-3 bg-accent bottom-1 left-0'></div>
              </span>{" "}
              to get started?
            </h2>
            <p className='text-darkText text-sm font-bold tracking-wide mt-2'>
              Choose a plan tailored to your needs
            </p>
            <div className='flex gap-2 mt-6'>
              <span className='text-base font-head font-semibold text-textDark'>
                USD
              </span>
              <label className='relative inline-flex items-center cursor-pointer'>
                <input
                  type='checkbox'
                  name='Currency'
                  component='input'
                  className='sr-only peer'
                  onChange={() => {
                    setCurrency((prev) => !prev);
                  }}
                />
                <div className="w-11 h-6 bg-zinc-400 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-accent/80 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-accent"></div>
              </label>
              <span className='text-base font-head font-semibold text-textDark'>
                TTD
              </span>
            </div>
          </div>
          <section className='flex flex-wrap justify-center mt-8 gap-4'>
            <div className='w-72 h-[29rem] border-zinc-300 border-2 relative'>
              <div className='bg-light absolute z-10 w-full h-full' />
              <div className='relative z-20'>
                <div className='flex flex-col items-center border-zinc-300 border-b-2 py-4'>
                  <h3 className='text-darkText text-2xl mb-2 font-bold font-head tracking-wider'>
                    Basic
                  </h3>
                  <p className='text-darkText font-semibold tracking-wider'>
                    A great value
                  </p>
                  {currency ? (
                    <p className='text-3xl text-darkText font-bold font-head tracking-widest mt-4'>
                      <span className="line-through">
                        {price ? `$${price?.basicAd.priceTTD}` : "--"}
                      </span>

                      <sub className='ml-3 font-lato font-semibold text-sm tracking-wide'>
                        TTD (per ad)
                      </sub>
                    </p>
                  ) : (
                    <p className='text-3xl text-darkText font-bold font-head tracking-widest mt-4'>
                      <span className="line-through">
                        {price ? `$${price?.basicAd.priceUSD}` : "--"}
                      </span>
                      <sub className='ml-3 font-lato font-semibold text-sm tracking-wide'>
                        USD (per ad)
                      </sub>
                    </p>
                  )}
                  <div className="mt-2">
                    <p className="text-center"><span className="text-red-500 font-black text-xl">100%</span> Free With Voucher Code: <span className="block text-lg font-bold text-green-600">ShowRoomTT</span></p>
                  </div>

                  <Link
                    to='/form'
                    state={{ premium: false, price: price?.basicAd.priceUSD }}
                  >
                    <button
                      className='bg-primary w-full text-center text-lightText font-bold rounded-full h-8 px-6 py-0 mt-2 tracking-wider'
                      type='button'
                    >
                      Claim Now
                    </button>
                  </Link>
                </div>
                <div className='py-2 px-4'>
                  <h4 className='font-extrabold text-base font-head'>
                    What's Included?
                  </h4>
                  <ul className='mt-4 text-textDark tracking-wide'>
                    <li className='my-2 flex gap-2'>
                      <Icon
                        className='text-primary text-2xl'
                        icon='tabler:check'
                      />
                      Upload up to 8 images
                    </li>
                    <li className='my-2 flex gap-2'>
                      <Icon
                        className='text-primary text-2xl'
                        icon='tabler:check'
                      />
                      Ad remains active until sold
                    </li>
                    <li className='my-2 flex gap-2'>
                      <Icon
                        className='text-primary text-2xl'
                        icon='tabler:check'
                      />
                      AI-powered number plate blurring
                    </li>
                    <li className='my-2 flex gap-2'>
                      <Icon
                        className='text-primary text-2xl'
                        icon='tabler:check'
                      />
                      Instant Ad Placement
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='w-72 h-[27rem] border-premium border-2 border-b-4 border-l-4 relative'>
              <div className='bg-light absolute z-10 w-full h-full' />
              <div className='relative z-20'>
                <div className='flex flex-col items-center border-zinc-300 border-b-2 py-4'>
                  <div className="w-full flex justify-around items-center mb-2">
                    <Icon
                      className='h-12 w-12 font-bold text-premium self-center'
                      icon='mdi:crown'
                    />
                    <h3 className='text-darkText text-2xl font-bold font-head tracking-wider'>
                      Premium
                    </h3>
                    <Icon
                      className='h-12 w-12 font-bold text-premium self-center'
                      icon='mdi:crown'
                    />
                  </div>
                  <p className='text-darkText font-semibold tracking-wider'>
                    Premium status for top results
                  </p>
                  {currency ? (
                    <p className='text-3xl text-darkText font-bold font-head tracking-widest mt-4'>
                      {price ? `$${price?.premiumAd.priceTTD}` : "--"}
                      <sub className='ml-3 font-lato font-semibold text-sm tracking-wide'>
                        TTD (per ad)
                      </sub>
                    </p>
                  ) : (
                    <p className='text-3xl text-darkText font-bold font-head tracking-widest mt-4'>
                      {price ? `$${price?.premiumAd.priceUSD}` : "--"}
                      <sub className='ml-3 font-lato font-semibold text-sm tracking-wide'>
                        USD (per ad)
                      </sub>
                    </p>
                  )}
                  <Link
                    to='/form'
                    state={{ premium: true, price: price?.premiumAd.priceUSD }}
                  >
                    <button
                      className='bg-primary w-full text-center text-lightText font-bold rounded-full h-8 px-6 py-0 mt-4 tracking-wider'
                      type='button'
                    >
                      Get Started
                    </button>
                  </Link>
                </div>
                <div className='py-2 px-4'>
                  <h4 className='font-extrabold text-base font-head'>
                    What's Included?
                  </h4>
                  <ul className='mt-4 text-textDark tracking-wide'>
                    <li className='my-2 flex gap-2'>
                      <Icon
                        className='text-primary text-2xl'
                        icon='tabler:check'
                      />
                      Everything In Basic+
                    </li>
                    <li className='my-2 flex gap-2'>
                      <Icon
                        className='text-primary text-2xl'
                        icon='tabler:check'
                      />
                      Upload up to 12 images
                    </li>
                    <li className='my-2 flex gap-2'>
                      <Icon
                        className='text-primary text-2xl'
                        icon='tabler:check'
                      />
                      <p>
                        Premium Tag on Ad:
                        <sub className='bg-premium px-4 py-1 rounded-bl-full rounded-tr-full text-center text-textDark font-bold'>
                          Premium
                        </sub>
                      </p>
                    </li>
                    <li className='my-2 flex gap-2'>
                      <Icon
                        className='text-primary text-2xl'
                        icon='tabler:check'
                      />
                      Higher promotional priority
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='w-72 h-[27rem] border-zinc-300 border-2 relative'>
              <div className='bg-light absolute z-10 w-full h-full' />
              <div className='relative z-20'>
                <div className='flex flex-col items-center border-zinc-300 border-b-2 py-4'>
                  <h3 className='text-darkText text-2xl mb-2 font-bold font-head tracking-wider'>
                    Premium Upgrade
                  </h3>
                  <p className='text-darkText font-semibold tracking-wider'>
                    Elevate To Premium Status
                  </p>
                  {currency ? (
                    <p className='text-3xl text-darkText font-bold font-head tracking-widest mt-4'>
                      {price ? `$${price?.premiumUpgrade.priceTTD}` : "--"}
                      <sub className='ml-3 font-lato font-semibold text-sm tracking-wide'>
                        TTD (per ad)
                      </sub>
                    </p>
                  ) : (
                    <p className='text-3xl text-darkText font-bold font-head tracking-widest mt-4'>
                      {price ? `$${price?.premiumUpgrade.priceUSD}` : "--"}
                      <sub className='ml-3 font-lato font-semibold text-sm tracking-wide'>
                        USD (per ad)
                      </sub>
                    </p>
                  )}
                  <Link to='/dashboard'>
                    <button
                      className='bg-primary w-full text-center text-lightText font-bold rounded-full h-8 px-6 py-0 mt-4 tracking-wider'
                      type='button'
                    >
                      Upgrade
                    </button>
                  </Link>
                </div>
                <div className='py-2 px-4'>
                  <h4 className='font-extrabold text-base font-head'>
                    What's Included?
                  </h4>
                  <ul className='mt-4 text-textDark tracking-wide'>
                    <li className='my-2 flex gap-2'>
                      <Icon
                        className='text-primary text-2xl'
                        icon='tabler:check'
                      />
                      Everything In Premium+
                    </li>
                    <li className='my-2 flex gap-2'>
                      <Icon
                        className='text-primary text-2xl'
                        icon='zondicons:refresh'
                      />
                      Refreshed Ad
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='w-72 h-[27rem] border-zinc-300 border-2 relative'>
              <div className='bg-light absolute z-10 w-full h-full' />
              <div className='relative z-20'>
                <div className='flex flex-col items-center border-zinc-300 border-b-2 py-4'>
                  <h3 className='text-darkText text-2xl mb-2 font-bold font-head tracking-wider'>
                    Refresh Ad
                  </h3>
                  <p className='text-darkText font-semibold tracking-wider'>
                    Don't Get Left Behind
                  </p>
                  {currency ? (
                    <p className='text-3xl text-darkText font-bold font-head tracking-widest mt-4'>
                      {price ? `$${price?.refreshAd.priceTTD}` : "--"}
                      <sub className='ml-3 font-lato font-semibold text-sm tracking-wide'>
                        TTD (per ad)
                      </sub>
                    </p>
                  ) : (
                    <p className='text-3xl text-darkText font-bold font-head tracking-widest mt-4'>
                      {price ? `$${price?.refreshAd.priceUSD}` : "--"}
                      <sub className='ml-3 font-lato font-semibold text-sm tracking-wide'>
                        USD (per ad)
                      </sub>
                    </p>
                  )}
                  <Link to='/dashboard'>
                    <button
                      className='bg-primary w-full text-center text-lightText font-bold rounded-full h-8 px-6 py-0 mt-4 tracking-wider'
                      type='button'
                    >
                      Get Started
                    </button>
                  </Link>
                </div>
                <div className='py-2 px-4'>
                  <h4 className='font-extrabold text-base font-head'>
                    What's Included?
                  </h4>
                  <ul className='mt-4 text-textDark tracking-wide'>
                    <li className='my-2 flex gap-2'>
                      <Icon
                        className='text-primary text-2xl'
                        icon='zondicons:refresh'
                      />
                      Refreshed Ad
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section id='faq' className='my-12 sm:mx-16 lg:mx-28 xl:mx-72'>
            <h3 className='text-[28px] font-semibold text-textDark font-head leading-[40px] text-center mb-8'>
              Frequently Asked Questions
            </h3>
            <div className='flex flex-col gap-2'>
              <div className='w-full border-zinc-300 border-b-2 border-solid'>
                <div
                  onClick={() => {
                    setFAQ((prev) => {
                      let newState = !prev.first;
                      return { ...prev, first: newState };
                    });
                  }}
                  className='flex justify-between px-4 items-center py-2 cursor-pointer'
                >
                  <h3 className='text-textDark text-[18px] leading-[28px] font-semibold'>
                    What is "Higher Priority"?
                  </h3>
                  <Icon
                    className={
                      fAQ.first
                        ? "text-3xl lg:text-5xl font-bold rotate-180 text-primary"
                        : "text-3xl lg:text-5xl font-bold text-primary"
                    }
                    icon='ic:round-arrow-drop-up'
                  />
                </div>
                <div
                  className={
                    fAQ.first
                      ? "px-4 border-zinc-300 border-t-2 border-solid bg-zinc-400/10"
                      : "px-4 border-zinc-300 border-t-2 border-solid hidden"
                  }
                >
                  <p className='text-textDark my-4'>
                    Higher Priorty Ads are push to the top of relevant search
                    results and placed on non-relevant product pages, therefore
                    increasing exposure.
                  </p>
                </div>
              </div>
              <div className='w-full border-zinc-300 border-b-2 border-solid'>
                <div
                  onClick={() => {
                    setFAQ((prev) => {
                      let newState = !prev.second;
                      return { ...prev, second: newState };
                    });
                  }}
                  className='flex justify-between px-4 items-center py-2 cursor-pointer'
                >
                  <h3 className='text-textDark text-[18px] leading-[28px] font-semibold'>
                    Do I need to blur my license plate before uploading?
                  </h3>
                  <Icon
                    className={
                      fAQ.second
                        ? "text-3xl lg:text-5xl font-bold rotate-180 text-primary"
                        : "text-3xl lg:text-5xl font-bold text-primary"
                    }
                    icon='ic:round-arrow-drop-up'
                  />
                </div>
                <div
                  className={
                    fAQ.second
                      ? "px-4 border-zinc-300 border-t-2 border-solid bg-zinc-400/10"
                      : "px-4 border-zinc-300 border-t-2 border-solid hidden"
                  }
                >
                  <p className='text-textDark my-4'>
                    No. License plates will automatically be blurred out during
                    the upload process.
                  </p>
                </div>
              </div>
              <div className='w-full border-zinc-300 border-b-2 border-solid'>
                <div
                  onClick={() => {
                    setFAQ((prev) => {
                      let newState = !prev.third;
                      return { ...prev, third: newState };
                    });
                  }}
                  className='flex justify-between px-4 items-center py-2 cursor-pointer'
                >
                  <h3 className='text-textDark text-[18px] leading-[28px] font-semibold'>
                    Can I take portrait photos?
                  </h3>
                  <Icon
                    className={
                      fAQ.third
                        ? "text-3xl lg:text-5xl font-bold rotate-180 text-primary"
                        : "text-3xl lg:text-5xl font-bold text-primary"
                    }
                    icon='ic:round-arrow-drop-up'
                  />
                </div>
                <div
                  className={
                    fAQ.third
                      ? "px-4 border-zinc-300 border-t-2 border-solid bg-zinc-400/10"
                      : "px-4 border-zinc-300 border-t-2 border-solid hidden"
                  }
                >
                  <p className='text-textDark my-4'>
                    Yes. But for the best result photos should be taken in
                    landscape.
                  </p>
                </div>
              </div>
              <div className='w-full border-zinc-300 border-b-2 border-solid'>
                <div
                  onClick={() => {
                    setFAQ((prev) => {
                      let newState = !prev.forth;
                      return { ...prev, forth: newState };
                    });
                  }}
                  className='flex justify-between px-4 items-center py-2 cursor-pointer'
                >
                  <h3 className='text-textDark text-[18px] leading-[28px] font-semibold'>
                    What happens to Ads with the status sold?
                  </h3>
                  <Icon
                    className={
                      fAQ.forth
                        ? "text-3xl lg:text-5xl font-bold rotate-180 text-primary"
                        : "text-3xl lg:text-5xl font-bold text-primary"
                    }
                    icon='ic:round-arrow-drop-up'
                  />
                </div>
                <div
                  className={
                    fAQ.forth
                      ? "px-4 border-zinc-300 border-t-2 border-solid bg-zinc-400/10"
                      : "px-4 border-zinc-300 border-t-2 border-solid hidden"
                  }
                >
                  <p className='text-textDark my-4'>
                    Ads with the status sold are automatically removed after a 2
                    week period.
                  </p>
                </div>
              </div>
              <div className='w-full border-zinc-300 border-b-2 border-solid'>
                <div
                  onClick={() => {
                    setFAQ((prev) => {
                      let newState = !prev.fifth;
                      return { ...prev, fifth: newState };
                    });
                  }}
                  className='flex justify-between px-4 items-center py-2 cursor-pointer'
                >
                  <h3 className='text-textDark text-[18px] leading-[28px] font-semibold'>
                    What happens to Ads when it is Refreshed?
                  </h3>
                  <Icon
                    className={
                      fAQ.fifth
                        ? "text-3xl lg:text-5xl font-bold rotate-180 text-primary"
                        : "text-3xl lg:text-5xl font-bold text-primary"
                    }
                    icon='ic:round-arrow-drop-up'
                  />
                </div>
                <div
                  className={
                    fAQ.fifth
                      ? "px-4 border-zinc-300 border-t-2 border-solid bg-zinc-400/10"
                      : "px-4 border-zinc-300 border-t-2 border-solid hidden"
                  }
                >
                  <p className='text-textDark my-4'>
                    Refreshed Ads get placed at the top of the front page and on
                    relevant searches, increasing visibility and significantly
                    improving the odds of vehicle being sold.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PricingSelection;
