import { Outlet, ScrollRestoration } from "react-router-dom";
import { useUserAuth } from "./api/fireauth";
import Nav from "./components/Nav";
import Notification from "./components/Notification";
import "./App.css";
import Modal from "./components/Modal";

function App() {
  const { user } = useUserAuth();
  return (
    <div className='h-full font-body text-darkText'>
      <Nav user={user} />
      <Modal />
      <Outlet context={{ user }} />
      <Notification />
      <ScrollRestoration />
    </div>
  );
}

export default App;
