const ScrollTo = (ref) => {
  const y = ref.current.getBoundingClientRect().top + window.pageYOffset + -100;
  if (ref && ref.current) {
    setTimeout(() => {
      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    }, 0);
  }
};
export { ScrollTo };
