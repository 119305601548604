import { Divide as Hamburger } from "hamburger-react";
import { Icon } from "@iconify/react";
import MobileSideBar from "../SideBarMobile";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { isAdmin } from "../../api/fireauth";

function Nav({ user }) {
  const location = useLocation();
  const [theme, setTheme] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isHide, setHide] = useState(false);
  const [admin, setAdmin] = useState(false);
  useEffect(() => {
    if (user) {
      const getAdmin = async () => {
        let adminBool = await isAdmin();
        setAdmin(adminBool);
      };
      getAdmin();
    }
  }, [user]);
  useEffect(() => {
    if (isOpen) {
      document.getElementsByTagName("HTML")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("HTML")[0].style.overflow = "auto";
    }
  }, [isOpen]);
  useEffect(() => {
    setHide(false);
    switch (location.pathname) {
      case "/auth":
        setHide(true);
        break;
      case "/":
        setTheme(true);
        break;
      case "/form":
        setTheme(true);
        break;
      case "/login":
        setHide(true);
        break;
      case "/about":
        setTheme(true);
        break;
      default:
        setTheme(false);
    }
  }, [location]);
  if (isHide) {
    return "";
  }
  return (
    <>
      <nav
        className={
          theme
            ? "w-full flex flex-row justify-between h-12 md:h-16 px-1 sm:px-4 lg:px-40 absolute z-50 overflow-hidden text-white"
            : isOpen
            ? "w-full flex flex-row justify-between h-12 md:h-16 px-1 sm:px-4 lg:px-40 absolute z-50 overflow-hidden"
            : "w-full flex flex-row justify-between h-12 md:h-16 px-1 sm:px-4 lg:px-40 absolute z-50 overflow-hidden bg-light text-black drop-shadow-md"
        }
      >
        <div className='md:hidden'>
          <Hamburger
            label={isOpen ? "Close Menu" : "Open Menu"}
            toggled={isOpen}
            toggle={() => {
              setIsOpen((prev) => !prev);
            }}
            size={32}
            color={isOpen ? "#1e0a47" : theme ? "#FFF7FE" : "#1e0a47"}
          />
        </div>
        {isOpen ? (
          ""
        ) : (
          <>
            <Link to='/' aria-label='Go back to HomePage' className='w-40'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 510 93.59'
                className='w-full h-12 md:h-16'
              >
                <g id='Layer_2' data-name='Layer 2'>
                  <g id='Layer_1-2' data-name='Layer 1'>
                    <g id='Layer_2-2' data-name='Layer 2'>
                      <g id='Layer_3' data-name='Layer 3'>
                        <text
                          transform='translate(79.22 86.23) scale(1.01 1)'
                          fontSize='59.32'
                          fill='#7f2e84'
                          fontFamily='bulletItalic'
                          style={{ isolation: "isolate" }}
                        >
                          howRoom
                        </text>
                      </g>
                    </g>
                    <g id='Layer_2-3' data-name='Layer 2'>
                      <g id='Shapes'>
                        <g>
                          <path
                            d='M0,2.59V83a3.09,3.09,0,0,0,2.57,3c2.57.43,72.32,0,72.32,0A4.48,4.48,0,0,0,77,83.44c.43-1.72,0-80.85,0-80.85A3.83,3.83,0,0,0,73.61,0H3.42S.44,0,0,2.59Z'
                            fill='#7f2e84'
                          />
                          <g>
                            <polygon
                              points='47.88 0 47.88 24.57 66.42 24.57 66.42 15.09 51.33 0 47.88 0'
                              fill='#fff'
                            />
                            <polygon
                              points='45.73 0 45.73 15.52 10.8 15.52 25.89 0 45.73 0'
                              fill='#fff'
                            />
                            <rect
                              x='10.8'
                              y='17.68'
                              width='18.54'
                              height='13.8'
                              fill='#fff'
                            />
                            <polygon
                              points='45.73 34.06 45.73 52.6 29.34 52.6 10.8 34.06 45.73 34.06'
                              fill='#fff'
                            />
                            <polygon
                              points='47.88 34.06 47.88 68.12 66.42 68.12 66.42 52.6 47.88 34.06'
                              fill='#fff'
                            />
                            <polygon
                              points='29.34 61.22 29.34 86.23 25.89 86.23 10.8 71.14 10.8 61.22 29.34 61.22'
                              fill='#fff'
                            />
                            <polygon
                              points='31.5 70.71 31.5 86.23 51.33 86.23 66.85 70.71 31.5 70.71'
                              fill='#fff'
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <path
                      d='M446.72,21.85l-4.5,17.9h-18.6l-13.2,47h-21.2l13.2-47h-18.6l4.5-17.9Z'
                      fill='#cd2735'
                    />
                    <path
                      d='M509.62,21.85l-4.5,17.9h-18.6l-13.2,47h-21.2l13.2-47h-18.6l4.5-17.9Z'
                      fill='#cd2735'
                    />
                  </g>
                </g>
              </svg>
            </Link>
            <div className='hidden w-1/2 pl-16 leading-8 h-full md:w-full md:flex text-base items-center justify-start gap-6 tracking-wide overflow-visible'>
              <Link to='/pricing'>
                <h2 className='font-head font-bold tracking-wide'>Post Ad</h2>
              </Link>
              <Link to='/pricing#faq'>
                <h2 className='font-head font-bold tracking-wide'>FAQ</h2>
              </Link>
              <Link to='/about'>
                <h2 className='font-head font-bold tracking-wide'>About</h2>
              </Link>
              {admin ? (
                <Link to='https://firecms49--preview-v63laabf.web.app/'>
                  <h2 className='font-head font-bold tracking-wide'>Admin</h2>
                </Link>
              ) : (
                ""
              )}
            </div>
            {user ? (
              <Link
                to='/dashboard'
                aria-label='Account Dashboard'
                className='place-self-center w-8'
              >
                <Icon
                  className={
                    theme
                      ? "w-full h-8 font-bold text-light"
                      : isOpen
                      ? "w-full h-8 font-bold text-light"
                      : "w-full h-8 font-bold text-darkText"
                  }
                  icon='ic:round-dashboard'
                />
              </Link>
            ) : (
              <Link
                to='/login'
                aria-label='Login to Account'
                className='place-self-center w-8'
              >
                <Icon
                  className={
                    theme
                      ? "w-full h-8 font-bold text-light"
                      : isOpen
                      ? "w-full h-8 font-bold text-light"
                      : "w-full h-8 font-bold text-darkText"
                  }
                  icon='ic:round-login'
                />
              </Link>
            )}
          </>
        )}
      </nav>
      {/* <div className='flex justify-between'>
        <h1>Justify</h1>
        <h1>Test</h1>
        <h1>Test</h1>
      </div>{" "}
      <div className='flex justify-between'>
        <div>
          <Icon
            className='w-full h-8 font-bold text-darkText'
            icon='ic:round-dashboard'
          />
        </div>
        <div>
          <Icon
            className='w-full h-8 font-bold text-darkText'
            icon='ic:round-dashboard'
          />
        </div>
        <div>
          <Icon
            className='w-full h-8 font-bold text-darkText'
            icon='ic:round-dashboard'
          />
        </div>
      </div>
      <div className='flex'>
        <div>
          <Icon
            className='w-full h-8 font-bold text-darkText'
            icon='ic:round-dashboard'
          />
        </div>
        <div className='grow'></div>
        <div>
          <Icon
            className='w-full h-8 font-bold text-darkText'
            icon='ic:round-dashboard'
          />
        </div>
        <div className='grow'></div>
        <div>
          <Icon
            className='w-full h-8 font-bold text-darkText'
            icon='ic:round-dashboard'
          />
        </div>
      </div>
      <div className='flex'>
        <div className='w-8'>
          <Icon
            className='w-full h-8 font-bold text-darkText'
            icon='ic:round-dashboard'
          />
        </div>
        <div className='grow'></div>
        <div className='w-8'>
          <Icon
            className='w-full h-8 font-bold text-darkText'
            icon='ic:round-dashboard'
          />
        </div>
        <div className='grow'></div>
        <div className='w-8'>
          <Icon
            className='w-full h-8 font-bold text-darkText'
            icon='ic:round-dashboard'
          />
        </div>
      </div> */}
      {isOpen ? (
        <MobileSideBar
          user={user}
          toggle={() => {
            setIsOpen((prev) => !prev);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}
export default Nav;
