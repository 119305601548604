import { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { sendEmailLink } from "../../api/fireauth";
import loginSchema from "./loginSchema";
import { Formik, Form, Field } from "formik";
import { GetSingleDocument } from "../../api/firestore";
import { Link } from "react-router-dom";
import useNoti from "../../context/notificationhook";
import GoogleAuthButton from "../GoogleSignInButton/googlebutton";

function Login({ email, setEmailCB, toggle }) {
  const formikRef = useRef();
  const { Show } = useNoti();
  const [formData, setFormData] = useState({});
  const [toggleText, setToggleText] = useState(false);
  const [loader, setLoader] = useState(false);
  const [toggleResend, setToggleResend] = useState(false);
  const FormSubmit = (values) => {
    setFormData({ email: values.email });
    setLoader(true);
    GetSingleDocument("Users", null, {
      key: "email",
      operator: "==",
      value: values.email,
    }).then(async (data) => {
      if (data) {
        await sendEmailLink(values.email);
        setLoader(false);
        setToggleText(true);
      } else {
        setEmailCB(values.email);
        toggle();
        Show("You need to create an Account", false);
      }
    });
  };

  const ToggleFunc = () => {
    let ResendToggle = setTimeout(() => {
      setToggleResend(true);
      clearTimeout(ResendToggle);
    }, 10000);

  }
  useEffect(() => {
    if (toggleText) {
      ToggleFunc();
    }
  }, [toggleText]);
  return (
    <div className='md:col-start-2 relative z-10 md:z-20 bg-[#160714] w-full text-center h-full overflow-hidden md:mr-12'>
      <svg xmlns="http://www.w3.org/2000/svg" className="absolute md:hidden -z-10 opacity-50 w-[30rem] left-12 top-36" viewBox="0 0 630 630" width="600" height="600" preserveAspectRatio="none">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <circle className="cls-1" fill="none" stroke="#5d52a3" strokeMiterlimit={10} strokeWidth={12} cx="322.06" cy="317.65" r="300" />
            <circle className="cls-2" fill="#5d52a3" cx="254.41" cy="30.88" r="30.88" />
            <circle className="cls-2" fill="#5d52a3" cx="30.88" cy="377.21" r="30.88" />
            <circle className="cls-2" fill="#5d52a3" cx="600" cy="211.03" r="30.88" />
            <circle className="cls-2" fill="#5d52a3" cx="497.06" cy="558.09" r="30.88" />
          </g>
        </g>
      </svg>

      <div className='md:hidden pt-2 flex flex-row justify-center'>
        <Link to='/'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 510 93.59'
            className='max-w-40 h-10'
          >
            <g id='Layer_2' data-name='Layer 2'>
              <g id='Layer_1-2' data-name='Layer 1'>
                <g id='Layer_2-2' data-name='Layer 2'>
                  <g id='Layer_3' data-name='Layer 3'>
                    <text
                      transform='translate(79.22 86.23) scale(1.01 1)'
                      fontSize='59.32'
                      fill='#7f2e84'
                      fontFamily='bulletItalic'
                      style={{ isolation: "isolate" }}
                    >
                      howRoom
                    </text>
                  </g>
                </g>
                <g id='Layer_2-3' data-name='Layer 2'>
                  <g id='Shapes'>
                    <g>
                      <path
                        d='M0,2.59V83a3.09,3.09,0,0,0,2.57,3c2.57.43,72.32,0,72.32,0A4.48,4.48,0,0,0,77,83.44c.43-1.72,0-80.85,0-80.85A3.83,3.83,0,0,0,73.61,0H3.42S.44,0,0,2.59Z'
                        fill='#7f2e84'
                      />
                      <g>
                        <polygon
                          points='47.88 0 47.88 24.57 66.42 24.57 66.42 15.09 51.33 0 47.88 0'
                          fill='#fff'
                        />
                        <polygon
                          points='45.73 0 45.73 15.52 10.8 15.52 25.89 0 45.73 0'
                          fill='#fff'
                        />
                        <rect
                          x='10.8'
                          y='17.68'
                          width='18.54'
                          height='13.8'
                          fill='#fff'
                        />
                        <polygon
                          points='45.73 34.06 45.73 52.6 29.34 52.6 10.8 34.06 45.73 34.06'
                          fill='#fff'
                        />
                        <polygon
                          points='47.88 34.06 47.88 68.12 66.42 68.12 66.42 52.6 47.88 34.06'
                          fill='#fff'
                        />
                        <polygon
                          points='29.34 61.22 29.34 86.23 25.89 86.23 10.8 71.14 10.8 61.22 29.34 61.22'
                          fill='#fff'
                        />
                        <polygon
                          points='31.5 70.71 31.5 86.23 51.33 86.23 66.85 70.71 31.5 70.71'
                          fill='#fff'
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <path
                  d='M446.72,21.85l-4.5,17.9h-18.6l-13.2,47h-21.2l13.2-47h-18.6l4.5-17.9Z'
                  fill='#cd2735'
                />
                <path
                  d='M509.62,21.85l-4.5,17.9h-18.6l-13.2,47h-21.2l13.2-47h-18.6l4.5-17.9Z'
                  fill='#cd2735'
                />
              </g>
            </g>
          </svg>
        </Link>
      </div>
      <div className='md:bg-white md:rounded-l-3xl flex flex-col justify-start md:justify-center items-center gap-6 md:gap-4 md:h-full mt-16 md:mt-0'>
        <Icon
          className='w-16 h-16  md:h-20 md:w-20 font-bold text-accent mr-4'
          icon='ic:round-login'
        />
        <h2 className='w-full text-light md:text-darkText font-head font-semibold text-4xl tracking-wider'>
          Welcome Back!
        </h2>
        {toggleText ? (
          ""
        ) : (
          <>
            <p className='w-full text-lg text-lightText md:text-darkText tracking-wider font-bold'>
              Sign in to your account
            </p>
            <Formik
              initialValues={{
                email: email,
              }}
              validationSchema={loginSchema}
              onSubmit={FormSubmit}
              innerRef={formikRef}
            >
              {({ isSubmitting, errors }) => (
                <Form className='mt-4 flex flex-col items-center gap-4 w-full'>
                  <Field
                    className='w-4/5 sm:w-3/5 h-12 bg-[#160714] md:bg-zinc-300 md:text-zinc-700 focus:border-tertiary text-lightText text-base tracking-widest font-semibold text-center rounded-full border-2 border-lightText md:border-zinc-400'
                    name='email'
                    type='text'
                    component='input'
                    placeholder='Email'
                  />
                  {errors.email ? (
                    <h2 className='text-lg md:font-semibold text-danger tracking-widest'>
                      {errors.email}
                    </h2>
                  ) : (
                    ""
                  )}
                  <button
                    type='submit'
                    className='font-bold relative tracking-wide justify-center bg-secondary md:bg-primary text-lightText rounded-full w-3/5 sm:w-2/5 h-10'
                    disabled={isSubmitting}
                  >
                    <span className='text-center'>Login</span>
                    {loader ? (
                      <div className='absolute right-2 inline' role='status'>
                        <svg
                          aria-hidden='true'
                          className='inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-light'
                          viewBox='0 0 100 101'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                            fill='currentColor'
                          />
                          <path
                            d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                            fill='currentFill'
                          />
                        </svg>
                        <span className='sr-only'>Loading...</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </button>
                </Form>
              )}
            </Formik>
            <div>
              <div className='flex no-wrap items-center'>
                <p className='w-full h-1 bg-zinc-400 md:bg-zinc-300'></p>
                <h4 className='text-lightText mx-2 md:text-darkText'>Or</h4>
                <p className='w-full h-1 bg-zinc-400 md:bg-zinc-300'></p>
              </div>

              <div className='flex flex-col items-center gap-4'>
                <GoogleAuthButton signIn={true} />
              </div>
            </div>
          </>
        )}

        {toggleText ? (
          <>
            <div className='mx-4'>
              <h2 className='text-lg text-lightText md:text-darkText font-semibold'>
                A link has just been sent to your Inbox. Please wait a bit
                before requesting a new one.
              </h2>
            </div>
            {toggleResend ? (
              <>
                <div className='w-full'>
                  <button
                    onClick={() => {
                      FormSubmit(formData)
                      setToggleResend(false);
                      ToggleFunc();
                    }}
                    className='bg-tertiary px-4 py-2 rounded-lg font-bold tracking-widest'
                  >
                    Resend
                  </button>
                </div>
                <div className='w-full'>
                  <button
                    onClick={() => {
                      setToggleText(false);
                      setEmailCB("");
                      setFormData({});
                    }}
                    className='text-light font-semibold tracking-wide underline decoration-4 decoration-secondary'
                  >
                    Renter Email
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {toggleText ? (
          ""
        ) : (
          <p className='text-zinc-400 md:text-zinc-600 text-base'>
            Don't have an account?
            <span
              className='ml-2 cursor-pointer text-zinc-200 md:text-darkText tracking-wider font-bold border-b-4 border-secondary'
              onClick={toggle}
            >
              Sign up now
            </span>
          </p>
        )}
      </div>
    </div>
  );
}
export default Login;
