import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { useNavigate } from "react-router-dom";

function TypeSlider() {
  const navigate = useNavigate();

  return (
    <div className='mb-6'>
      <div className='relative'>
        <div className='absolute bg-accent w-2 sm:w-3 h-12 sm:h-14 -top-2 left-1 sm:left-2' />
        <h2 className='uppercase text-darkText font-semibold font-head text-2xl tracking-wide mb-6 ml-4 sm:ml-8'>
          Vehicle Type
        </h2>
      </div>
      <div className='flex-justify-center'>
        <Splide
          aria-label='Types of Vehicles'
          options={{
            rewind: false,
            width: "100%",
            mediaQuery: "min",
            gap: "0.5rem",
            perPage: 3,
            arrows: false,
            pagination: false,
            breakpoints: {
              640: {
                perPage: 4,
              },
              1024: {
                perPage: 5,
              },
              1228: {
                perPage: 6,
              },
            },
          }}
        >
          <SplideSlide
            onClick={() => {
              navigate(`search?q=suv`);
            }}
            className='relative cursor-pointer w-16 px-4 py-2 h-24 md:h-32 flex flex-col justify-center z-3 text-lightText font-semibold text-center rounded-xl'
            role='button'
          >
            <div className='absolute bg-primary w-11/12 h-full -skew-x-12 right-0'></div>
            <div className='relative z-10 flex flex-col justify-center items-center'>
              <img
                className='h-1/2'
                src='https://firebasestorage.googleapis.com/v0/b/showroom478.appspot.com/o/Assets%2FSlider%2FSuv.webp?alt=media&token=78b69eb1-3b4d-44b8-a89f-bb6084f3b90b'
                alt='SUV'
                title="SUV Category Link"
              />
              <h3 className='text-xl tracking-wide font-head font-semibold'>
                SUVs
              </h3>
            </div>
          </SplideSlide>
          <SplideSlide
            onClick={() => {
              navigate(`search?q=sedan`);
            }}
            role='button'
            className='realtive cursor-pointer w-16 px-4 py-2 h-24 md:h-32 flex flex-col justify-center text-lightText font-semibold text-center rounded-xl'
          >
            <div className='absolute bg-primary w-11/12 h-full -skew-x-12 right-0'></div>
            <div className='relative z-10 flex flex-col justify-center items-center'>
              <img
                className='h-1/2 w-auto'
                src='https://firebasestorage.googleapis.com/v0/b/showroom478.appspot.com/o/Assets%2FSlider%2FSedan.webp?alt=media&token=8cec13cc-fac4-4b05-83a8-39c48f2a9042'
                alt='Sedans'
                title="Sedan Category Link"
              />
              <h3 className='text-xl tracking-wide font-head font-semibold'>
                Sedans
              </h3>
            </div>
          </SplideSlide>
          <SplideSlide
            onClick={() => {
              navigate(`search?q=pickup`);
            }}
            role='button'
            className='cursor-pointer w-16 px-4 py-2 h-24 md:h-32 flex flex-col justify-center text-lightText font-semibold text-center rounded-xl'
          >
            <div className='absolute bg-primary w-11/12 h-full -skew-x-12 right-0'></div>
            <div className='relative z-10 flex flex-col justify-center items-center'>
              <img
                className='h-1/2 w-auto'
                src='https://firebasestorage.googleapis.com/v0/b/showroom478.appspot.com/o/Assets%2FSlider%2FPickup.webp?alt=media&token=51611dd8-242f-4a5c-b26b-19fe7376d336'
                alt='Pickups'
                title="PickUp Category Link"
              />
              <h3 className='text-xl tracking-wide font-head font-semibold'>
                Pickups
              </h3>
            </div>
          </SplideSlide>
          <SplideSlide
            onClick={() => {
              navigate(`search?q=truck`);
            }}
            role='button'
            className='relative cursor-pointer w-16 px-4 py-2 h-24 md:h-32 flex flex-col justify-center text-lightText font-semibold text-center rounded-xl'
          >
            <div className='absolute bg-primary w-11/12 h-full -skew-x-12 right-0'></div>
            <div className='relative z-10 flex flex-col justify-center items-center'>
              <img
                className='h-1/2 w-auto'
                src='https://firebasestorage.googleapis.com/v0/b/showroom478.appspot.com/o/Assets%2FSlider%2FTruck.webp?alt=media&token=72eb9d51-569c-4ccf-9c37-b4cc7a6a4ec6'
                alt='Trucks'
                title="Truck Category Link"
              />
              <h3 className='text-xl tracking-wide font-head font-semibold'>
                Trucks
              </h3>
            </div>
          </SplideSlide>
          <SplideSlide
            onClick={() => {
              navigate(`search?q=boat`);
            }}
            role='button'
            className='relative cursor-pointer w-16 px-4 py-2 h-24 md:h-32 flex flex-col justify-center text-lightText font-semibold text-center rounded-xl'
          >
            <div className='absolute bg-primary w-11/12 h-full -skew-x-12 right-0'></div>
            <div className='relative z-10 flex flex-col justify-center items-center'>
              <img
                className='h-1/2 w-auto'
                src='https://firebasestorage.googleapis.com/v0/b/showroom478.appspot.com/o/Assets%2FSlider%2FBoat.webp?alt=media&token=d1c92f18-d174-492c-bf5f-69de594c221f'
                alt='Boats'
                title="Boat Category Link"
              />
              <h3 className='text-xl tracking-wide font-head font-semibold'>
                Boats
              </h3>
            </div>
          </SplideSlide>
          <SplideSlide
            onClick={() => {
              navigate(`search?q=other`);
            }}
            role='button'
            className='relative cursor-pointer w-16 px-4 py-2 h-24 md:h-32 flex flex-col justify-center text-lightText font-semibold text-center rounded-xl'
          >
            {" "}
            <div className='absolute bg-primary w-11/12 h-full -skew-x-12 right-0'></div>
            <div className='relative z-10 flex flex-col justify-center items-center'>
              <img
                className='h-1/2 w-auto'
                src='https://firebasestorage.googleapis.com/v0/b/showroom478.appspot.com/o/Assets%2FSlider%2FOther.webp?alt=media&token=b9f36584-b524-47b5-b9e9-3e02c6f2af89'
                alt='Other Vehicles'
                title="Other Vehicles Category Link"
              />
              <h3 className='text-xl tracking-wide font-head font-semibold'>
                Others
              </h3>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
}

export default TypeSlider;
