import HelmetTags from "../components/Helmet/helmet";
import PricingSelection from "../components/PricingSelection";

function Pricing() {
  return (
    <div>
      <HelmetTags
        pageTitle='Pricing - ShowRoomTT'
        description='Discover transparent and competitive pricing for our services at ShowRoomTT. Explore our Pricing Page for detailed information on plans, features, and options. Check out our pricing now!'
        link='www.showroomtt.com/pricing'
      />
      <PricingSelection />
    </div>
  );
}

export default Pricing;
