import { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const { user } = useOutletContext();
  useEffect(() => {
    if (user === false) {
      navigate("/login", { state: { msg: "Please Login", status: true } });
    }
  }, [user]);
  if (user) {
    return children;
  }
  return "";
}

export default ProtectedRoute;
