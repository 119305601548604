import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authEmailLink } from "../api/fireauth";
import AuthLoader from "../components/AuthLoader";
import { CreateNewUser } from "../api/firestore";

function authPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [operation, setOperation] = useState("Authenticating ...");
  const UserCreation = async (userData) => {
    await CreateNewUser({
      contactName: userData.name,
      uid: userData.uid,
      email: userData.email,
    }).then(() => {
      navigate("/", {
        state: { msg: `Welcome ${userData.name}`, success: true },
      });
    });
  };
  useEffect(() => {
    if (state) {
      if (state.newUser) {
        setOperation("Creating Account ...");
        UserCreation(state);
      } else {
        navigate("/", { state: { msg: "Unauthorized", success: false } });
      }
    } else {
      if (localStorage.getItem("emailForSignIn")) {
        const CheckSignIn = async () => {
          const AuthRes = await authEmailLink();
          if (AuthRes.isAuth) {
            if (AuthRes.NewUser) {
              setOperation("Creating Account ...");
              UserCreation(AuthRes.NewUser);
            } else {
              navigate("/", {
                state: { msg: "Login Successful", success: true },
              });
            }
          } else {
            navigate("/", { state: { msg: "Unauthorized", success: false } });
          }
        };
        CheckSignIn();
      } else {
        navigate("/", { state: { msg: "Unauthorized", success: false } });
      }
    }
  }, []);
  return (
    <div className='w-full h-screen flex justify-center'>
      <div className='flex flex-col justify-center'>
        <AuthLoader operation={operation} />
      </div>
    </div>
  );
}

export default authPage;
