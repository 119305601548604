import HelmetTags from "../components/Helmet/helmet";
import Form from "../components/Form";

function FormPage() {
  return (
    <div>
      <HelmetTags
        pageTitle='Form - ShowRoomTT'
        description='Discover transparent and competitive pricing for our services at ShowRoomTT. Explore our Pricing Page for detailed information on plans, features, and options. Check out our pricing now!'
        link='www.showroomtt.com/form'
      />
      <Form />
    </div>
  );
}

export default FormPage;
