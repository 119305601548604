import Login from "../components/Login/Login";
import SignUp from "../components/SignUp";
import useNoti from "../context/notificationhook";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigationType } from "react-router-dom";
import HelmetTags from "../components/Helmet/helmet";

function LoginSignUp() {
  const { state } = useLocation();
  const { Show } = useNoti();
  const naviType = useNavigationType();
  const [emailState, setEmailState] = useState("");
  const [toggleForm, setToggleForm] = useState(true);
  const toggle = () => {
    setToggleForm((prev) => !prev);
  };
  const SetEmailCallback = (email) => {
    setEmailState(email);
  };
  useEffect(() => {
    if (state && naviType !== "POP") {
      Show(state.msg, state.status);
    }
  }, []);
  return (
    <div className='w-full h-screen'>
      <HelmetTags
        pageTitle={toggleForm ? "Login - ShowRoomTT" : "SignUp - ShowRoomTT"}
        description={toggleForm ? "Login Today" : "Register Now"}
        link={"www.showroomtt.com/login"}
      />
      <div className='md:grid md:grid-cols-2 bg-cover h-full'>
        {" "}
        <div className='hidden md:block col-start-1 bg-[#160714] pt-8 pl-8 md:relative md:z-10 md:pl-12'>
          <svg xmlns="http://www.w3.org/2000/svg" className="absolute md:hidden -z-10 opacity-50 w-[30rem] left-12 top-36" viewBox="0 0 630 630" width="600" height="600" preserveAspectRatio="none">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <circle className="cls-1" fill="none" stroke="#5d52a3" strokeMiterlimit={10} strokeWidth={12} cx="322.06" cy="317.65" r="300" />
                <circle className="cls-2" fill="#5d52a3" cx="254.41" cy="30.88" r="30.88" />
                <circle className="cls-2" fill="#5d52a3" cx="30.88" cy="377.21" r="30.88" />
                <circle className="cls-2" fill="#5d52a3" cx="600" cy="211.03" r="30.88" />
                <circle className="cls-2" fill="#5d52a3" cx="497.06" cy="558.09" r="30.88" />
              </g>
            </g>
          </svg>
          <div className='mb-32'>
            <Link to='/'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 510 93.59'
                className='max-w-40 h-12'
              >
                <g id='Layer_2' data-name='Layer 2'>
                  <g id='Layer_1-2' data-name='Layer 1'>
                    <g id='Layer_2-2' data-name='Layer 2'>
                      <g id='Layer_3' data-name='Layer 3'>
                        <text
                          transform='translate(79.22 86.23) scale(1.01 1)'
                          fontSize='59.32'
                          fill='#7f2e84'
                          fontFamily='bulletItalic'
                          style={{ isolation: "isolate" }}
                        >
                          howRoom
                        </text>
                      </g>
                    </g>
                    <g id='Layer_2-3' data-name='Layer 2'>
                      <g id='Shapes'>
                        <g>
                          <path
                            d='M0,2.59V83a3.09,3.09,0,0,0,2.57,3c2.57.43,72.32,0,72.32,0A4.48,4.48,0,0,0,77,83.44c.43-1.72,0-80.85,0-80.85A3.83,3.83,0,0,0,73.61,0H3.42S.44,0,0,2.59Z'
                            fill='#7f2e84'
                          />
                          <g>
                            <polygon
                              points='47.88 0 47.88 24.57 66.42 24.57 66.42 15.09 51.33 0 47.88 0'
                              fill='#fff'
                            />
                            <polygon
                              points='45.73 0 45.73 15.52 10.8 15.52 25.89 0 45.73 0'
                              fill='#fff'
                            />
                            <rect
                              x='10.8'
                              y='17.68'
                              width='18.54'
                              height='13.8'
                              fill='#fff'
                            />
                            <polygon
                              points='45.73 34.06 45.73 52.6 29.34 52.6 10.8 34.06 45.73 34.06'
                              fill='#fff'
                            />
                            <polygon
                              points='47.88 34.06 47.88 68.12 66.42 68.12 66.42 52.6 47.88 34.06'
                              fill='#fff'
                            />
                            <polygon
                              points='29.34 61.22 29.34 86.23 25.89 86.23 10.8 71.14 10.8 61.22 29.34 61.22'
                              fill='#fff'
                            />
                            <polygon
                              points='31.5 70.71 31.5 86.23 51.33 86.23 66.85 70.71 31.5 70.71'
                              fill='#fff'
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <path
                      d='M446.72,21.85l-4.5,17.9h-18.6l-13.2,47h-21.2l13.2-47h-18.6l4.5-17.9Z'
                      fill='#cd2735'
                    />
                    <path
                      d='M509.62,21.85l-4.5,17.9h-18.6l-13.2,47h-21.2l13.2-47h-18.6l4.5-17.9Z'
                      fill='#cd2735'
                    />
                  </g>
                </g>
              </svg>
            </Link>
          </div>
          <div className='flex flex-col gap-12'>
            <h3 className='text-light font-head text-5xl'>
              Start posting ads today!
            </h3>
            <p className='w-5/6 font-semibold text-zinc-300 text-xl tracking-wider leading-9'>
              Strap in and get ready to embark on a seamless and enjoyable
              vehicle-buying or selling experience.
            </p>
          </div>
        </div>
        {toggleForm ? (
          <Login
            email={emailState}
            setEmailCB={SetEmailCallback}
            toggle={toggle}
          />
        ) : (
          <SignUp
            email={emailState}
            setEmailCB={SetEmailCallback}
            toggle={toggle}
          />
        )}
      </div>
    </div>
  );
}

export default LoginSignUp;
