import { useEffect, useState, Suspense, lazy } from "react";
import { useLocation, useNavigationType } from "react-router-dom";
import useNoti from "../context/notificationhook";
import HomeHero from "../components/HomeHero";
import TypeSlider from "../components/VehicleType";
import HelmetTags from "../components/Helmet/helmet";
const HomeFilter = lazy(() => import("../components/HomeFilter/homeFilter"));
const PremiumSec = lazy(() => import("../components/PremiumSection"));
const BasicSec = lazy(() => import("../components/BasicSection"));
function Home() {
  let { state } = useLocation();
  const naviType = useNavigationType();
  const [toggleAdvance, setToggleAdvance] = useState(false);
  const { Show } = useNoti();
  useEffect(() => {
    if (state && naviType !== "POP") {
      setTimeout(() => {
        Show(state.msg, state.success);
      }, 2000);
    }
  }, []);
  const toggle = () => {
    setToggleAdvance((prev) => !prev);
  };
  return (
    <div>
      <HelmetTags
        pageTitle='ShowRoomTT'
        description='ShowRoomTT - Explore the best platform in Trinidad and Tobago for viewing ,selling and vehicles.Reach a wide audience and find the perfect buyer for your vehicle today. List your Vehicle now!'
        link='www.showroomtt.com'
      />
      <HomeHero toggle={toggle} toggleValue={toggleAdvance} />
      <div className='relative flex justify-center'>
        <Suspense>
          <HomeFilter toggle={toggleAdvance} />
        </Suspense>
      </div>
      <div className='relative mx-1 md:mx-12 lg:mx-28 xl:mx-80'>
        <div
          className={
            toggleAdvance
              ? "relative transition-all mt-8 duration-500 ease-in-out"
              : "relative -mt-[800px] xl:-mt-[720px] duration-500 ease-in-out"
          }
        >
          <TypeSlider />
          <div>
            <Suspense>
              <PremiumSec />
              <BasicSec />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
