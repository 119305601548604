import { object, string, number, array } from "yup";

const signUpSchema = object({
  email: string().email("Invalid Email").required("Email is required"),
  contactName: string()
    .max(25, "Exceeded Max Length (25)")
    .min(2, "Min Length (2)")
    .trim()
    .required("Contact Name is Required"),
});

export default signUpSchema;
