import Search from "../components/Search";
import useNoti from "../context/notificationhook";
import { json, useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Algo from "../api/algosearch";
import { GetMultipleUniqueDocs } from "../api/firestore";
import { useInfiniteQuery } from "@tanstack/react-query";

function SearchPage() {
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { Show } = useNoti();
  const [index, setIndex] = useState("base");
  const param = searchParams.get("q");
  const AlgoSearch = async (query, filter = null, nextPage, indexType) => {
    let AlgoIndex;
    switch (indexType) {
      case "dsc":
        AlgoIndex = Algo.dscIndex;
        break;
      case "asc":
        AlgoIndex = Algo.ascIndex;
        break;
      default:
        AlgoIndex = Algo.baseIndex;
    }
    if (filter === null) {
      return await Algo.client.search({
        requests: [{
          indexName: AlgoIndex,
          query: query,
          page: nextPage,
          hitsPerPage: 8,
        }]
      }).then(({ results }) => {
        const list = [];
        results[0].hits.map((item) => {
          list.push({ key: "objectID", operator: "==", value: item.objectID, });
        });
        return { results, list };
      }).then(async ({ results, list }) => {
        const docResults = await GetMultipleUniqueDocs("UserUploads", list, true)

        return {
          query,
          filter,
          documents: docResults,
          hasMore: results[0].page + 1 < results[0].nbPages,
          currentPage: results[0].page,
          totalDocuments: results[0].nbHits,
          index,
        };
      });
    } else {
      return await Algo.client.search({
        requests: [{
          indexName: AlgoIndex,
          filters: filter,
          page: nextPage,
          hitsPerPage: 8,
        }]
      }).then(({ results }) => {
        const list = [];
        results[0].hits.map((item) => {
          list.push({ key: "objectID", operator: "==", value: item.objectID, });
        });
        return { results, list };
      }).then(async ({ results, list }) => {

        const docResults = await GetMultipleUniqueDocs("UserUploads", list, true)

        return {
          query,
          filter,
          documents: docResults,
          hasMore: results[0].page + 1 < results[0].nbPages,
          currentPage: results[0].page,
          totalDocuments: results[0].nbHits,
          index,
        };
      });
    }
  }
  const SearchQuery = async (pageParam) => {
    if (state) {
      if (state.filter) {
        const data = await AlgoSearch(
          pageParam.query.query,
          pageParam.query.filter,
          pageParam.nextPage ? pageParam.nextPage : 0,
          pageParam.query.index
        );
        return data;
      }
    } else {
      return await AlgoSearch(
        pageParam.query.query,
        null,
        pageParam.nextPage ? pageParam.nextPage : 0,
        pageParam.query.index
      );
    }
  };
  const { data, status, fetchNextPage, hasNextPage, refetch, isError } =
    useInfiniteQuery({
      queryKey: [param ? param : "", index],
      queryFn: ({
        pageParam = {
          nextPage: 0,
          query: {
            query: param ? param : "",
            filter: state?.filter ? state.filter : null,
            index,
          },
        },
      }) => SearchQuery(pageParam),
      getNextPageParam: (lastpage) => {
        if (lastpage) {
          if (lastpage.hasMore) {
            return {
              nextPage: lastpage.currentPage + 1,
              query: {
                query: lastpage.query,
                filter: lastpage.filter,
                index: lastpage.index,
              },
            };
          }
          return undefined;
        }
        return undefined;
      },
      enabled: false,
      cacheTime: 120000,
    });
  const SortSearch = async (type) => {
    if (type == "asc") {
      setIndex("asc");
    } else if (type == "dsc") {
      setIndex("dsc");
    } else {
      setIndex("base");
    }
  };
  useEffect(() => {
    refetch();
  }, [index, param]);
  return (
    <div className='pt-12 mx-1 md:mx-12 lg:mx-28 xl:mx-36'>
      <Search
        data={data?.pages}
        status={status}
        next={fetchNextPage}
        hasMore={hasNextPage}
        sortSearch={SortSearch}
        search={setSearchParams}
        totalDocuments={data?.pages[0].totalDocuments}
      />
    </div>
  );
}

export default SearchPage;
