import { object, string, number, array } from "yup";
import phoneUtil from "google-libphonenumber";
const phoneParser = phoneUtil.PhoneNumberUtil.getInstance();

const AccountInfo = object({
  contactName: string().max(30).required("Contact Name Required"),
  userName: string().max(30).required("Contact Name Required"),
  contactNumbers: array()
    .max(3, "Number Max 3")
    .notRequired(),
});
const tempSchema = object().test("tempNumber", "Invalid Phone Number", (value) => {
  return phoneParser.isValidNumber(phoneParser.parseAndKeepRawInput(`${value.number}`, value.meta.iso2));
}).notRequired();

export { AccountInfo, tempSchema };
