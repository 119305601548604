import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyDWz4e1I9hcN9U39UOnJYhQKbvZxq65O48",
  authDomain: "showroom478.firebaseapp.com",
  projectId: "showroom478",
  storageBucket: "showroom478.appspot.com",
  messagingSenderId: "86961942918",
  appId: "1:86961942918:web:22cc558199edc7396a3c07",
  measurementId: "G-FPW92B5JVQ"
};

const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider("6Lf1-C0qAAAAAEg7PxJgAM8XbPxFBviJddzwi-gu"),
  isTokenAutoRefreshEnabled: true
});

const analytics = getAnalytics(app);
export default app;
