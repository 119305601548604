import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import useNoti from "../../context/notificationhook";
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { GetSingleDocument, AddNewDocument } from "../../api/firestore";
import VehicleForm from "./subComponents/vehicleForm";
import ContactForm from "./subComponents/contactForm";
import UploadForm from "./subComponents/uploadForm";
import PaymentForm from "./subComponents/paymentForm";
import Uploader from "./subComponents/uploader";
function Form() {
  try {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { Show } = useNoti();
    const [data, setData] = useState(null);
    const [media, setMedia] = useState([]);
    const [steps, setStepData] = useState(1);
    const [length, setLength] = useState(459);
    const { user } = useOutletContext();
    const [userProfile, setUserProfile] = useState(null);
    useEffect(() => {
      try {
        if (!state) {
          navigate("/pricing", {
            state: { msg: "Please Select Ad Tier", status: false },
          });
          return;
        }
        if (user) {
          fetchProfile();
        }
      } catch (e) {
        throw new Error(e);
      }
    }, []);

    const fetchProfile = async () => {
      await GetSingleDocument("Users", user?.uid)
        .then((data) => {
          if (data) {
            setUserProfile(data);
          }
        })
        .catch((e) => {
          Show("Failed To Retrive User Profile", false);
        });
    };
    const NextStep = (formData) => {
      if (steps == 1) {
        setData(formData);
        setStepData(2);
      } else if (steps == 2) {
        setMedia(formData.files);
        setStepData(3);
      } else if (steps == 3) {
        if (formData) {
          setData((prev) => {
            return { ...prev, formData };
          });
        }
        setStepData(4);
      } else if (steps == 4) {
        setStepData(5);
        submitData();
      }
    };
    const Animate = (progress) => {
      const movement = (progress / 100) * 459;
      setLength((prev) => prev - movement);
    };
    const submitData = async (transId = "Voucher", price = 0) => {
      await AddNewDocument(data, media, state.premium, user?.uid, Animate)
        .then(() => {
          Animate(30);
          window.gtag('event', 'conversion', {
            'send_to': 'AW-16457599553/aeNyCL6h1MoZEMGUzKc9',
            'value': Number(Math.round(price + 'e2') + 'e-2'),
            'currency': 'USD',
            'transaction_id': transId
          });
          setTimeout(() => {
            navigate("/", {
              state: { msg: "Thanks For Choosing ShowRoom", success: true },
            });
          }, 1000);
        })
        .catch((e) => {
          navigate("/", { state: { msg: "Error Occurred", success: false } });
        });
    };
    return (
      <div>
        {steps != 5 ? (
          <>
            <div className='relative w-full bg-[url("https://firebasestorage.googleapis.com/v0/b/showroom478.appspot.com/o/Assets%2FFormHero%2Fformbg.webp?alt=media&token=21dc21ba-caa3-4c61-9230-c959166dcf6c")] bg-[length:110%_110%] h-[300px] sm:h-[400px]'>
              <div className='h-full w-full bg-main/80 pt-20'>
                <div className='text-center flex flex-col justify-center items-center lg:mt-16'>
                  <Icon
                    className='w-16 h-16 font-bold ml-2 text-lightText'
                    icon='clarity:form-line'
                  />
                  <h1 className='text-lightText text-xl tracking-wide w-64'>
                    {userProfile
                      ? `Hi ${userProfile.contactName}, lets begin filling out the form below.`
                      : "Lets begin filling out the form below."}
                  </h1>
                </div>
                <div className='text-center flex justify-start mt-8'>
                  <h3 className='bg-accent text-lightText font-semibold tracking-wider py-1 w-28  rounded-tr-full'>
                    Step {steps} of 4
                  </h3>
                </div>
              </div>
            </div>
            <div className='pb-12 md:mx-12 lg:mx-28 xl:mx-36'>
              {steps == 1 ? <VehicleForm next={NextStep} data={data} /> : ""}
              {steps == 2 ? (
                <UploadForm
                  data={data}
                  media={media}
                  isPremium={state.premium}
                  next={NextStep}
                  previous={setStepData}
                />
              ) : (
                ""
              )}
              {steps == 3 ? (
                <ContactForm
                  next={NextStep}
                  previous={setStepData}
                  user={userProfile}
                  fetchUser={fetchProfile}
                />
              ) : (
                ""
              )}
              {steps == 4 ? (
                <PaymentForm
                  uid={user.uid}
                  price={state.price}
                  item={{ ...data, media }}
                  operation={state.premium ? 1 : 2}
                  submit={NextStep}
                  previous={setStepData}
                />
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <div className='relative z-[51] flex flex-col justify-center h-screen bg-light'>
            <Uploader length={length} />
          </div>
        )}
      </div>
    );
  } catch (e) {
    throw new Error(e);
  }
}
export default Form;
