import useNoti from "../../context/notificationhook";

function Notification() {
  const { text, notiType, active } = useNoti();
  return (
    <div className='relative z-50'>
      <div
        className={
          active
            ? "transition -translate-y-0 ease-in-out duration-500 fixed bottom-4 flex justify-center w-full"
            : "transition fixed flex justify-center w-full translate-y-20 bottom-4 duration-500"
        }
      >
        <div
          className={
            notiType
              ? "bg-success py-3 px-4 rounded-lg"
              : "bg-danger py-3 px-4 rounded-lg"
          }
        >
          <h3 className='font-bold tracking-widest'>{text}</h3>
        </div>
      </div>
    </div>
  );
}
export default Notification;
