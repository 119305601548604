function Uploader({ length }) {
  return (
    <div className='flex justify-center flex-wrap'>
      <svg
        className='h-40'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 405 451.5'
      >
        <defs>
          <style>
            {`
      .cls-1 {
        fill: #7e2e84;
      }

      .cls-2 {
        fill: #919191;
      }

      .cls-3 {
        fill: #fff;
      }
    `}
          </style>
        </defs>
        <g id='Layer_2' data-name='Layer 2'>
          <g id='Box'>
            <path
              className='cls-1'
              d='M.45,13.58V434.65s0,13.58,13.44,15.84,378.69,0,378.69,0,9-4.52,11.2-13.58,0-423.33,0-423.33S399.3,0,385.85,0H18.37S2.69,0,.45,13.58Z'
            />
          </g>
          <g id='Layer_3' data-name='Layer 3'>
            <rect
              className='cls-2 transition-all'
              width='405'
              height={length}
              rx='12'
            />
          </g>
          <g id='Shapes'>
            <polygon
              className='cls-3'
              points='251.15 0 251.15 128.68 348.22 128.68 348.22 79.01 269.21 0 251.15 0'
            />
            <polygon
              className='cls-3'
              points='239.86 0 239.86 81.27 57 81.27 136.02 0 239.86 0'
            />
            <rect
              className='cls-3'
              x='57'
              y='92.56'
              width='97.07'
              height='72.24'
            />
            <polygon
              className='cls-3'
              points='239.86 178.34 239.86 275.42 154.08 275.42 57 178.34 239.86 178.34'
            />
            <polygon
              className='cls-3'
              points='251.15 178.34 251.15 356.69 348.22 356.69 348.22 275.42 251.15 178.34'
            />
            <polygon
              className='cls-3'
              points='154.08 320.56 154.08 451.5 136.02 451.5 57 372.49 57 320.56 154.08 320.56'
            />
            <polygon
              className='cls-3'
              points='165.36 370.23 165.36 451.5 269.21 451.5 350.48 370.23 165.36 370.23'
            />
          </g>
        </g>
      </svg>
      <div className='w-full text-center mt-16 text-3xl font-bold'>
        {length >= 414 ? (
          <h2>Fetching User...</h2>
        ) : length >= 231 ? (
          <h2>Uploading Media...</h2>
        ) : length >= 139 ? (
          <h2>Updating Post...</h2>
        ) : length >= 1 ? (
          <h2>Processing...</h2>
        ) : (
          <h2>Success</h2>
        )}
        <h2></h2>
      </div>
    </div>
  );
}

export default Uploader;
