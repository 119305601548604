import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../api/fireauth";
import { UpdateWatchList } from "../../api/firestore";
import useNoti from "../../context/notificationhook";
import { toCommas } from "../../utils/priceConverter";
import Logo from "../../assets/SLogo.svg";

function Card({
  data,
  options = { temp: false, disableWatch: false, cardIndex: 0 },
}) {
  const [watchList, setWatchList] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const { Show } = useNoti();
  const NewValidate = () => {
    const endTime = Math.floor(Date.now() / 1000);
    const secs = endTime - data.uploadDate.seconds;
    let min = secs / 60;
    let hours = min / 60;
    let days = Math.floor(hours / 24);
    if (days < 7) {
      setIsNew(true);
    }
  };
  useEffect(() => {
    if (!options.temp) {
      NewValidate();
    }
    if (user) {
      if (!options.temp) {
        const search = data.watching.find((item) => item == user.uid);
        if (search) {
          setWatchList(true);
        }
      }
      return;
    }
    if (localStorage.getItem("localList")) {
      const localList = JSON.parse(localStorage.getItem("localList"));
      const isFound = localList.find((item) => item === data.objectID);
      if (isFound) {
        setWatchList(true);
      }
    }
  }, []);
  const toggleWatch = async (evt) => {
    try {
      evt.stopPropagation();
      if (user) {
        if (watchList == true) {
          let res = await UpdateWatchList(data.objectID, !watchList);
          if (res) {
            Show("Removed From Watchlist", true, 3000);
            setWatchList(false);
            return;
          }
        } else {
          let res = await UpdateWatchList(data.objectID, !watchList);
          if (res) {
            Show("Added to Watchlist", true, 3000);
            setWatchList(true);
            return;
          }
        }
      } else {
        navigate("/login", {
          state: { msg: "Please Login to Add to watchList", status: true },
        });
      }
    } catch (e) {
      Show("Error Updating Watchlist", false, 3000);
    }
  };
  const GoToProduct = () => {
    navigate(`/product/${data.objectID}`, { state: data });
  };
  return (
    <div
      onClick={
        options.temp || data.processing
          ? () => {
            if (data.processing) {
              Show("Ad Upload In Progress", true);
              return;
            }
          }
          : GoToProduct
      }
      className='rounded-xl flex flex-col max-w-[15rem] min-w-[11rem]'
    >
      <div className='relative h-40 lg:h-52 w-full'>
        {data.soldStatus ? (
          <div className='absolute z-[1] px-4 py-1 bg-danger rounded-tl-lg rounded-br-lg text-center text-lightText drop-shadow-md'>
            <p className='font-semibold text-sm tracking-widest'>SOLD</p>
          </div>
        ) : (
          ""
        )}
        {data.processing ? (
          <img
            src={Logo}
            className='h-full w-full rounded-tl-lg rounded-tr-lg'
            alt='Placeholder Image: Upload In Progress'
          />
        ) : (
          <img
            className={
              data.soldStatus
                ? "h-full w-full rounded-tl-lg rounded-tr-lg grayscale"
                : "h-full w-full rounded-tl-lg rounded-tr-lg"
            }
            src={options.temp ? data.tempImage : data.thumbnail}
            alt={`${data.brand} ${data.model} picture`}
          />
        )}

        <div className='flex flex-col items-end absolute right-0 bottom-0 text-lightText font-bold'>
          {data.callForPrice ? (
            ""
          ) : data.price < data.prevPrice ? (
            <p className='tracking-widest bg-danger text-sm text-end line-through rounded-tl-lg py-1 px-1'>
              {`$ ${toCommas(data.prevPrice)}`}
            </p>
          ) : (
            ""
          )}
          <p className='tracking-widest bg-primary rounded-tl-lg py-1 pr-1 pl-3'>
            {data.price == 0 ? "Call For Price" : `$ ${toCommas(data.price)}`}
          </p>
        </div>
      </div>
      <div className='relative flex text-darkText bg-cardgrey rounded-bl-lg rounded-br-lg'>
        <div className='relative z-10 w-full'>
          {isNew || data.premium || data.new ? (
            <div className='grid grid-cols-2 text-xs font-bold h-6'>
              {isNew || data.new ? (
                <div className='px-4 py-1 lg:text-base bg-danger text-white rounded-tr-full col-start-1 drop-shadow-md text-center'>
                  <p>New Ad</p>
                </div>
              ) : (
                ""
              )}
              {data.premium ? (
                <div className='flex justify-around pl-2 py-1 lg:text-base bg-premium rounded-bl-full text-center col-start-2 drop-shadow-md'>
                  <Icon
                    className='h-4 w-4 font-bold text-dark self-center'
                    icon='mdi:crown'
                  />
                  <p>Premium</p>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div className='p-2 lg:text-lg'>
            <div className='flex gap-2'>
              <span>Brand:</span>
              <p className='font-semibold truncate'>{data.brand}</p>
            </div>
            <div className='flex gap-2'>
              <span>Model:</span>
              <p className='font-semibold truncate'>{data.model}</p>
            </div>
            <div className='flex gap-2'>
              <span>Series:</span>
              <p className='font-semibold truncate'>{data.series}</p>
            </div>
            {isNew || data.premium || data.new ? (
              ""
            ) : (
              <div className='flex gap-2'>
                <span>Trans:</span>
                <p className='font-semibold truncate'>{data.trans}</p>
              </div>
            )}
            <div className='flex gap-2'>
              <span>Condition:</span>
              <p className='font-semibold truncate'>{data.condition}</p>
            </div>
            {options.disableWatch || data.soldStatus ? (
              ""
            ) : (
              <div
                className='absolute z-20 right-1 bottom-0 w-8 h-8 flex justify-end'
                onClick={toggleWatch}
              >
                {watchList ? (
                  <Icon
                    className='text-[1.75rem] font-bold text-tertiary'
                    icon='mdi:eye-check'
                  />
                ) : (
                  <Icon
                    className='text-[1.75rem] font-bold text-zinc-400'
                    icon='mdi:eye-plus'
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-1'>
        {data.quickSale ? (
          <div className='tracking-widest bg-success font-bold flex justify-around rounded-lg'>
            <p className='text-white'>Quick Sale</p>
            <Icon
              className='text-[1.75rem] font-bold text-premium'
              icon='fluent:flash-20-filled'
            />
          </div>
        ) : (
          ""
        )}
        {data.negotiable ? (
          <div className='tracking-widest bg-success font-bold flex justify-around rounded-lg'>
            <p className='text-white'>Negotiable</p>
            <Icon
              className='text-[1.75rem] font-bold text-premium'
              icon='fa6-solid:handshake'
            />
          </div>
        ) : (
          ""
        )}
        {data.workToOwn ? (
          <div className='tracking-widest bg-success font-bold flex justify-around rounded-lg'>
            <p className='text-white'>Work To Own</p>
            <Icon
              className='text-[1.75rem] font-bold text-premium'
              icon='ic:outline-work'
            />
          </div>
        ) : (
          ""
        )}
        {data.tradeAcc ? (
          <div className='tracking-widest bg-success font-bold flex justify-around rounded-lg'>
            <p className='text-white'>Trade Accepted</p>
            <Icon
              className='text-[1.75rem] font-bold text-premium'
              icon='uil:exchange'
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Card;
